export const currencyFormatUS = (num: number, decimals?: number) => {
  return num
    .toFixed(decimals === undefined ? 2 : decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyFormatDE = (num: number) => {
  return num
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
};

export const formatPrice = (d: number) => {
  let amount = d.toLocaleString('en-EN', {
    style: 'currency',
    currency: 'USD',
  });
  return amount.substring(1);
};

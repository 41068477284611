import { ApolloError } from '@apollo/client';
import { snackbarSeverityType } from 'contexts/UiContext';

export const apolloErrorHandler =
  (cb: (msg: { text: string; severity: snackbarSeverityType }) => void) => (e: ApolloError) => {
    if (e.graphQLErrors) {
      e.graphQLErrors.forEach((err) => {
        if (typeof err.message === 'string') {
          cb!({ text: err.message, severity: 'error' });
        }
      });
    }
    if (e.networkError) {
      cb!({ text: 'Network error', severity: 'error' });
    }
  };
export const apolloErrorTexts = (e: ApolloError) => {
  const errors: string[] = [];
  if (e.graphQLErrors) {
    e.graphQLErrors.forEach((err) => {
      if (typeof err.message === 'string') {
        errors.push(err.message);
      }
    });
  }
  if (e.networkError) {
    errors.push('Network error');
  }
  return errors;
};

const getTokenFromLocalStorage = (): string | null =>
  sessionStorage?.getItem('msal.idtoken') ?? null;

export const buildInitials = (val: string): string => {
  if (!val) return '';

  const temp = val?.split(' ');

  if (temp.length > 1) {
    // DeMac accounts have LASTNAME-FIRSTNAME formatted

    let lastName = temp[0].length > 0 ? temp[0] : '';
    // const firstName = temp[1].length > 0 ? temp[1] : '';

    const fnameInitial = temp[1].length > 0 ? temp[1].substr(0, 1) : '';
    // const lnameInitial = temp[0].length > 0 ? temp[0].substr(0, 1) : '';

    // Remove commas from last name
    lastName = lastName.split(',').join('');

    return `${lastName}, ${fnameInitial}`;
  }

  if (temp.length === 1) return temp[0].substr(0, 1);
  return '';
};
export { getTokenFromLocalStorage };

import { FC } from 'react';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';

export interface ICustomQuery {
  config: any;
  query: any;
  setQuery: any;
  [id: string]: any;
}

export const CustomQuery: FC<ICustomQuery> = ({ config, query, setQuery, ...props }) => {
  return (
    <>
      <QueryBuilder
        query={query}
        onQueryChange={(q) => setQuery(q)}
        fields={config.fields}
        {...props}
      />
    </>
  );
};

import { Tooltip } from '@mui/material';
import { FC } from 'react';
import s from './style.module.scss';

export interface IStatusLabelProps {
  tooltip: string;
  tooltipTitle?: string;
}

export const StatusLabel: FC<IStatusLabelProps> = ({ tooltip, tooltipTitle }) => {
  if (!tooltip) {
    return <></>;
  }
  return (
    <div className={s.statusLabelBlock}>
      {tooltip && tooltipTitle ? (
        <Tooltip title={tooltipTitle}>
          <div>{tooltip}</div>
        </Tooltip>
      ) : (
        tooltip
      )}
    </div>
  );
};

import { FC, useState } from 'react';
import EasyAutoComplete from 'components/EasyAutoComplete';

export interface TextDropdownInputFieldProps {
  id: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  filterProps: any;
  error?: boolean;
}
export const TextDropdownInputField: FC<TextDropdownInputFieldProps> = ({
  id,
  placeholder,
  value,
  onChange,
  filterProps,
  error,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [localSelected, setLocalSelected] = useState<any>();

  return (
    <EasyAutoComplete
      key={`txt-dd-${id}`}
      placeholder={placeholder}
      label=""
      textFieldStyle="outlined"
      getOptionSelected={(option: any, value: any) => {
        return (
          (!!option.id && option.id === value.id) ||
          (!!option.key && option.key === value.key) ||
          (!!option.name && option.name === value.name)
        );
      }}
      style={{ backgroundColor: 'white' }}
      textFieldStyling={{ margin: '0px' }}
      // Defaults
      optionsLabel="name"
      // Passed props
      {...filterProps}
      // Open dropdown only on arrow click
      open={!!open}
      onOpen={(e: any) => {
        if (e.type === 'click') {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      // Text input simulation
      inputValue={value || ''}
      onInputChange={(...props: any) => {
        const [, value, reason] = props;
        if (reason === 'input') {
          onChange(value);
        }
      }}
      // Dropdown
      selected={localSelected}
      selectedChanged={(value: any): void => {
        setLocalSelected(value); // for dropdown search
        onChange(value.name); // for table search
      }}
      error={error}
    />
  );
};

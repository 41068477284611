import { FC, useCallback, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { AssetDetailsFormFields } from '../AssetDetailsFormFields/AssetDetailsFormFields';
import { useAssetContext } from 'contexts/asset/AssetContext';
import { Print } from 'template/Print/Print';
import { AssetPrintingMeta } from '../AssetPrintingMeta/AssetPrintingMeta';
import { Box, Button, Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { SaveAlt } from '@mui/icons-material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { paths } from 'constants/paths';
import { DataSourceTable } from 'components/DataSourceTable/DataSourceTable';
import { useHistory } from 'react-router-dom';
import { relatedAssetsTableHead } from 'constants/relatedAssetsTable';
import AlertDialog from 'components/AlertDialog';
import s from './style.module.scss';
import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { useAssetAssignmentContext } from 'template/AssetAssignment/AssetAssignmentContext';
import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';

export const AssetDetailsForm: FC = () => {
  const history = useHistory();
  const { getBasePageInfo } = useMainTabsHistory();
  const { resetChanged, confirmRequest } = useFormChangedDialogContext();

  const { openValidationResult } = useFormValidationReportContext();
  const [printing, setPrinting] = useState(false);
  const [showValidationResult, setShowValidationResult] = useState(false);

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);
  const [displayUncancelWarning, showUncancelWarning] = useState(false);

  const {
    asset,
    loading,
    allowSubmit,
    onSubmit,
    validateAssetNameResult,
    similarAssets,
    similarAssetsTableDataSource,

    onSubmitValidate,
    onValidateAssetDetails,
    validateAssetDetailsResult,

    validationResult,
    validateExactExpressCode,
    validateExactFactoryIdentifier,

    openAssetAssignmentForm,

    AssetDelete,
    AssetCancel,
    AssetUncancel,

    refetch,
  } = useAssetContext();

  const { assignment: assetAssignment } = useAssetAssignmentContext();

  const { errors, currentAssignment, canCancel, canDelete, canUncancel } = asset;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleSubmit = useCallback(async () => {
    // onSubmit && onSubmit();
    const duplicateExpressCodeFound =
      validateExactExpressCode &&
      (await validateExactExpressCode({ expressCode: asset.expressCode, id: asset.id }));

    const duplicateFactoryIdentifierFound =
      validateExactFactoryIdentifier &&
      (await validateExactFactoryIdentifier({
        factoryIdentifier: asset.factoryIdentifier,
        id: asset.id,
      }));

    const nameValidationCheckResult = onValidateAssetDetails && (await onValidateAssetDetails());

    if (
      onSubmitValidate &&
      onSubmitValidate() &&
      !duplicateFactoryIdentifierFound &&
      !duplicateExpressCodeFound
    ) {
      if (nameValidationCheckResult) {
        onSubmit && onSubmit();
      } else {
        setShowValidationResult(true);
      }
    } else {
      openValidationResult && openValidationResult();
      // window.scrollTo({ top: 0 });
    }
  }, [
    onSubmit,

    onSubmitValidate,
    onValidateAssetDetails,
    openValidationResult,
    asset,
    validateExactExpressCode,
    validateExactFactoryIdentifier,
  ]);

  const handleCancelForm = useCallback(async () => {
    history.push(getBasePageInfo().url || paths.client.BASE);
  }, [history, getBasePageInfo]);

  const handleCreateAsset = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onSubmit!();
      }
      setShowValidationResult(false);
    },
    [onSubmit]
  );

  const onAssetSelect = useCallback(
    (id: string) => {
      const newUrl = getBasePageInfo().url + paths.client.ASSET_DETAILS_SUB_PAGE.replace(':id', id);
      history.push(newUrl);
      return true;
    },
    [history, getBasePageInfo]
  );

  const validationResultMessage = useMemo(() => {
    const { name } = asset;
    return (
      <div>
        {validateAssetNameResult ? (
          <>
            Asset "
            <div
              style={{ display: 'inline' }}
              className={s.linkStyle}
              onClick={debounce(() => {
                onAssetSelect(validateAssetNameResult.id);
              }, DEBOUNCE_TIMEOUT)}
            >
              {name}
            </div>
            " exists.
          </>
        ) : undefined}
        {similarAssets?.filter((asset) => asset?.name !== name).length ? (
          <>
            <Box m={3} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={3} />
                </Grid>
              </Grid>
            </Grid>
            Assets with similar name to "{name}" are found:
            <br />
            <br />
            <DataSourceTable
              dataSource={similarAssetsTableDataSource}
              tableHead={relatedAssetsTableHead}
              onSelect={onAssetSelect}
            ></DataSourceTable>
          </>
        ) : undefined}
        {validationResult.expressCode ? (
          <>
            Express Code "<div style={{ display: 'inline' }}>{validationResult.expressCode}</div>"
            is in use.
          </>
        ) : undefined}
        {validationResult.factoryIdentifier ? (
          <>
            SERIAL NO/SERVICE TAG/IMEI "
            <div style={{ display: 'inline' }}>{validationResult.factoryIdentifier}</div>" is in
            use.
          </>
        ) : undefined}
      </div>
    );
  }, [
    validateAssetNameResult,
    similarAssets,
    asset,
    similarAssetsTableDataSource,
    onAssetSelect,
    validationResult,
  ]);

  const handleDelete = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await AssetDelete!()) {
          history.push(getBasePageInfo().url || paths.client.BASE);
          resetChanged && resetChanged();
        }
      }
    },
    [AssetDelete, history, resetChanged, getBasePageInfo]
  );

  const handleCancel = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await AssetCancel!()) {
          refetch();
          resetChanged && resetChanged();
        }
      }
    },
    [AssetCancel, resetChanged, refetch]
  );

  const handleUncancel = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        showDeleteWarning(() => false);
        if (await AssetUncancel!()) {
          refetch();
          resetChanged && resetChanged();
        }
      }
    },
    [AssetUncancel, resetChanged, refetch]
  );

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <FormValidationReport
        errors={{
          ...errors,
          ...(openAssetAssignmentForm || currentAssignment ? assetAssignment?.errors : undefined),
          expressCode: validationResult?.expressCode
            ? ['Express Code ' + asset.expressCode + ' is already in use']
            : undefined,
          factoryIdentifier: validationResult?.factoryIdentifier
            ? ['SERIAL NO/SERVICE TAG/IMEI ' + asset.factoryIdentifier + ' is already in use']
            : undefined,
          name: validateAssetDetailsResult?.validateAssetNameResult
            ? ['Asset name ' + asset.name + ' is already in use']
            : undefined,
        }}
      ></FormValidationReport>
      <AlertDialog
        title="Asset Exists"
        message={validationResultMessage}
        open={showValidationResult && !!validateAssetNameResult}
        onClose={() => {
          setShowValidationResult(false);
        }}
        maxWidth="lg"
        fullWidth={!!similarAssets?.filter((item) => item?.name !== asset.name).length}
      />
      <ConfirmationDialog
        open={showValidationResult && !validateAssetNameResult}
        title="Please confirm..."
        message={'Please confirm that you would like to create Asset'}
        content={validationResultMessage}
        onClose={handleCreateAsset}
        confirmButtonProps={{ style: { background: 'red' } }}
        maxWidth="lg"
        fullWidth
      />
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Asset deletion"
        message={'Selected Asset will be deleted! The action is irreversible!'}
        onClose={handleDelete}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Asset cancelation"
        message={'Selected Asset will be canceled!'}
        onClose={handleCancel}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayUncancelWarning}
        title="Please confirm Asset uncancel"
        message={'Selected Asset will be uncanceled!'}
        onClose={handleUncancel}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <AssetDetailsFormFields></AssetDetailsFormFields>
      <Print printing={printing} setPrinting={setPrinting} PrintingMeta={AssetPrintingMeta}>
        <AssetDetailsFormFields></AssetDetailsFormFields>
      </Print>
      <Box m={5} />
      <Container maxWidth="md" style={!matches ? { padding: '0px' } : undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={12} md={4} lg={3}></Grid>
              <Grid item xs={11} md={4} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1em',
                    cursor: 'pointer',
                  }}
                  onClick={debounce(() => {
                    setPrinting(true);
                  }, DEBOUNCE_TIMEOUT)}
                >
                  <SaveAlt></SaveAlt>
                  <Button variant="outlined" color="primary">
                    Print
                  </Button>
                </div>
              </Grid>
              <Grid item xs={1} md={4} lg={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box m={5} />
      <Grid container justifyContent="center" spacing={3}>
        {canCancel ? (
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showCancelWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  disabled={!canCancel}
                  style={{ background: 'red', whiteSpace: 'nowrap', width: 'max-content' }}
                >
                  Cancel Asset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : undefined}
        {canUncancel ? (
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showUncancelWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  disabled={!canUncancel}
                >
                  Uncancel Asset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : undefined}
        {canDelete ? (
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showDeleteWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  disabled={!canDelete}
                  style={{ background: 'red' }}
                >
                  Delete Asset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : undefined}
        <Grid item>
          <div style={{ padding: '0 30px' }}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        confirmRequest!((confirmed) => {
                          confirmed && handleCancelForm();
                        });
                      }}
                    >
                      Cancel{/* {id ? 'Submit' : 'Create new'} */}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={debounce(handleSubmit, DEBOUNCE_TIMEOUT)}
                      disabled={!allowSubmit}
                    >
                      Submit{/* {id ? 'Submit' : 'Create new'} */}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

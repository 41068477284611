import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ASSET_STATUSES } from 'graphql/ams/assetStatuses';
import { assetStatuses } from 'graphql/ams/types/assetStatuses';

export const useAssetStatusAll = () => {
  const [assetStatuses, setAssetStatuses] = useState<any[]>([]);

  const { data, loading, error } = useQuery<assetStatuses>(GET_ASSET_STATUSES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && !loading) {
      setAssetStatuses(data?.assets_assetStatuses.sort((a, b) => a.order - b.order) || []);
    }
  }, [data, loading]);

  return { assetStatuses, error };
};

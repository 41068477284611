// import { validators } from 'constants/validators';
import { validators } from 'constants/validators';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { validate } from 'validate.js';
// import { validate } from 'validate.js';

export interface IContextState {
  location: any;
}

export interface IContextActions {
  setLocation: any;
  setProps: any;
  onSubmitValidate: () => boolean;
}

const initialState: IContextState = {
  location: {},
};

const EditAssetLocationContext = createContext<IContextState & Partial<IContextActions>>(
  initialState
);

interface IProviderProps {
  children: any;
}

export const EditAssetLocationProvider: FC<IProviderProps> = ({ children }) => {
  const [props, setProps] = useState<any>({});

  const [locationNotValidated, setLocation] = useState<any>({});
  const [location, setValidatedLocation] = useState<any>({});

  const assetLocationValidators = useMemo(() => {
    return {
      name: validators.simpleText,
      orderNo: validators.getPositiveIntegerValidator(props.totalItems),
    };
  }, [props.totalItems]);

  const validateForm = useCallback(
    (newState: any) => {
      const errors = validate(newState, assetLocationValidators);
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [assetLocationValidators]
  );

  useEffect(() => {
    setValidatedLocation(() => {
      return validateForm({ ...locationNotValidated });
    });
  }, [locationNotValidated, validateForm]);

  const onSubmitValidate = useCallback(() => {
    const { name, isValid } = location;
    if (!isValid || !name) {
      setValidatedLocation(validateForm({ ...locationNotValidated, showValidator: true }));
      return false;
    }
    return true;
  }, [location, validateForm, locationNotValidated]);

  return (
    <EditAssetLocationContext.Provider
      value={{
        location,
        setLocation,
        setProps,
        onSubmitValidate,
      }}
    >
      {children}
    </EditAssetLocationContext.Provider>
  );
};

export const useEditAssetLocationContext = () => useContext(EditAssetLocationContext);

import { IHeadCell } from 'components/ui/TableDnd/components/TableHead/TableHead';
import { AssetSortableColumn } from 'graphql/ams/types/graphql-types';
import { keyBy } from 'lodash';
import { ShowCanceledStatus } from 'utils/TableCellTransform';
import {
  TransformAssetActions,
  TransformCurrentAssignmentEmail,
} from 'utils/TableTransformFunctions';

export const assetsTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Asset Name',
    sortable: true,
    sortBy: AssetSortableColumn.NAME,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformDataCell: ShowCanceledStatus,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Asset Type',
    sortable: true,
    sortBy: AssetSortableColumn.ASSET_TYPE_NAME,
    filter: 'hierarchy-multiple',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Asset Status',
    sortable: true,
    sortBy: AssetSortableColumn.ASSET_STATUS_NAME,
    filter: 'hierarchy-multiple',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'isLoaner',
    numeric: false,
    disablePadding: false,
    label: 'Is Loaner',
    sortable: true,
    sortBy: AssetSortableColumn.IS_LOANER,
    filter: 'dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },
  {
    id: 'isCurrentlyAssigned',
    numeric: false,
    disablePadding: false,
    label: 'Is Assigned',
    sortable: false,
    filter: 'dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },
  {
    id: 'currentAssignment',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
    sortable: true,
    sortBy: AssetSortableColumn.CURRENT_ASSIGNEE_NAME,
    filter: 'text-dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (assignment) => {
      if (assignment?.user) {
        return assignment.user.name;
      }
      if (assignment?.room) {
        return assignment.room.name;
      }
      if (assignment?.contractorName) {
        return assignment.contractorName;
      }
      return '--';
    },
  },
  {
    id: 'currentAssignmentEmail',
    numeric: false,
    disablePadding: false,
    label: `Assignee's Email`,
    sortable: true,
    sortBy: AssetSortableColumn.CURRENT_ASSIGNEE_EMAIL,
    filter: 'text-dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformDataCell: TransformCurrentAssignmentEmail,
  },
  {
    id: 'lastAssignmentEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Loaner Return Date',
    sortable: true,
    sortBy: AssetSortableColumn.LAST_ASSIGNMENT_END_DATE,
    filter: 'date',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (_, row) =>
      row?.lastAssignment?.endDate
        ? new Date(row.lastAssignment?.endDate).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sortable: true,
    sortBy: AssetSortableColumn.ASSET_LOCATION_NAME,
    filter: 'hierarchy-multiple',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'office',
    numeric: false,
    disablePadding: false,
    label: 'Office',
    sortable: true,
    sortBy: AssetSortableColumn.OFFICE,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  // {
  //   id: 'type',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Asset Type',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data) => data?.name || '--',
  // },
  // {
  //   id: 'status',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Asset Status',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data) => data?.name || '--',
  // },
  // {
  //   id: 'location',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'City',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data) => data?.name || '--',
  // },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: 'Brand',
    sortable: true,
    sortBy: AssetSortableColumn.BRAND,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: false,
    label: 'Model',
    sortable: true,
    sortBy: AssetSortableColumn.MODEL,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  // {
  //   id: 'serialNumber',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Asset SN',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.SERIAL_NUMBER,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'factoryIdentifier',
    numeric: false,
    disablePadding: false,
    label: 'Serial No/Service Tag/IMEI',
    sortable: true,
    sortBy: AssetSortableColumn.FACTORY_IDENTIFIER,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'expressCode',
    numeric: false,
    disablePadding: false,
    label: 'Express Code',
    sortable: true,
    sortBy: AssetSortableColumn.EXPRESS_CODE,
    filter: 'text-dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },

  // {
  //   id: 'cpu',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'CPU',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.CPU,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'ram',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'RAM',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.RAM,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'storage',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Storage',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.STORAGE,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'screenSize',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Screen Size',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.SCREEN_SIZE,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'serviceProvider',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Service Provider',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.SERVICE_PROVIDER,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'ipAddress',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'IP Address',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.IP_ADDRESS,
  //   filter: 'text',
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'dollarValue',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Value',
  //   sortable: true,
  //   sortBy: AssetSortableColumn.DOLLAR_VALUE,
  //   filter: 'dropdown',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data: any) => (data ? data : '-- '),
  //   transformDataCell: TransformCurrencyUS,
  //   excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  // },

  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    sortable: true,
    sortBy: AssetSortableColumn.CREATED_AT,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified Date',
    sortable: true,
    sortBy: AssetSortableColumn.UPDATED_AT,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'purchaseDate',
    numeric: false,
    disablePadding: false,
    label: 'Purchase Date',
    sortable: true,
    sortBy: AssetSortableColumn.PURCHASE_DATE,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'warrantyExpirationDate',
    numeric: false,
    disablePadding: false,
    label: 'Warranty Expiration Date',
    sortable: true,
    sortBy: AssetSortableColumn.WARRANTY_EXPIRATION_DATE,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },

  {
    id: 'isCanceled',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
    // filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformAssetActions,
  },
];

export const mappedAssetsTableHead = keyBy(assetsTableHead, 'id');

// import { validators } from 'constants/validators';
import { validators } from 'constants/validators';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { validate } from 'validate.js';
// import { validate } from 'validate.js';

export interface IContextState {
  room: any;
}

export interface IContextActions {
  setRoom: any;
  setProps: any;
  onSubmitValidate: () => boolean;
}

const initialState: IContextState = {
  room: {},
};

const EditRoomContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const EditRoomProvider: FC<IProviderProps> = ({ children }) => {
  const [props, setProps] = useState<any>({});

  const [roomNotValidated, setRoom] = useState<any>({});
  const [room, setValidatedRoom] = useState<any>({});

  const roomValidators = useMemo(() => {
    return {
      name: validators.simpleText,
      orderNo: validators.getPositiveIntegerValidator(props.totalItems),
    };
  }, [props.totalItems]);

  const validateForm = useCallback(
    (newState: any) => {
      const errors = validate(newState, roomValidators);
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [roomValidators]
  );

  useEffect(() => {
    setValidatedRoom(() => {
      return validateForm({ ...roomNotValidated });
    });
  }, [roomNotValidated, validateForm]);

  const onSubmitValidate = useCallback(() => {
    const { name, isValid } = room;
    if (!isValid || !name) {
      setValidatedRoom(validateForm({ ...roomNotValidated, showValidator: true }));
      return false;
    }
    return true;
  }, [room, validateForm, roomNotValidated]);

  return (
    <EditRoomContext.Provider
      value={{
        room,
        setRoom,
        setProps,
        onSubmitValidate,
      }}
    >
      {children}
    </EditRoomContext.Provider>
  );
};

export const useEditRoomContext = () => useContext(EditRoomContext);

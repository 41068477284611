import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { GET_USERS, GET_USERS_COUNT } from 'graphql/ams/users';
import { usersList } from 'graphql/ams/types/usersList';
import { usersCount } from 'graphql/ams/types/usersCount';
import { UserFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataUsersCount: any) => dataUsersCount.userCount;
const getItems = (dataUsers: any) =>
  dataUsers.users?.map((user: IUser) => ({ ...user, ddLabel: `${user.name} (${user.email})` }));
const getItemId = (item: any) => item.id;
const filterToAPI = (search: string): UserFilter => ({
  nameOrEmailContains: search,
  isActive: true,
});

export interface IUsersAutocompleteDataProps {
  search: string;
}

export interface IUser {
  id: string;
  name: string;
  email?: string | null;
}

export const useUsersAutocompleteDataHook = ({ search }: IUsersAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<usersList, usersCount, IUser, UserFilter>({
    GET_QUERY: GET_USERS,
    GET_COUNT_QUERY: GET_USERS_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};

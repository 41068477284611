import EasyAutoComplete from 'components/EasyAutoComplete';
import { GET_ASSET_STATUSES } from 'graphql/ams/assetStatuses';
import { assetStatuses } from 'graphql/ams/types/assetStatuses';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';

import { FC, useMemo } from 'react';

export interface IStatusData {
  id: string;
  name: string;
}

export interface ISelectAssetStatusProps {
  status?: IStatusData;
  onSelectChange: (value: IStatusData | undefined) => void;
  [id: string]: any;
}

const filterToApi = () => ({});

export const SelectAssetStatus: FC<ISelectAssetStatusProps> = ({
  status,
  onSelectChange,
  ...easyAutoCompleteProps
}) => {
  const statusesQuery = useMemo(() => {
    return {
      GET_QUERY: GET_ASSET_STATUSES,
      getItemId: (item: any) => item.id,
      getItems: (data: assetStatuses) =>
        [...data.assets_assetStatuses]
          .filter((item) => item.isSelectable)
          .sort((a, b) => a.order - b.order)
          .map(({ id, name }) => ({ id, key: name })),
      filterToApi,
    };
  }, []);

  const statusesProps = useAutoCompleteSimpleDataSource<any, any, any>(statusesQuery);

  const updateStatus = (getValue: (props: any) => IStatusData | undefined) => (props: any) => {
    const value = getValue(props);
    onSelectChange(value);
  };

  const selectedItem = useMemo(() => {
    if (!status) {
      return null;
    } else {
      const { id, name } = status;
      return { id: id, key: name };
    }
  }, [status]);

  return (
    <EasyAutoComplete
      disableClearable={false}
      selected={selectedItem}
      key={`stockExchanges`}
      label=""
      optionsLabel="key"
      textFieldStyle="outlined"
      {...statusesProps}
      selectedChanged={updateStatus((value: any) =>
        value ? { id: value.id, name: value.key } : undefined
      )}
      getSelectedLabel={(option: any) => {
        return option.key;
      }}
      {...easyAutoCompleteProps}
    />
  );
};

// import { validators } from 'constants/validators';
import { validators } from 'constants/validators';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { validate } from 'validate.js';
// import { validate } from 'validate.js';

export interface IContextState {
  status: any;
}

export interface IContextActions {
  setStatus: any;
  setProps: any;
  onSubmitValidate: () => boolean;
}

const initialState: IContextState = {
  status: {},
};

const EditAssetStatusContext = createContext<IContextState & Partial<IContextActions>>(
  initialState
);

interface IProviderProps {
  children: any;
}

export const EditAssetStatusProvider: FC<IProviderProps> = ({ children }) => {
  const [props, setProps] = useState<any>({});

  const [statusNotValidated, setStatus] = useState<any>({});
  const [status, setValidatedStatus] = useState<any>({});

  const assetStatusValidators = useMemo(() => {
    return {
      name: validators.simpleText,
      orderNo: validators.getPositiveIntegerValidator(props.totalItems),
    };
  }, [props.totalItems]);

  const validateForm = useCallback(
    (newState: any) => {
      const errors = validate(newState, assetStatusValidators);
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [assetStatusValidators]
  );

  useEffect(() => {
    setValidatedStatus(() => {
      return validateForm({ ...statusNotValidated });
    });
  }, [statusNotValidated, validateForm]);

  const onSubmitValidate = useCallback(() => {
    const { name, isValid } = status;
    if (!isValid || !name) {
      setValidatedStatus(validateForm({ ...statusNotValidated, showValidator: true }));
      return false;
    }
    return true;
  }, [status, validateForm, statusNotValidated]);

  return (
    <EditAssetStatusContext.Provider
      value={{
        status,
        setStatus,
        setProps,
        onSubmitValidate,
      }}
    >
      {children}
    </EditAssetStatusContext.Provider>
  );
};

export const useEditAssetStatusContext = () => useContext(EditAssetStatusContext);

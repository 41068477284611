import React, { createContext, FC, useContext } from 'react';

import { IAssetAssignment } from 'graphql/ams/types/IAssetAssignment';
import { useAssetAssignees } from 'hooks/assetAssigneesHook';

export interface IContextState {
  assignments: any[];
  totalItems: number;
  pageLoadParams: any;
}

export interface IContextActions {
  onFilterChange: any;
  loadPage: any;
}

const initialState: IContextState = {
  assignments: [],
  totalItems: 0,
  pageLoadParams: {},
};

const AssetAssigneesContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IAssetAssigneesProviderProps {
  children: any;
  loadedAssignments: IAssetAssignment[];
}

export const AssetAssigneesProvider: FC<IAssetAssigneesProviderProps> = ({
  children,
  loadedAssignments,
}) => {
  const { loadPage, onFilterChange, assignments, totalItems, pageLoadParams } = useAssetAssignees({
    assignments: loadedAssignments,
  });

  return (
    <AssetAssigneesContext.Provider
      value={{
        loadPage,
        onFilterChange,
        assignments,
        totalItems,
        pageLoadParams,
      }}
    >
      {children}
    </AssetAssigneesContext.Provider>
  );
};

export const useAssetAssigneesContext = () => useContext(AssetAssigneesContext);

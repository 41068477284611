/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetAssignmentType {
  PERMANENT = "PERMANENT",
  TEMPORARY = "TEMPORARY",
}

export enum AssetDistinctOnColumn {
  ASSET_TAG = "ASSET_TAG",
  BRAND = "BRAND",
  CPU = "CPU",
  CURRENT_ASSIGNEE_EMAIL = "CURRENT_ASSIGNEE_EMAIL",
  CURRENT_ASSIGNEE_NAME = "CURRENT_ASSIGNEE_NAME",
  EXPRESS_CODE = "EXPRESS_CODE",
  FACTORY_IDENTIFIER = "FACTORY_IDENTIFIER",
  MODEL = "MODEL",
  NAME = "NAME",
  OFFICE = "OFFICE",
  RAM = "RAM",
  SCREEN_SIZE = "SCREEN_SIZE",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  SERVICE_TAG = "SERVICE_TAG",
  STATUS_ID = "STATUS_ID",
  STORAGE = "STORAGE",
}

export enum AssetLocationSortableColumn {
  CREATED_AT = "CREATED_AT",
  DESCRIPTION = "DESCRIPTION",
  ID = "ID",
  IS_SELECTABLE = "IS_SELECTABLE",
  NAME = "NAME",
  ORDER = "ORDER",
  UPDATED_AT = "UPDATED_AT",
}

export enum AssetRoomSortableColumn {
  CREATED_AT = "CREATED_AT",
  DESCRIPTION = "DESCRIPTION",
  ID = "ID",
  IS_SELECTABLE = "IS_SELECTABLE",
  NAME = "NAME",
  ORDER = "ORDER",
  UPDATED_AT = "UPDATED_AT",
}

export enum AssetSortableColumn {
  ASSET_LOCATION_NAME = "ASSET_LOCATION_NAME",
  ASSET_STATUS_NAME = "ASSET_STATUS_NAME",
  ASSET_TAG = "ASSET_TAG",
  ASSET_TYPE_NAME = "ASSET_TYPE_NAME",
  BRAND = "BRAND",
  COMMENT = "COMMENT",
  CPU = "CPU",
  CREATED_AT = "CREATED_AT",
  CURRENT_ASSIGNEE_EMAIL = "CURRENT_ASSIGNEE_EMAIL",
  CURRENT_ASSIGNEE_NAME = "CURRENT_ASSIGNEE_NAME",
  CURRENT_ASSIGNMENT_USER_EMAIL = "CURRENT_ASSIGNMENT_USER_EMAIL",
  CURRENT_ASSIGNMENT_USER_NAME = "CURRENT_ASSIGNMENT_USER_NAME",
  DOLLAR_VALUE = "DOLLAR_VALUE",
  EXPRESS_CODE = "EXPRESS_CODE",
  FACTORY_IDENTIFIER = "FACTORY_IDENTIFIER",
  ID = "ID",
  IP_ADDRESS = "IP_ADDRESS",
  IS_LOANER = "IS_LOANER",
  LAST_ASSIGNMENT_END_DATE = "LAST_ASSIGNMENT_END_DATE",
  MODEL = "MODEL",
  NAME = "NAME",
  OFFICE = "OFFICE",
  PURCHASE_DATE = "PURCHASE_DATE",
  RAM = "RAM",
  SCREEN_SIZE = "SCREEN_SIZE",
  SERIAL_NUMBER = "SERIAL_NUMBER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  SERVICE_TAG = "SERVICE_TAG",
  STATUS_ID = "STATUS_ID",
  STORAGE = "STORAGE",
  UPDATED_AT = "UPDATED_AT",
  WARRANTY_EXPIRATION_DATE = "WARRANTY_EXPIRATION_DATE",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserSortableColumns {
  CREATED_AT = "CREATED_AT",
  DISCIPLINE_CATEGORY = "DISCIPLINE_CATEGORY",
  DIVISION = "DIVISION",
  EMAIL = "EMAIL",
  ID = "ID",
  IS_ACTIVE = "IS_ACTIVE",
  IS_ASSETS_USER = "IS_ASSETS_USER",
  NAME = "NAME",
  OID = "OID",
  UPDATED_AT = "UPDATED_AT",
}

export interface AssetDistinctOn {
  column: AssetDistinctOnColumn;
}

export interface AssetFilter {
  assetTypeIds?: string[] | null;
  assetStatusIds?: string[] | null;
  assetLocationIds?: string[] | null;
  isLoaner?: boolean | null;
  name?: string | null;
  nameContains?: string | null;
  assetTagContains?: string | null;
  brandContains?: string | null;
  modelContains?: string | null;
  serialNumber?: string | null;
  serialNumberContains?: string | null;
  serviceTagContains?: string | null;
  cpuContains?: string | null;
  ramContains?: string | null;
  storageContains?: string | null;
  screenSizeContains?: string | null;
  purchaseDate?: any | null;
  purchaseDateFrom?: any | null;
  purchaseDateTo?: any | null;
  warrantyExpirationDate?: any | null;
  warrantyExpirationDateFrom?: any | null;
  warrantyExpirationDateTo?: any | null;
  serviceProviderContains?: string | null;
  ipAddressContains?: string | null;
  commentContains?: string | null;
  factoryIdentifier?: string | null;
  factoryIdentifierContains?: string | null;
  expressCode?: string | null;
  expressCodeContains?: string | null;
  dollarValueLow?: number | null;
  dollarValueHigh?: number | null;
  officeContains?: string | null;
  isCurrentlyAssigned?: boolean | null;
  typeNameContains?: string | null;
  statusNameContains?: string | null;
  locationNameContains?: string | null;
  currentAssignmentUserNameContains?: string | null;
  currentAssignmentUserEmailContains?: string | null;
  currentAssignmentUserOrRoomNameContains?: string | null;
  currentAssignmentUserOrRoomOrContractorNameContains?: string | null;
  currentAssignmentUserOrContractorEmailContains?: string | null;
  lastAssignmentEndDateFrom?: any | null;
  lastAssignmentEndDateTo?: any | null;
  createdAt?: any | null;
  createdAtFrom?: any | null;
  createdAtTo?: any | null;
  updatedAt?: any | null;
  updatedAtFrom?: any | null;
  updatedAtTo?: any | null;
}

export interface AssetLocationFilter {
  ids?: string[] | null;
  nameContains?: string | null;
  isSelectable?: boolean | null;
}

export interface AssetLocationSort {
  column: AssetLocationSortableColumn;
  order: Order;
}

export interface AssetRoomFilter {
  ids?: string[] | null;
  nameContains?: string | null;
  isSelectable?: boolean | null;
}

export interface AssetRoomSort {
  column: AssetRoomSortableColumn;
  order: Order;
}

export interface AssetSort {
  column: AssetSortableColumn;
  order: Order;
}

export interface AssetTypeFilter {
  ids?: string[] | null;
  parentAssetTypeIds?: string[] | null;
  nameContains?: string | null;
  isSelectable?: boolean | null;
}

export interface UserFilter {
  oid?: string | null;
  nameContains?: string | null;
  emailContains?: string | null;
  nameOrEmailContains?: string | null;
  isActive?: boolean | null;
  isMoscow?: boolean | null;
  isAssetsUser?: boolean | null;
  divisionId?: string | null;
  projectId?: string | null;
}

export interface UserSort {
  column: UserSortableColumns;
  order: Order;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export const idToken = `
  acct
  aio
  aud
  auth_time
  ctry
  email
  exp
  family_name
  given_name
  home_oid
  iat
  idp
  ipaddr
  iss
  name
  nbf
  nonce
  oid
  platf
  preferred_username
  sid
  sub
  tenant_ctry
  tenant_region_scope
  tid
  uti
  ver
  xms_tpl
`;

export const account = `
  accountIdentifier
  environment
  homeAccountIdentifier
  idToken {
    ${idToken}
  }
  idTokenClaims {
    ${idToken}
  }
  name
  sid
  userName
`;

export const user = `
  id
  name
  email
  discipline
  division {
    id
    name
  }
`;

export const reportUser = `
  id
  order
  persona
  status
  isEnabled
  user {
    ${user}
  }
`;

export const reportVersion = `
  uploader {
    ${reportUser}
  }
  versionMajor
  versionMinor
  originalFilename
  mimeType
  blobName
  createdAt
  downloadUrl
`;

export const reportEvent = `
  reportVersion {
    ${reportVersion}
  }
  author {
    ${reportUser}
  }
  reviewer{
    ${reportUser}
  }
  eventType
  comment
  clientApprovalType
  clientApprovalStatus
  createdAt
`;

export const division = `
  id
  name
  manager {
    ${user}
  }
`;

export const country = `
  id
  code
  name
`;

export const project = `
  id
  key
  name
  predecessor
  countries {
    ${country}
  }
  divisions {
    ${division}
  }
  owner {
    ${division}
  }
  leader {
    ${user}
  }
  coordinator {
    ${user}
  }
  technicianCoordinator {
    ${user}
  }
  secondaryTechnicianCoordinator {
    ${user}
  }
`;

export const projectForTable = `
  id
  key
  name
  owner {
    ${division}
  }
`;

export const reportInfo = `
  copiesRequestedByClient
  permanentFileCopy
  divisionFileCopy
  totalNumberOfCopies
  numberOfTables
  tablesAppendixNo
  numberOfFigures
  figuresAppendixNo
  numberOfPlates
  platesAppendixNo
  textOnly
  textWithAppendix
  signatureBeforePrinting
  signatureAfterPrinting
  titleOnCover
  title
  clientNumberOfCds
  cdNo
  printShopCd
  reportCd
  dateShipped
  deliveryMethod
  trackingNumber
  specialInstructions
  secParagraphCheckedBy
  secParagraphCheckedOn
`;

export const report = `
  id
  name
  
  status
  canChangeOwner
  canCancel
  canUpdate
  canReview
  canComment
  canRequestReview
  canChangeReviewers
  canCreateVersion
  canUploadFile
  canDeleteFile
  canChangeClientApprovalStatus
  canArchive
  canViewArchiveSection
  canSoftDeleteCanceled

  files {originalFilename, downloadUrl, id, createdAt, updatedAt}
  
  lastEvent {
    eventType
  }

  lastAction {
    eventType
  }

  project {
    ${project}
  }
  clientApprovalNeeded
  notifyMainStakeholders
  nonReportDocument

  info {
    copiesRequestedByClient
      permanentFileCopy
      divisionFileCopy
      totalNumberOfCopies
      numberOfTables
      tablesAppendixNo
      numberOfFigures
      figuresAppendixNo
      numberOfPlates
      platesAppendixNo
      textOnly
      textWithAppendix
      signatureBeforePrinting
      signatureAfterPrinting
      titleOnCover
      title
      clientNumberOfCds
      cdNo
      printShopCd
      reportCd
      dateShipped
      deliveryMethod
      
      
      trackingNumber
      specialInstructions
      
      secParagraphCheckedBy
      secParagraphCheckedOn
  }
  reserves {
    volumetricReserves, 
      volumetricContingentResources,
      materialBalanceReserves,
      materialBalanceContingentResources,
      rateTimeReserves,
      rateTimeContingentResources,
      otherPerformanceToolsReserves,
      otherPerformanceToolsContingentResources,
      analogyAssignmentReserves,
      analogyAssignmentContingentResources,
      analogyVolumetricReserves,
      analogyVolumetricContingentResources,
      simulationsReserves,
      simulationsContingentResources,
      unconventionalToolsReserves,
      unconventionalToolsContingentResources
  }


  

  reviewers {
    ${reportUser}
  }
  versions {
    ${reportVersion}
  }
  events {
    ${reportEvent}
  }
  createdAt
  updatedAt
  owner {
    ${user}
  }


  pendingReviewers {
    ${reportUser}
  }



  approvalCount
  reviewerCount
  
  lastEvent {
    ${reportEvent}
  }
  lastAction {
    ${reportEvent}
  }
`;

export const dashboardReport = `
  id
  name
  status
  lastEvent {
    eventType
  }
  lastAction {
    eventType
  }
  project {
    id
    key
    name
    predecessor
    owner {
     id
     name
    }
  }
  createdAt
  updatedAt
  owner {
    ${user}
  }
  pendingReviewers {
    ${reportUser}
  }
  approvalCount
  reviewerCount
  canSoftDeleteCanceled
  canCancel
  canReview
  lastAction {
    author {
      ${reportUser}
    }
  }`;

export const reportTable = `
  id
  name
  type
  project {
    ${projectForTable}
  }
  
  versions {
    ${reportVersion}
  }
  createdAt
  updatedAt
  owner {
    ${user}
  }


  approvalCount
  reviewerCount
  
  lastEvent {
    ${reportEvent}
  }
  lastAction {
    ${reportEvent}
  }
`;

export const sasBlob = `
 url
`;

export const uploadedFileType = `
  blobContainerName
  blobName
  fileFilename
  fileMimeType
`;

import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

export interface IFieldRow {
  title: string;
  isRequired?: boolean;
  children: any;
  md1?: number;
  md2?: number;
  lg1?: number;
  lg2?: number;
}

export const FieldRow: FC<IFieldRow> = ({ title, isRequired, children, md1, md2, lg1, lg2 }) => {
  return (
    <>
      <Grid item xs={12} md={md1 || 4} lg={lg1 || 5}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography variant="body2" className="label-title">
            {title}
            {!!title ? ':' : undefined}
          </Typography>
          {isRequired ? (
            <div style={{ padding: '0 2px' }}>
              <Typography variant="body2" className="label-title-desc">
                (required)
              </Typography>
            </div>
          ) : undefined}
        </div>
      </Grid>
      <Grid item xs={12} md={md2 || 8} lg={lg2 || 7}>
        {children}
      </Grid>
    </>
  );
};

import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { useTableStorage } from 'components/ui/TableDnd/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAssetTypesTree } from './assetTypesTreeHook';

export interface IAssetTypesFilter {}
export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: IAssetTypesFilter;
}

export const useAssetType = () => {
  const {
    types,
    loading,
    error,
    deleteTreeItemById,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    moveTreeItem,
    expandedTypes,
    parents,
    onExpandChange,
    hasPrevSibling,
    moveTreeItemRight,
    moveTreeItemLeft,
    hasParent,
    totalItems,
    totalRecords,
  } = useAssetTypesTree();

  const { setItem, getItem } = useTableStorage({
    key: 'AssetTypesList',
  });

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {},
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const visibleTypes = useMemo(() => {
    const { rowsPerPage, page } = pageLoadParams;
    const from = page * rowsPerPage;
    const toNotIncluded = from + rowsPerPage;
    return types.slice(from, toNotIncluded);
  }, [pageLoadParams, types]);

  const deleteItem = useCallback(
    (id: string) => {
      deleteTreeItemById(id);
    },
    [deleteTreeItemById]
  );

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      const { page, rowsPerPage } = pageLoadParams;
      const offset = page * rowsPerPage;
      const fromId = types[offset + fromIndex].id;
      const toId = types[offset + toIndex].id;
      moveTreeItem(fromId, toId);
    },
    [pageLoadParams, moveTreeItem, types]
  );

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onFilterChange = useCallback(() => false, []);

  return {
    types: visibleTypes,
    loading,
    error,
    totalItems,
    totalRecords,
    deleteItem,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    moveItem,
    loadPage,
    onFilterChange,
    expandedTypes,
    parents,
    onExpandChange,
    hasPrevSibling,
    moveTreeItemRight,
    moveTreeItemLeft,
    hasParent,
  };
};

import { FormValidationReportProvider } from 'components/FormValidationReport/FormValidationReportContext';
import { FC } from 'react';
import { AssetDetailsForm } from 'template/Asset/components/AssetDetailsForm/AssetDetailsForm';

export const AssetDetails: FC = () => {
  return (
    <>
      <FormValidationReportProvider>
        <AssetDetailsForm></AssetDetailsForm>
      </FormValidationReportProvider>
    </>
  );
};

import { paths } from 'constants/paths';

export const newAssetTabs = [
  {
    label: 'New Asset',
    value: ['new-asset'],
    path: paths.client.NEW_ASSET_SUB_PAGE,
  },
  //   {
  //     label: 'File Management',
  //     value: ['file-management'],
  //     path: paths.client.ASSET_FM,
  //   },
  //   {
  //     label: 'Comments/Event Log',
  //     value: ['events-log'],
  //     path: paths.client.ASSET_EVENTS,
  //   },
];

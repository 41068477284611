import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ASSET_LOCATIONS } from 'graphql/ams/assetLocations';
import { assetLocations } from 'graphql/ams/types/assetLocations';
import { useUI } from 'contexts/UiContext';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

export const useAssetLocationAll = () => {
  const { addSnackbar } = useUI();
  const [assetLocations, setAssetLocations] = useState<any[]>([]);

  const { data, loading, error } = useQuery<assetLocations>(GET_ASSET_LOCATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && !loading) {
      setAssetLocations(data?.assets_assetLocations.sort((a, b) => a.order - b.order) || []);
    }
  }, [data, loading]);

  useEffect(() => {
    if (error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [error, addSnackbar]);

  return { assetLocations, error };
};

export const FileDarkIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.81"
      height="13.513"
      viewBox="0 0 10.81 13.513"
      {...props}
    >
      <defs></defs>
      <path
        fill="currentColor"
        d="M5.351,2A1.349,1.349,0,0,0,4.007,3.351L4,14.161a1.349,1.349,0,0,0,1.345,1.351h8.114a1.355,1.355,0,0,0,1.351-1.351V6.054L10.756,2Zm4.729,4.729V3.013L13.8,6.729Z"
        transform="translate(-4 -2)"
      />
    </svg>
  );
};

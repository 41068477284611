import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { useTableStorage } from 'components/ui/TableDnd/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { useUI } from 'contexts/UiContext';
import {
  ASSET_ROOM_CREATE_MUTATION,
  ASSET_ROOM_UPDATE_MUTATION,
  GET_ASSET_ROOMS,
} from 'graphql/ams/assetRooms';

import { assetRooms } from 'graphql/ams/types/assetRooms';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { tryUpdateProcedure } from 'utils/apollo';
import { arrayMoveItem } from 'utils/array';
import { v4 as uuidv4 } from 'uuid';

export interface IRoomesFilter {}
export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: IRoomesFilter;
}

export const useRoom = () => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const [createRoomMutation] = useMutation(ASSET_ROOM_CREATE_MUTATION);
  const [updateRoomMutation] = useMutation(ASSET_ROOM_UPDATE_MUTATION);

  const [roomes, setRoomes] = useState<any[]>([]);

  const [reordered, setReordered] = useState(false);

  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  const { data, loading, error } = useQuery<assetRooms>(GET_ASSET_ROOMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { setItem, getItem } = useTableStorage({
    key: 'RoomesList',
  });

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {},
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const visibleRoomes = useMemo(() => {
    const { rowsPerPage, page } = pageLoadParams;
    const from = page * rowsPerPage;
    const toNotIncluded = from + rowsPerPage;
    return roomes.slice(from, toNotIncluded);
  }, [pageLoadParams, roomes]);

  const getRoomes = useCallback(() => {
    const { page, rowsPerPage } = pageLoadParams;
    let index = page * rowsPerPage;
    return (
      data?.assets_assetRooms
        .sort((a, b) => a.order - b.order)
        .map((room) => {
          index++;
          return { ...room, orderNo: index };
        }) || []
    );
  }, [data, pageLoadParams]);

  const getRoomesRef = useRef(getRoomes);
  useEffect(() => {
    getRoomesRef.current = getRoomes;
  }, [getRoomes]);

  useEffect(() => {
    if (data && !loading) {
      setRoomes(getRoomesRef.current());
    }
  }, [data, loading, getRoomesRef]);

  const totalItems = useMemo(() => {
    return roomes?.length || 0;
  }, [roomes]);

  const deleteItem = useCallback(
    (id: string) => {
      const found = roomes.find((room: any) => room.id === id);
      if (found && !found.isNew) {
        setDeletedItems((old: string[]) => [...old, id]);
      }

      const index = roomes.findIndex((room: any) => room.id === id);
      const newRoomes = [...roomes];
      newRoomes.splice(index, 1);
      setRoomes(newRoomes);
    },
    [roomes]
  );

  const removeItem = useCallback(
    (id: string) => {
      const index = roomes.findIndex((room: any) => room.id === id);
      const newRoomes = [...roomes];
      newRoomes.splice(index, 1);
      setRoomes(newRoomes);
    },
    [roomes]
  );

  const updateItem = useCallback((item: any) => {
    const updateValues = { ...item };
    setRoomes((roomes: any[]) => {
      const newRoomes = [...roomes];

      const foundItemIndex = newRoomes.findIndex((room: any) => room.id === updateValues.id);
      const foundItem = newRoomes[foundItemIndex];
      Object.assign(foundItem, { ...updateValues, changed: true });

      const { orderNo } = updateValues;
      const newItemIndex = orderNo - 1;
      if (newItemIndex !== foundItemIndex) {
        return arrayMoveItem(newRoomes, foundItemIndex, newItemIndex);
      } else {
        return newRoomes;
      }
    });
  }, []);

  const onSubmit = useCallback(async () => {
    let orderNo = 1;
    for (const room of roomes) {
      if (room.order !== orderNo) {
        room.order = orderNo;
        room.changed = true;
      }
      orderNo++;
    }

    const createItems = roomes.filter((room: any) => !!room.isNew);
    const updateItems = roomes.filter((room: any) => !room.isNew && !!room.changed);

    let foundError = false;
    let allErrors: string[] = [];
    let lastResult = undefined;

    for (const item of createItems) {
      const saveData = pick(item, ['name', 'description', 'order', 'isSelectable']);
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createRoomMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.isNew = false;
        item.changed = false;
        item.id = result?.assets_roomCreate?.id;
      }
    }

    for (const item of updateItems) {
      const saveData = pick(item, ['id', 'name', 'description', 'order', 'isSelectable']);
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          updateRoomMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.changed = false;
      }
    }

    if (foundError) {
      addSnackbar!({
        text: allErrors?.join(' '),
        severity: 'error',
      });
    } else {
      if (lastResult) {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
      } else {
        addSnackbar!({
          text: 'Nothing to Save',
          severity: 'info',
        });
      }
      await client.resetStore();
    }
    if (deletedItems.length) {
      addSnackbar!({
        text: 'Room deletion is not supported',
        severity: 'error',
      });
    }

    if (!foundError) {
      setReordered(() => false);
    }
    setRoomes((old) => [...old]);
  }, [roomes, addSnackbar, createRoomMutation, updateRoomMutation, deletedItems.length, client]);

  const hasChanges = useMemo(() => {
    return (
      reordered || deletedItems.length || !!roomes.find((room: any) => room.isNew || !!room.changed)
    );
  }, [reordered, deletedItems, roomes]);

  const addNew = useCallback(() => {
    const newRoomItem: any = {
      id: uuidv4(),
      isSelectable: true,
      order: 1,
      name: '',
      description: '',
      isNew: true,
      orderNo: 1,
    };
    setRoomes((old: any[]) => {
      return [newRoomItem, ...old];
    });
    return newRoomItem;
  }, []);

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      setReordered(true);
      setRoomes((old: any[]) => {
        const { page, rowsPerPage } = pageLoadParams;
        const offset = page * rowsPerPage;
        return arrayMoveItem(old, offset + fromIndex, offset + toIndex);
      });
    },
    [pageLoadParams]
  );

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onFilterChange = useCallback(() => false, []);

  return {
    roomes: visibleRoomes,
    loading,
    error,
    totalItems,
    deleteItem,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    removeItem,
    moveItem,
    loadPage,
    onFilterChange,
  };
};

import { ListAlt } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { SelectAssetLocation } from 'components/SelectAssetLocation/SelectAssetLocation';
import { SelectAssetStatus } from 'components/SelectAssetStatus/SelectAssetStatus';
import { SelectAssetType } from 'components/SelectAssetType/SelectAssetType';
import { AssetAssignmentObjectType, useAssetContext } from 'contexts/asset/AssetContext';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AssetStatusTable } from 'template/AssetStatus/components/AssetStatusTable/AssetStatusTable';
import { FieldRow } from './components/FieldRow/FieldRow';
import { Provider as ChangedDialogContextProvider } from 'components/FormChangedDialog/FormChangedDialogContext';
import { FormChangedDialog } from 'components/FormChangedDialog/FormChangedDialog';
import { EditAssetStatusProvider } from 'template/AssetStatus/components/AssetStatusTable/components/EditAssetStatus/EditAssetStatusContext';
import { EditAssetLocationProvider } from 'template/AssetLocation/components/AssetStatusTable/components/EditAssetLocation/EditAssetLocationContext';
import { AssetLocationTable } from 'template/AssetLocation/components/AssetStatusTable/AssetLocationTable';
import { EditAssetTypeProvider } from 'template/AssetType/components/AssetStatusTable/components/EditAssetType/EditAssetTypeContext';
import { AssetTypeTable } from 'template/AssetType/components/AssetStatusTable/AssetTypeTable';
import { AssetAssignees } from 'template/AssetAssignees/AssetAssignees';
import { AssetAssigneesProvider } from 'template/AssetAssignees/AssetAssigneesContext';
import { AssetAssignment } from 'template/AssetAssignment/AssetAssignment';
import { AssetRoomAssignment } from 'template/AssetAssignment/AssetRoomAssignment';
import { debounce, omit } from 'lodash';
import { DEBOUNCE_TIMEOUT, MAX_ASSET_VALUE } from 'constants/config';
import {
  AssetAssignmentProvider,
  useAssetAssignmentContext,
} from 'template/AssetAssignment/AssetAssignmentContext';
import { GroupByTwoGrid } from 'components/GroupByTwoGrid/GroupByTwoGrid';

import { CurrencyInputField } from 'components/CurrencyInputField/CurrencyInputField';
import { AssetAssignmentType } from 'graphql/ams/types/graphql-types';
import { EditRoomProvider } from 'template/AssetRoom/components/RoomsTable/components/EditRoom/EditRoomContext';
import { RoomTable } from 'template/AssetRoom/components/RoomsTable/RoomsTable';
import { IRoomItem } from 'components/SelectRoom/SelectRoom';

import { TextDropdownInputField } from 'components/TextDropdownInputField/TextDropdownInputField';
import { useAssetNamesAutocompleteDataHook } from 'hooks/assetNamesAutoCompleteDataHook';
import { useAssetBrandsAutocompleteDataHook } from 'hooks/assetBrandAutoCompleteDataHook';
import { useAssetModelsAutocompleteDataHook } from 'hooks/assetModelsAutoCompleteDataHook';
import { useAssetFactoryIdentifiersAutocompleteDataHook } from 'hooks/assetFactoryIdentifierAutoCompleteDataHook';
import { useAssetExpressCodesAutocompleteDataHook } from 'hooks/assetExpressCodesAutoCompleteDataHook';
import { useAssetCpusAutocompleteDataHook } from 'hooks/assetCpusAutoCompleteDataHook';
import { useAssetRamsAutocompleteDataHook } from 'hooks/assetRamsAutoCompleteDataHook';
import { useAssetStoragesAutocompleteDataHook } from 'hooks/assetStoragesAutoCompleteDataHook';
import { useAssetScreenSizesAutocompleteDataHook } from 'hooks/assetScreenSizesAutoCompleteDataHook';
import { useAssetOfficesAutocompleteDataHook } from 'hooks/assetOfficesAutoCompleteDataHook';
import { useAssetServiceProvidersAutocompleteDataHook } from 'hooks/assetServiceProvidersAutoCompleteDataHook';
import { AssetContractorAssignment } from 'template/AssetAssignment/AssetContractorAssignment';

export const AssetDetailsFormFields: FC = () => {
  const {
    asset,
    loadedAsset,
    onSetAsset,
    onUndoAsset,
    isNewAsset,
    openAssetAssignmentForm,
    setOpenAssetAssignmentForm,
    refetch,
    ts,
    validationResult,
    allowSubmit,
  } = useAssetContext();

  const { setAssignment } = useAssetAssignmentContext();

  const {
    id,

    showValidator,
    errors,

    name,

    selectedAssetStatus,
    selectedAssetType,
    selectedAssetLocation,

    isLoaner,

    brand,
    model,

    cpu,
    ram,
    storage,
    screenSize,
    purchaseDate,
    warrantyExpirationDate,
    serviceProvider,
    comment,

    currentAssignment,
    assignments,

    createdAt,
    updatedAt,

    factoryIdentifier,
    expressCode,
    dollarValue,
    office,

    createdBy,
    updatedBy,
  } = asset;

  const [openStatuses, setOpenStatuses] = useState(false);
  const [openTypes, setOpenTypes] = useState(false);
  const [openLocations, setOpenLocations] = useState(false);
  const [openRooms, setOpenRooms] = useState(false);

  const [selectAssetTypeCtrlKey, setSelectAssetTypeCtrlKey] = useState(Date.now().toString());
  const [selectStatusCtrlKey, setSelectStatusCtrlKey] = useState(Date.now().toString());
  const [selectLocationCtrlKey, setSelectLocationCtrlKey] = useState(Date.now().toString());

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const refreshStatusList = useCallback(() => {
    setSelectStatusCtrlKey(Date.now().toString());
  }, []);

  const refreshAssetTypeList = useCallback(() => {
    setSelectAssetTypeCtrlKey(Date.now().toString());
  }, []);

  const refreshLocationList = useCallback(() => {
    setSelectLocationCtrlKey(Date.now().toString());
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [id, ts]);

  const assetNamesProps = useAssetNamesAutocompleteDataHook({ search: name || '' });
  const brandNamesProps = useAssetBrandsAutocompleteDataHook({ search: brand || '' });
  const modelNamesProps = useAssetModelsAutocompleteDataHook({ search: model || '' });
  const cpuNamesProps = useAssetCpusAutocompleteDataHook({ search: cpu || '' });
  const ramNamesProps = useAssetRamsAutocompleteDataHook({ search: ram || '' });
  const storageNamesProps = useAssetStoragesAutocompleteDataHook({ search: storage || '' });
  const officeNamesProps = useAssetOfficesAutocompleteDataHook({ search: office || '' });

  const factoryIdentifierNamesProps = useAssetFactoryIdentifiersAutocompleteDataHook({
    search: factoryIdentifier || '',
  });

  const expressCodeNamesProps = useAssetExpressCodesAutocompleteDataHook({
    search: expressCode || '',
  });

  const screenSizeNamesProps = useAssetScreenSizesAutocompleteDataHook({
    search: screenSize || '',
  });

  const serviceProviderNamesProps = useAssetServiceProvidersAutocompleteDataHook({
    search: serviceProvider || '',
  });

  const assetNameError = useMemo(() => {
    return assetNamesProps.items.some((item) => item.name === name && item.id !== id);
  }, [assetNamesProps, id, name]);

  const factoryIdentifierError = useMemo(() => {
    return factoryIdentifierNamesProps.items.some(
      (item) =>
        item.name === factoryIdentifier && loadedAsset?.factoryIdentifier !== factoryIdentifier
    );
  }, [factoryIdentifierNamesProps, factoryIdentifier, loadedAsset]);

  const expressCodeError = useMemo(() => {
    return expressCodeNamesProps.items.some(
      (item) => item.name === expressCode && loadedAsset?.expressCode !== expressCode
    );
  }, [expressCodeNamesProps, expressCode, loadedAsset]);

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <DialogForm
        open={openStatuses}
        dialogTitle={'Asset Statuses'}
        onClose={() => {
          setOpenStatuses(false);
        }}
      >
        <div style={{ padding: '0 24px 40px 24px' }}>
          <ChangedDialogContextProvider>
            <FormChangedDialog></FormChangedDialog>
            <EditAssetStatusProvider>
              <AssetStatusTable
                onClose={() => {
                  setOpenStatuses(false);
                }}
                onSelect={(assetStatus: any) => {
                  onSetAsset!((oldAsset) => {
                    return { ...oldAsset, selectedAssetStatus: assetStatus };
                  });
                }}
                onRefresh={refreshStatusList}
              ></AssetStatusTable>
            </EditAssetStatusProvider>
          </ChangedDialogContextProvider>
        </div>
      </DialogForm>
      <DialogForm
        open={openTypes}
        dialogTitle={'Asset Types'}
        onClose={() => {
          setOpenTypes(false);
        }}
      >
        <div style={{ padding: '0 24px 40px 24px' }}>
          <ChangedDialogContextProvider>
            <FormChangedDialog></FormChangedDialog>
            <EditAssetTypeProvider>
              <AssetTypeTable
                onClose={() => {
                  setOpenTypes(false);
                }}
                onSelect={(assetType: any) => {
                  onSetAsset!((oldAssetType) => {
                    return { ...oldAssetType, selectedAssetType: assetType };
                  });
                }}
                onRefresh={refreshAssetTypeList}
              ></AssetTypeTable>
            </EditAssetTypeProvider>
          </ChangedDialogContextProvider>
        </div>
      </DialogForm>
      <DialogForm
        open={openLocations}
        dialogTitle={'Assets Cities'}
        onClose={() => {
          setOpenLocations(false);
        }}
      >
        <div style={{ padding: '0 24px 40px 24px' }}>
          <ChangedDialogContextProvider>
            <FormChangedDialog></FormChangedDialog>
            <EditAssetLocationProvider>
              <AssetLocationTable
                onClose={() => {
                  setOpenLocations(false);
                }}
                onSelect={(assetLocation: any) => {
                  onSetAsset!((oldAsset) => {
                    return { ...oldAsset, selectedAssetLocation: assetLocation };
                  });
                }}
                onRefresh={refreshLocationList}
              ></AssetLocationTable>
            </EditAssetLocationProvider>
          </ChangedDialogContextProvider>
        </div>
      </DialogForm>
      <DialogForm
        open={openRooms}
        dialogTitle={'Rooms'}
        onClose={() => {
          setOpenRooms(false);
        }}
      >
        <div style={{ padding: '0 24px 40px 24px' }}>
          <ChangedDialogContextProvider>
            <FormChangedDialog></FormChangedDialog>
            <EditRoomProvider>
              <RoomTable
                onClose={() => {
                  setOpenRooms(false);
                }}
                onSelect={(room: IRoomItem) => {
                  setAssignment((assignment: any) => ({
                    ...assignment,
                    selectedRoom: room ? omit(room, '__typename') : null,
                  }));
                }}
                onRefresh={refreshLocationList}
              ></RoomTable>
            </EditRoomProvider>
          </ChangedDialogContextProvider>
        </div>
      </DialogForm>
      {asset.isCanceled ? (
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              color: 'red',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            CANCELED
          </div>
        </div>
      ) : undefined}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={5} />
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <GroupByTwoGrid>
              <FieldRow title="Asset Name" isRequired>
                <TextDropdownInputField
                  id={'txt-dd-assetName'}
                  placeholder="Input name..."
                  key={'name'}
                  value={name || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, name: value };
                    });
                  }}
                  filterProps={assetNamesProps}
                  error={assetNameError}
                />
              </FieldRow>
            </GroupByTwoGrid>
            <GroupByTwoGrid>
              {!!createdAt ? (
                <FieldRow title="Created Date" md1={4} md2={3}>
                  <>
                    {new Date(createdAt).toLocaleDateString('en-EN', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </>
                </FieldRow>
              ) : undefined}

              {!!updatedAt ? (
                <FieldRow title="Last Modified Date" md1={4} md2={3}>
                  <>
                    {new Date(updatedAt).toLocaleDateString('en-EN', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </>
                </FieldRow>
              ) : (
                <></>
              )}

              {!!createdBy ? (
                <FieldRow title="Created By" md1={4} md2={3}>
                  <>{createdBy.name}</>
                </FieldRow>
              ) : undefined}

              {!!updatedBy ? (
                <FieldRow title="Modified By" md1={4} md2={3}>
                  <>{updatedBy.name}</>
                </FieldRow>
              ) : undefined}
            </GroupByTwoGrid>
            <Box m={2} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={2} />
                </Grid>
              </Grid>
            </Grid>

            <GroupByTwoGrid>
              <FieldRow title="Asset Type">
                <div style={{ paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <SelectAssetType
                      placeholder="Select asset type"
                      assetType={selectedAssetType}
                      onSelectChange={(assetType) => {
                        onSetAsset!((oldAsset) => {
                          return { ...oldAsset, selectedAssetType: assetType };
                        });
                      }}
                      error={showValidator && !!errors?.selectedAssetType}
                      key={selectAssetTypeCtrlKey}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                    onClick={() => {
                      setOpenTypes(true);
                    }}
                  >
                    <ListAlt></ListAlt>
                  </div>
                </div>
              </FieldRow>

              <FieldRow title="Asset Status">
                <div style={{ paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <SelectAssetStatus
                      placeholder="Select asset status"
                      status={selectedAssetStatus}
                      onSelectChange={(assetStatus) => {
                        onSetAsset!((oldAsset) => {
                          return { ...oldAsset, selectedAssetStatus: assetStatus };
                        });
                      }}
                      error={showValidator && !!errors?.selectedAssetStatus}
                      key={selectStatusCtrlKey}
                    ></SelectAssetStatus>
                  </div>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                    onClick={() => {
                      setOpenStatuses(true);
                    }}
                  >
                    <ListAlt></ListAlt>
                  </div>
                </div>
              </FieldRow>

              <FieldRow title="Asset City">
                <div style={{ paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <SelectAssetLocation
                      placeholder="Select asset city"
                      status={selectedAssetLocation}
                      onSelectChange={(assetLocation) => {
                        onSetAsset!((oldAsset) => {
                          return { ...oldAsset, selectedAssetLocation: assetLocation };
                        });
                      }}
                      error={showValidator && !!errors?.selectedAssetLocation}
                      key={selectLocationCtrlKey}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                    onClick={() => {
                      setOpenLocations(true);
                    }}
                  >
                    <ListAlt></ListAlt>
                  </div>
                </div>
              </FieldRow>

              <FieldRow title="Office">
                <TextDropdownInputField
                  id={'txt-dd-office'}
                  placeholder="Input office..."
                  value={office || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, office: value };
                    });
                  }}
                  filterProps={officeNamesProps}
                />
              </FieldRow>

              <FieldRow title="Value">
                <CurrencyInputField
                  placeholder="Input asset value..."
                  size="small"
                  value={dollarValue}
                  onUndo={() => onUndoAsset && onUndoAsset('dollarValue')}
                  onClear={() => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, dollarValue: null };
                    });
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, dollarValue: parseFloat(e.target.value) };
                    });
                  }}
                  fullWidth
                  error={showValidator && errors?.dollarValue}
                  style={{ paddingRight: '12px' }}
                  maxAmount={MAX_ASSET_VALUE}
                />
              </FieldRow>

              <FieldRow title="Loaner">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={!!isLoaner}
                      onChange={() => {
                        setAssignment((old: any) => ({
                          ...old,
                          assignmentType: isLoaner
                            ? AssetAssignmentType.PERMANENT
                            : AssetAssignmentType.TEMPORARY,
                        }));
                        onSetAsset!((oldAsset) => {
                          return { ...oldAsset, isLoaner: !isLoaner };
                        });
                      }}
                    />
                  }
                  label={!!isLoaner ? 'Loaner' : 'Not a Loaner'}
                />
              </FieldRow>
            </GroupByTwoGrid>
            <Box m={2} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={2} />
                </Grid>
              </Grid>
            </Grid>

            <GroupByTwoGrid>
              <FieldRow title="Brand">
                <TextDropdownInputField
                  id={'txt-dd-brand'}
                  placeholder="Input brand..."
                  value={brand || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, brand: value };
                    });
                  }}
                  filterProps={brandNamesProps}
                />
              </FieldRow>

              <FieldRow title="Model">
                <TextDropdownInputField
                  id={'txt-dd-model'}
                  placeholder="Input model..."
                  value={model || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, model: value };
                    });
                  }}
                  filterProps={modelNamesProps}
                />
              </FieldRow>

              <FieldRow title="Serial No/Service Tag/IMEI">
                <TextDropdownInputField
                  id={'txt-dd-factoryIdentifier'}
                  placeholder="Input serial no/service tag/IMEI..."
                  value={factoryIdentifier || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, factoryIdentifier: value };
                    });
                  }}
                  filterProps={factoryIdentifierNamesProps}
                  error={
                    (showValidator &&
                      !!factoryIdentifier &&
                      validationResult?.factoryIdentifier === factoryIdentifier) ||
                    factoryIdentifierError
                  }
                />
              </FieldRow>

              <FieldRow title="Express Code">
                <TextDropdownInputField
                  id={'txt-dd-expressCode'}
                  placeholder="Input express code..."
                  value={expressCode || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, expressCode: value };
                    });
                  }}
                  filterProps={expressCodeNamesProps}
                  error={
                    (showValidator &&
                      !!expressCode &&
                      validationResult?.expressCode === expressCode) ||
                    expressCodeError
                  }
                />
              </FieldRow>

              <FieldRow title="CPU">
                <TextDropdownInputField
                  id={'txt-dd-cpu'}
                  placeholder="Input CPU..."
                  value={cpu || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, cpu: value };
                    });
                  }}
                  filterProps={cpuNamesProps}
                />
              </FieldRow>

              <FieldRow title="RAM">
                <TextDropdownInputField
                  id={'txt-dd-ram'}
                  placeholder="Input RAM..."
                  value={ram || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, ram: value };
                    });
                  }}
                  filterProps={ramNamesProps}
                />
              </FieldRow>

              <FieldRow title="Storage Size">
                <TextDropdownInputField
                  id={'txt-dd-storage'}
                  placeholder="Input storage size..."
                  value={storage || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, storage: value };
                    });
                  }}
                  filterProps={storageNamesProps}
                />
              </FieldRow>

              <FieldRow title="Screen Size">
                <TextDropdownInputField
                  id={'txt-dd-screenSize'}
                  placeholder="Input screen size..."
                  value={screenSize || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, screenSize: value };
                    });
                  }}
                  filterProps={screenSizeNamesProps}
                />
              </FieldRow>
            </GroupByTwoGrid>
            <Box m={2} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Box m={2} />
                </Grid>
              </Grid>
            </Grid>

            <GroupByTwoGrid>
              <FieldRow title="Purchase Date">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    value={purchaseDate}
                    onChange={(date: Date | null) => {
                      onSetAsset!((oldAsset) => {
                        return { ...oldAsset, purchaseDate: date };
                      });
                    }}
                    clearable
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={props.error || (showValidator && errors?.purchaseDate)}
                      />
                    )}
                  />
                </div>
              </FieldRow>

              <FieldRow title="Warranty Expiration Date">
                <div style={{ maxWidth: '150px' }}>
                  <DatePicker
                    value={warrantyExpirationDate}
                    onChange={(date: Date | null) => {
                      onSetAsset!((oldAsset) => {
                        return { ...oldAsset, warrantyExpirationDate: date };
                      });
                    }}
                    clearable
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={props.error || (showValidator && errors?.warrantyExpirationDate)}
                      />
                    )}
                  />
                </div>
              </FieldRow>

              <FieldRow title="Service Provider">
                <TextDropdownInputField
                  id={'txt-dd-serviceProvider'}
                  placeholder="Input service provider..."
                  value={serviceProvider || ''}
                  onChange={(value) => {
                    onSetAsset!((oldAsset) => {
                      return { ...oldAsset, serviceProvider: value };
                    });
                  }}
                  filterProps={serviceProviderNamesProps}
                />
              </FieldRow>

              <FieldRow title="Notes">
                <div style={{ paddingRight: '12px' }}>
                  <TextareaAutosize
                    className="MuiInputBase-input"
                    color="grey"
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      minWidth: '100%',
                      minHeight: '2rem',
                      padding: 10,
                      borderRadius: 4,
                      border: '1px #ccc solid',
                      overflow: 'auto',
                      marginBottom: '1rem',
                    }}
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Write a note or comment..."
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      onSetAsset!((oldAsset) => {
                        return { ...oldAsset, comment: e.target.value };
                      });
                    }}
                    value={comment || ''}
                  />
                </div>
              </FieldRow>
            </GroupByTwoGrid>
          </Grid>

          <Box m={2} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>

          <Box m={2} />

          {openAssetAssignmentForm ? (
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <FieldRow title="Current Assignee" lg1={3} lg2={9}>
                <div style={{ paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
                  {openAssetAssignmentForm === AssetAssignmentObjectType.USER ? (
                    <AssetAssignment isLoaner={isLoaner} />
                  ) : undefined}
                  {openAssetAssignmentForm === AssetAssignmentObjectType.ROOM ? (
                    <div style={{ display: 'flex', alignItems: 'start' }}>
                      <AssetRoomAssignment></AssetRoomAssignment>
                      <div
                        style={{
                          paddingLeft: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: 'blue',
                        }}
                        onClick={() => {
                          setOpenRooms(true);
                        }}
                      >
                        <ListAlt></ListAlt>
                      </div>
                    </div>
                  ) : undefined}
                  {openAssetAssignmentForm === AssetAssignmentObjectType.CONTRACTOR ? (
                    <AssetContractorAssignment isLoaner={isLoaner} />
                  ) : undefined}
                </div>
              </FieldRow>
              {!currentAssignment ? (
                <FieldRow title="" lg1={3} lg2={9}>
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={debounce(() => {
                      setOpenAssetAssignmentForm(undefined);
                    }, DEBOUNCE_TIMEOUT)}
                    disabled={!allowSubmit}
                  >
                    Cancel Assignment
                  </Button>
                </FieldRow>
              ) : undefined}
            </Grid>
          ) : undefined}

          {!openAssetAssignmentForm && !currentAssignment ? (
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <FieldRow title="Current Assignee" lg1={3} lg2={9}>
                Not Assigned
              </FieldRow>
              <FieldRow title="" lg1={3} lg2={9}>
                <div style={{ display: 'flex' }}>
                  <div style={{ margin: '0 20px 20px 0' }}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={debounce(() => {
                        setOpenAssetAssignmentForm(AssetAssignmentObjectType.USER);
                      }, DEBOUNCE_TIMEOUT)}
                      disabled={!allowSubmit}
                    >
                      Assign to User
                    </Button>
                  </div>
                  <div style={{ margin: '0 20px 20px 0' }}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={debounce(() => {
                        setOpenAssetAssignmentForm(AssetAssignmentObjectType.ROOM);
                      }, DEBOUNCE_TIMEOUT)}
                      disabled={!allowSubmit}
                    >
                      Assign to Room
                    </Button>
                  </div>
                  <div style={{ margin: '0 20px 20px 0' }}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={debounce(() => {
                        setOpenAssetAssignmentForm(AssetAssignmentObjectType.CONTRACTOR);
                      }, DEBOUNCE_TIMEOUT)}
                      disabled={!allowSubmit}
                    >
                      Assign to Contractor
                    </Button>
                  </div>
                </div>
              </FieldRow>
            </Grid>
          ) : undefined}

          <Box m={3} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={3} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignContent="center" alignItems="center">
            {!isNewAsset ? (
              <AssetAssignmentProvider>
                <FieldRow
                  title="Asset Assignees History"
                  md1={assignments.length ? 12 : undefined}
                  md2={assignments.length ? 12 : undefined}
                  lg1={12}
                  lg2={12}
                >
                  {assignments.length ? (
                    <AssetAssigneesProvider loadedAssignments={assignments}>
                      <AssetAssignees
                        assetId={id}
                        refetch={refetch}
                        disabled={!allowSubmit}
                        isLoaner={!!isLoaner}
                      ></AssetAssignees>
                    </AssetAssigneesProvider>
                  ) : (
                    'List is empty'
                  )}
                </FieldRow>
              </AssetAssignmentProvider>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

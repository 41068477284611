import { useLazyQuery } from '@apollo/client';
import { GET_ASSET_STATUS_NAMES } from 'graphql/ams/assetStatuses';
import {
  assetStatusNames,
  assetStatusNames_assets_assetStatuses,
} from 'graphql/ams/types/assetStatusNames';
import { useCallback, useEffect, useMemo } from 'react';

export interface IUseSelectedStatusDataState {
  items: assetStatusNames_assets_assetStatuses[];
  loading: boolean;
  error?: any;
}

interface IUseSelectedStatusDataProps {
  IDs?: string[];
}

export const useAssetSelecteStatusesDataItems = ({
  IDs,
}: IUseSelectedStatusDataProps): IUseSelectedStatusDataState => {
  const [loadParties, { data, loading, refetch, called, error }] = useLazyQuery<assetStatusNames>(
    GET_ASSET_STATUS_NAMES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadParties({ variables });
      }
    },
    [loadParties, refetch, called]
  );

  useEffect(() => {
    if (IDs?.length) {
      loadPage(IDs);
    }
  }, [IDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.assets_assetStatuses) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items, loading, error };
};

import * as React from 'react';

export const SvgLogo: React.FC<any> = (props: any) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="69px"
      height="55px"
      viewBox="0 0 69 55"
      enableBackground="new 0 0 69 55"
      {...props}
    >
      {' '}
      <image
        id="image0"
        width="69"
        height="55"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA3CAYAAABaZ4fjAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAX
KElEQVRo3u16eXCd1ZXn73fu971F0tNmSU+SZckWtkEGbBMbCFtwgCLxBAIhERizGajQHTLpdE0y
3T01MylPJzU96emununMVGc6jQE7tgERICwxexwSMgFiMF6EN2xZsmTt29vf++4984dsI8s2CBtm
aqr4Vb1633buOfd3z3e+c8+9wGc4ATxDeWmuqIgxFApnjHEA0NPTMwagMKVtnT17diRIBDMQRZFz
LmetHerr60sDUAAGgDty/FH26tS2T3L+/46U2eXl5aa09D4A85zqYZLVhYI+MJ4ef39GJNZAh4xF
LukNDIwUGhquMiLXOmCUylmgvpBIp5+P+X4ljSllDplcGMPpdDrwfT/S19fXPxuIBA0NM5xzI+Eg
iKUA9Pf3j8ypaTiHikwCuUR4YGBM4o11YiRUYMGZpBnoHOscO0LyacGcCSnlkUiEfiQeEL852NW1
tjQWCxsxc6KRUBU981fqSTmMpLMlJfmQyEUgN1vgWUMMw6EuQq8Az7sfwqQaWWyMLA1HIoiIXDgy
Pr61tKws5oWj1/o0Z9MzlxoJpSqrKiGGP4Vv4r5nMqHyckOffyqCZUbkK/SRKSmL7R8fHw9Ot19y
JqQAgAJR49jSWN94mSfS4IBuVc0DUMKl1Zo+z/OSSg5COd+oXuiAmRTUOw8zSD3kLLsF3EfVYnHO
U7JxdkPDxS4WK7KJsedAXK7k9oPdB9/I5XJOAQs4Z4HhnCqh2meh26nodWTy0KFDp+0lZ+QpS5bA
n9NSUR2K4Kxoic4vq8DcojLIzNmFdwsFO5LLmh5VPURRPdjVta8kFhsTYRlVoiACOve7nHNbCUSN
YbFSTcG5XxvVMQeEjDFiVce7BgZ6SyvKh8eTyXcymUw2HA5r2AunJOw6amqCsvrmIFReQTTM1fdD
vkuVlRcGmpujfR0d6fzp9u3jxhTeemfN+eqbRXCuAip5wOVFGDhHFSLqFBcRcBR0BE63Gs9t2fgv
/X1nMnJH0dqKkBTHPyeUReoYFmhWRQtUBhOuoQaivirDJFSA9kKi9622NiQ/DVJ4y6r4xUJ+jZAW
pfutg7xLtWPUYIwmlLF5FBkP31TwZkDrCSQA7FPiNahuVQYvPbJmsOd0yFi+HOGK2pplFnIVHDpo
XC8dAxAlCuyXnPRa33rOOM8ELAKlUoXzDblCVfdR8Dyz+RfWrx8en46+j3x9br+9qu68paX/lpS/
IOVLStSr1RcpNgeVwKYGdkqkqN5ZaSBxFoksya0ACHIBFUPOcQfFfGXBopJIY12yc98+2OkScutd
tedGYrHvQLVEhHHrCg9oyu52ppDI5M0eVTeuPivo6Bl6yym8npC4EJcqeRUpCxX4PDzT0rKwaHDn
u6lDZ+Qpt9xd9zlD3q/qalSxhWAEosOELABwjaomAfwGigwExYTGHDhLFONK1FJxGICxtH9JqsCa
r5Lszdrk40+uTQx9qGWtMLeUxJeTvFyte9WJHDIFV66eXCbkzSAL6uy/KDHk0VzmFOeAehGVpYAW
lAwTCKuqEjig0IdBLibdYz2276nNDyH7sUlZcVf8aopcH9j8BmSkHRjIA4BfUnsvyL9VIEVFFUin
CuVEawT0J0Ggz3qG1ztyp9Xxp9uakkNYDW1tLa2QWNG9oqBvUw+sPQUxy5bBizfXrhTlOarYJMSV
qvYNpfkGiWoq5oNoUWWOVKegB6iBwpA8rk8KDaB8K7Duz40wTHKVUP8gBbdh3bq+1LRfnxWr6q6g
4DqFe/yxhwf/0N6ezi9cEl/q+bFaJxpBYP8pSOf/I8NeiuCVJEIgDAAD4rCCe0ncQOJ+Qfjq84dK
23dsS3S2t+cyC+Ym/2j82OXKUPPCc0N7tm/P5Kaol89fWftlIT/nnH3A+RgTyG0AvklKDRXVIM8F
SBI+wBABj6BMJeTIOAmp9UJcCqfbLO0DVFluxVW1zEvtam8/8VWeSgpvubNqvohcD3WbH3mo/zdH
b+zYlure/m6ye8fW1M4d29KdNTVZW1JWQgILQc6c1EQLwZsBhgCUkcgr3a7zF0fSO7ZmBtvbYWeU
J9+KVZR83RkTqSpP7unoOJZ9yq131lxMI1cp3DbSLDXKe0h+kUQ9wTqQJQo4qBoAjuQ0ci0KiCEI
h8TRQuRZKpcbP5adUZHsnqR/wojJJzeuKi8TY74A4ODhjv5ff5iazZsR5INgtwLvnWACQRIzSTgA
eRLJjQ8N7Zokmy2o/XuQX54xu3rO0et33BGvgieXg7oNKhWEXgfwIFTfVHAU0LQ63QTFa0e+bns+
mhAAUAeFgyJDQTI9lB9Vcb9UwbWVzfHGqU8fI2X1akgEkcWqrEPWPb95Mz4yTTaiAoXBKSdhTFjV
/zHa2/fE1DttDw30wrqNIXj3L1kCv7UVoTy5BMoyZ90oiWuhFMKFoXgd0LVQffwI6YcAHCbx2+mR
gpQSb1PdmBJzimaEyh95sO8Nhev3HJYtuQ/+SUnZs6esjMACKts3bpxespW0mgDxnkIHTxgZYFSh
/UbtC5s2IXcyeZvu2wxq2dnn1l4QROLlInIdgJQx3l8BuJHCZQBvVqoHtVsU+vxEgNVVAKqguGSa
pAgVaVj3Di3eeuTBni4AsOoe80QubEpXVZ2MFFrj1QKcNV5IvzpNRdi0fnicGjwGxcsKjE1EeoxC
8d+V7t9T9Z2S0EDHqeTb2mAp+Kl6/HbEw3mgLgDZCugXjgVNsoaU/wCavwPMNwlUkQyUHAJZMR07
VVFEcJHzvPTGh3t3HtP/0ECvUrf7If+qE0hZvhwhMd5cR+1+bsPYyOQHli2Dt3JV9bzWu0uqT6aw
kBrYD7UPKvR/AjysdJ2atT82GrziwDcShapLV64sq8ApPv89s3rfhqKOKisJjBG4AODUD4BPMA7q
pQr940RPQVU9oOrWqNNfABg7KSHQgMA+hb4CZ094Jofsc0Z5/QmkhOPlUVDOI/StqULV1dBswWZM
UNx8qtE+jP7fMmP/EepeIhhmVL7l1L/fFoJnoBJSE1244p6qupPJb16NQFX/oIJLlR8exwhYBzwO
wAe0GVBLwf8S5n6gqr+EakqBEQf8UKHPH5FKKfVngQaPU7V0avx44oHRLsAVr1pVXn4cKVEvFCZx
Vpp97Sfr9BPrh7uzJrf7VMZufgjZjRv7+yj8a7jg3ziHDgjm+iHvbhW3E+QsBtLU2lpdctLOUjdT
0Q1oAR+OiABXAPomVX8auOD7hfHe7esfHH5PnVurxE6q+8+JbOofAhf8O4W+AWgeyjKkggOgVM7L
1p83pU2nyh05RhYdvWAAYPGCaKkTc90vHkxuPJU1u7ZmT5kWH8X2d5JjM8rTBypixbszeX3NM/wC
KMso/FOIXC8hXtk8rzSd7Q+Pj6XTOQCuqa6ppag8GAxHUN7bJU/7EX09Ocot4yPcVSjoH/0wAwqq
CXgASWAuoM5RNz320MBT7e0T3nXBwlS/M7E+p7rTyw51opDuFb/kHFCfViKRDWNryEo9DL2qxcn+
jq0feOW5i0oalazZuTW5BQA8AAgCUPwzr20CE/kL0BcA2N96X8UPpBC5xZDfgioASQ/1mK97RUVf
n11f/zcdPT27jIcf7dsWkVBUfxSk+KXu94NfWcgldOyF8aoqKux487k27vmcc8StYlCMWbWvTda7
bh1S119/eFOkpjQCwI5FEKkk5iv0bc9hWzH9/6qi21Xt89GgMgQMHxtkoT0MmIuOnnsAYH3rEf5p
l+9OBZs2RZ6P72AiJy+MDaFv8LAYKM4Sz/tyc0XFYQKSd+7bSMvPIBjXAp5TwYgK3/XyQW0uZy5y
TnVSKtRlxf2rtgcGdkzV98wzSAPj6SOnqda78ZcezMNKbCBxIwybCIlUGH/01ntqE8xmXtqwYWwE
lDzAyHGkhNUULBn6pEmZGNkjUwnVvUEgbzafWwjKqt2+rr2eHyn2mw6268azruoaLhmvfXDHW75n
guAw/Ei9c7ipqFIizefYllBIK48RrfY7j67p2zEd1W0P9rbffFfd90gsJdx3oeyzDmnjiROHP0e4
6PsrVxWtt05HSD32ZfIAIA0goupWr4asXn36VfCpiKpLOYcXIHo2iLMr4/oXE7NZMY1zXYEw34xU
jl1c4lc1mjJe0dHV9d0jopsAbLr1mrofkfwKgGOjKCq5a++IF5dl815b28jYR9nw2MOHf9vaWvGu
REKzBLZcjFGkCz02ah4R8CaIbDFOr3GQY1MGAQDje4ECA9v2VtZ/kk6yfv1wkoqfQ7FTFUIgTNAj
QVDzoP1PRYWxcRbMUgUW4MRCuuik/EaBnIqeVS78ipSErpimGdrWNjL26Lq+HQiyu4Q8m1H/bwiZ
51T/ORvwPYANltmtx5GSGwqyDro74ocWfpKkAHCDzu1SdRum3qDqW6D5XVsbLClfJMmbbjthUA5C
cWwKoUCXZPmsJ/y8c/bwdI1YvhzhlXfVLdFw8bcB/heAK4RYQTHnQgMDomH/tuG9x5ESDg+m1blt
SizFJ7DsMRkzgnRUKXOOpu0KjKnq79ThpwnDga/dGZtBco6DPh4KR7503BCz8Aygv4YiDwCE7tuw
4fDBgO5XOTPdGTJQVltfA4MfivB7R2bvIYAXQbEk7MtlDty+ZQuO5UgCTCRo6gpdAEzr7VXzPkFO
GIQjDQSuAQBVtYTbZK3+YMj1PvfMP/ekI6b4q6ruRav6GlUvvPaOePFR4UfWDPao00dAdKqqQvE7
AHhsTd/LT68ZTEzXCIVWK3AFwNhxxkFbCK6gDY6bxR/zimx0ZFBFd5uQdw0+IW+5cVV5mYH3JwAb
FBgGsN6p/jfN9L724jqkVq4sqwBleS6f34BkrhvQ/ZXCZZPb8J1uVtW/A/WfSLfxNMwQSqCAvqrQ
JwH9IDMnqHDplOeO+5odm3jt2YLC+QtjjuTChYti6fMWFc1esCg2r/ni0v49WxIflX6fFIsXVraI
4d+C2E7oX+fyuTXIDO5pa5soAZ63tOLPlLq1be3Aa+3t2cLCxdEkxCxvWRje1f5uJgEA27alCvXn
F+8KCV9/9MG+01ki0frzYoko+Zo1+qJxKFNyCaD7ocg56j8+sWZgDyYlQt5k6SDdu5fFNe8Zmiut
4TZDXBEJ8j0Adn9cSyYod/eocn+g7k+K0bd3w/oPKugr76z9IoDqbJB64MglN2hlb6XRPZ7nr2ht
xU/a2iZiyYsTBebUaViAqfL33TP3pQIsc5qtU6dvFzT9DvAh5ci2NuSzw+4VpYooF6vFDFi/ZPrq
jwfJy5zqjwPn9Tw0aUnh9nuqzlbDlWrduslLHS+u60vlC/mXoTBSHL8Jn3DQX926IDQ72jxWZCID
oOtwcE+dbKnlBKVPPz2Y8ANdK0SVCMt940amp/JkcGudSb/y5NruY4pbb6+e69T7Lq1uCDL9O6dK
PLF++FDA4JeGXHLL3fHrP0liVre157en3y0eLozWFgJ99tGH+98/2XOnXPdpvbuk2mjJ/Ur2Opt/
tm3tUA9OvSGGra3wB4oh1Sm4QnF5UVFemQVLn9gw2gXAtbYiaoqrlxLmRlW82nuw94UPqwPfelft
uSBvV6A955K/enJtYniy/mXL4FXPjrd4kKuVIB06nWAGCVXoIRS0y3OFziNLpbzttsoYQv4XHHgF
LX6xYW3vm6fS/aErhHfcES+2IX4LTsucuCcscgfbHhg/6jm6bBm8yoaqat/zGkhpJmwM5LiqXAi4
PnWyzaV7Npvi2npSLlG6RVD39CMP9v8B09hUc9ttlQ02FF4FIGXUvZwQ2/H0msHEinuq6p3zFnvk
aiUuIOhNlVXVDoC/CPzMDxGYWl+9qxScFbDwcNuawQ+NkdNaYL/1zppLaOQmC+wXdfscvSJKoTMc
RMatsXc44D4AdcCRFTngKS3gx0IVFVcPsoXgaEHtk20PDfROR+dRtLYiJCXx5Qay1Cn2OMVB8XSF
qNwL4CMmseqg9lsKmakqu8cK2Wc3TWORfdpbMZYvR7i0rvYqUV4G4hwFhkIwOxRcYGFvmCBFrQIZ
qD4PxWFCuhVIO5t7+dF1Q7twBvvRWu8trTQ2+mWA8yiYQfBagNVQxBTwSHCiHsskoCMAelRxQNX9
GjQvHa3gTwenJKWhoWEmyQpr7f6enp40AH9OY+MNSy/ufNLG4k1hJ/NBjUO1lMJyKEMTkzyOA65v
bISJzr2+v31713MACmfF4zVqTGR/T0/n6RIDTMSSuqbas0FtFpGZTlGhyigEoGpOqaO07FUN9ttM
eFdb26HMx9XhneqGMeZsA1xHcj2ArU0NDV+F8nttbXg8HkdfkSexsUzm98PDw4k5c+rni5oWDURc
kHu7o7fv4OyZMxeJ4ffnzGyMHejufMz6fgtFqgF0zi6fXc7iQlNOdW9PT0+6sb7xss6eztfj8Xhx
1ERndfR0vN/Y2DjfA+YAGE1kMlsHBgaSTfVNl27efPD3c9G7r1DXFLbGvu6LLDEGFQDgCu6d/YcO
HWyaOXOJ8Yqa1blZ8Xj8FWOMRoxZAGv7g5SXMEWF0hw5cGSwPwYpQNSpZo1y+ZxZs4ohcgFVLQAT
9aLzaNwNsaKiN2Ox2C46LFXVMaUbp+eVAqAxpljJHAWx5oambzi6QCDlAMAidwWNv9TLu0cBvGcM
VwB43ff9coq7Yk59vdLxHEeXBLkgHA73AnjfGPzr5sbGSlUtMdTF6UzuR14olHDqna/UQfW8RNPM
mZeJMbMC1fc91bLiUPROZ/CkWr2BxgxLqe1ReFkDvIWJUtIJ+PAcQGW7Ou0nZFXg3CMkxxobG0tF
9A4CZ3k0l6tqM1VnOlVjSWtFao62q851uyyfUCAqlG8AwKxZs85SD5eouibf522zMTtMQX1DQ8NC
ca5FgToaUwqiLFDtIRA2qtWY2NFQ5kQOBSL9ClYODAwkkUrtUuoAVA90dHT0giwnMJPksIoUg64p
n8/7IshbDXYJeQmBpV4Q+Kfq9il3MpWES13AoM9Rt9DI7s7OzvbykpJs6lB2r19kxtUWngqs7BFf
+i3QQWCGUY3lrd2eSCRGiz3PIhQaOnj44K6KSLhbRHqtk10S5DPOmF3GBi84YrhjvLOzvKSkXURa
4HmhwNpXc4XC/pATFcpcCAazQbAtmUymykpLkx2dna8bYwYiEhobTYztr8pmNYiW5QK63kQiMW48
r8fQzxugBVbTLpt5whkzQmt7stZu84E9gch72SDoSaVSH3tOR3wQiI/+HyVRpjzDI6+iP0VmsifK
kd/kdie340+RlyPnk19xc4rjyW0elQ1NkeUpjj/DZ/gMn+FTxacScG5aWdfkGxdzagptP+85rqp1
KqxeDXl3/8yKJ9d2j3ztzpkV0SAoKxRcP0pM1FOZkbV2+Ml1fQMA9Gt3xGuOHn8a9ntn3sSJiIT0
IqU3Xy1+f9ttlYetH/ochEkJVKxIs0cmocHB4Vjfnk0/mdjltKurflHU6GUr7617xlpcpGHj+RGv
V9SNBYYVYTFXt7ZijVdcd2UQFA4AGPg0bAc+4crWUTiKAGz2PUYy6leRZoU6mVMQkxfhhRbaTDH5
kt4PRloKzgdwiVUpN4J5UDSoYn6uEIzTaoyKGkRrFpPmaiPe+a2rZi46AxP/75MCAATSgQsGTSEz
CtVthqyDDcbg0G3A/T9f07P7aA0WAMQFXQDg5TIHLPAcyaRCR9MuP6S0hwlW+2IuUnUHHGTUAK3/
X5EiKrWqWkeiyZSUnENINRREyBkKiq3T+Mo7ay9qvaf+7KM2WN+br2QZALggO2hpO6DujVi05FKF
LFLVTgv3BoBzjJFaUqe1yH46+FRiSsYWNoZEn5WcNyaSzedN0cEAmq0ODYyP5Kt/FtVQYdQL8uFE
JMCRCtxoPv9ORdj/s+7u4XRoVtwWuez/9lOjCZSWjkPDobzLFrxMIuEi9T3wggyS9mOXBD7DGeD/
AFQjbzrG9bAUAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA2LTAxVDE2OjI1OjQ2KzAwOjAwWx2L
ngAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wNi0wMVQxNjoyNTo0NiswMDowMCpAMyIAAAAASUVO
RK5CYII="
      />
    </svg>
  );
};

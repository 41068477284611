import { FC, useCallback } from 'react';
import { ValueEditor, ValueEditorProps } from 'react-querybuilder';
import { HierarchyMultipleSelect } from 'components/HierarchyMultipleSelect/HierarchyMultipleSelect';
import EasyAutoComplete from 'components/EasyAutoComplete';
import { DateRange } from 'components/DateRange/DateRange';

export interface ICustomValueEditorProps extends ValueEditorProps {}

export const CustomValueEditor: FC<ICustomValueEditorProps> = ({ ...props }) => {
  const {
    handleOnChange,
    operator,
    value,
    fieldData: { dataType, filterOptions, name },
  } = props;

  const onhange = useCallback(
    (updateValue: any) => {
      handleOnChange({ ...value, ...updateValue });
    },
    [handleOnChange, value]
  );

  if (dataType === 'date') {
    if (operator === 'past') {
      return <div></div>;
    } else if (operator === 'future') {
      return <div></div>;
    } else if (['older', 'younger', 'sooner', 'later'].includes(operator)) {
      return (
        <DateRange
          value={value}
          onChange={onhange}
          // title={['older', 'younger'].includes(operator) ? 'Ago' : ''}
          title=""
        />
      );
    }
    // ssss
  } else if (dataType === 'hierarchy-multiple') {
    console.log('hierarchy-multiple');
    return (
      <div>
        <HierarchyMultipleSelect
          selectMode={'leafOnly'}
          items={filterOptions}
          selected={value}
          selectedChanged={(value: any): void => {
            handleOnChange(value || [{ id: 'All', name: 'All' }]);
          }}
        />
      </div>
    );
  } else if (dataType === 'dropdown') {
    return (
      <div>
        <EasyAutoComplete
          key={`dd-qb-${name}`}
          items={filterOptions}
          selected={value || filterOptions[0]}
          label=""
          textFieldStyle="outlined"
          optionsLabel="name"
          selectedChanged={(value: any): void => {
            handleOnChange(value);
          }}
          getOptionSelected={(option: any, value: any) => {
            return (
              (!!option.id && option.id === value.id) ||
              (!!option.key && option.key === value.key) ||
              (!!option.name && option.name === value.name)
            );
          }}
          style={{ backgroundColor: 'white' }}
          textFieldStyling={{ margin: '0px' }}
        />
      </div>
    );
  }
  return <ValueEditor {...props} />;
};

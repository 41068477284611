import { API_BASE } from 'constants/config';

const GRAPHQL_API = `${API_BASE}graphql`;

const serverPaths = {
  API_BASE,
  GRAPHQL_API,
};

export default serverPaths;

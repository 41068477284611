import { Container } from '@mui/material';
import { FC, useEffect } from 'react';
import { forwardRef, useState } from 'react';
import { PrintingFooter } from '../PrintingFooter/PrintingFooter';
import { PrintingHeader } from '../PrintingHeader/PrintingHeader';

import s from './style.module.scss';

export interface IPrintingMeta {
  onReady: () => void;
}

export interface IPrintingData {
  onReady?: () => void;
  printView?: boolean;
}

export interface IPrintingTemplateProps {
  children: any;
  onReady: () => void;
  DocumentPrintingMeta: FC<IPrintingMeta>;
  DocumentPrintingData?: FC<IPrintingData>;
  subtitle?: string;
}

export const PrintingFrame = forwardRef<HTMLDivElement, IPrintingTemplateProps>((props, ref) => {
  const { children, onReady, DocumentPrintingMeta, DocumentPrintingData, subtitle } = props;

  const [metaReady, setMetaReady] = useState(false);
  const [dataReady, setDataReady] = useState(!DocumentPrintingData);

  useEffect(() => {
    if (metaReady && dataReady) {
      onReady();
    }
  }, [metaReady, dataReady, onReady]);

  return (
    <Container maxWidth="md" ref={ref}>
      <div className={s.documentPrintingTemplate}>
        <PrintingHeader subtitle={subtitle} />
        <DocumentPrintingMeta
          onReady={() => {
            setMetaReady(true);
          }}
        />
        {DocumentPrintingData ? (
          <DocumentPrintingData
            onReady={() => {
              setDataReady(true);
            }}
            printView
          />
        ) : undefined}
        {children}
        <PrintingFooter />
      </div>
    </Container>
  );
});

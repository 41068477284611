import { IHeadCell } from 'components/ui/TableDnd/components/TableHead/TableHead';

export const roomsTableHead: IHeadCell[] = [
  // {
  //   id: 'order',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Order',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Room',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: false,
    dataCell: {
      connected: 'right',
      wrap: 'box',
    },
  },
  {
    id: 'isSelectable',
    numeric: false,
    disablePadding: false,
    label: 'Selectable',
    sortable: false,
    dataCell: {
      shrink: 'content',
      align: 'center',
    },
    transformFunction: (data) => (!!data ? 'Yes' : 'No'),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    isActionsCell: true,
  },
];

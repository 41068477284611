import { FC } from 'react';
import { Switch } from 'react-router-dom';
import { AssetProvider } from 'contexts/asset/AssetContext';
import { ViewFrame } from 'template/ViewFrame/ViewFrame';
import { AssetHeader } from 'template/AssetHeader/AssetHeader';
import { AssetTabs } from 'template/AssetTabs/AssetTabs';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { useAssetManager } from 'hooks/assetManagerHook';
import { newAssetTabs } from 'constants/tabs/newAssetTabs';
import { AssetAssignmentProvider } from 'template/AssetAssignment/AssetAssignmentContext';

export interface IProps {
  routes: Array<any>;
}

export const NewAssetPage: FC<IProps> = ({ routes }) => {
  const assetManager = useAssetManager({ assetId: undefined });
  return (
    <AssetAssignmentProvider>
      <AssetProvider assetManager={assetManager}>
        <ViewFrame
          header={<AssetHeader></AssetHeader>}
          tabs={<AssetTabs currentView={newAssetTabs[0].value[0]} tabs={newAssetTabs}></AssetTabs>}
        >
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </ViewFrame>
      </AssetProvider>
    </AssetAssignmentProvider>
  );
};

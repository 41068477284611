import React, { FC } from 'react';

import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { Button } from '@mui/material';

export interface IConfirmationButton {
  action: any;
  text: string;
  disabled?: boolean;
  color?: any;
  size?: any;
  // [key: string]: any;
}
export const ConfirmationButton: FC<IConfirmationButton> = ({
  action,
  text,
  disabled,
  ...props
}) => {
  const { confirmRequest } = useFormChangedDialogContext();

  const onClick = () => {
    confirmRequest!((confirmed) => {
      confirmed && action && action();
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={debounce(onClick, DEBOUNCE_TIMEOUT)}
      disabled={disabled}
      {...props}
    >
      {text}
    </Button>
  );
};

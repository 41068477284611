import { useUI } from 'contexts/UiContext';
import React, { FC, useMemo } from 'react';
import { SNACKBAR_AUTOHIDE } from 'constants/config';
import { Snackbar } from 'components/ui/Snackbar/Snackbar';

export const Layout: FC<{ children: any }> = ({ children }) => {
  const { snackbars, closeSnackbar } = useUI();

  const snackbarsList = useMemo(() => {
    if (!snackbars.length) {
      return [];
    }

    const { id, text, severity, ...props } = snackbars[0];
    const defaultAutoHideDuration = SNACKBAR_AUTOHIDE;
    return (
      <Snackbar
        text={text}
        severity={severity}
        key={id}
        id={id!}
        onClose={() => {
          closeSnackbar!(id);
        }}
        autoHideDuration={defaultAutoHideDuration}
        {...props}
      ></Snackbar>
    );
  }, [closeSnackbar, snackbars]);

  return (
    <div>
      {children}
      {snackbarsList}
    </div>
  );
};

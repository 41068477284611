import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { GET_ASSET_ROOMS, GET_ASSET_ROOM_COUNT } from 'graphql/ams/assetRooms';
import { assetRooms } from 'graphql/ams/types/assetRooms';
import { assetRoomCount } from 'graphql/ams/types/assetRoomCount';

const getCount = (dataRoomsCount: any) => dataRoomsCount.assets_assetRoomCount;
const getItems = (dataRooms: any) => dataRooms.assets_assetRooms;
const getItemId = (item: any) => item.id;
const filterToAPI: (filterValues: any) => any = (localSearch: any) => ({
  nameContains: localSearch,
  isSelectable: true,
});

export interface IRoomsAutocompleteDataProps {
  search: string;
}

export interface IRoom {
  id: string;
  name: string;
  email?: string | null;
}

export const useRoomsAutocompleteDataHook = ({ search }: IRoomsAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetRooms, assetRoomCount, IRoom>({
    GET_QUERY: GET_ASSET_ROOMS,
    GET_COUNT_QUERY: GET_ASSET_ROOM_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};

import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

export interface IUseItemState<T> {
  items: T[];
  loadPage: (page: number) => void;
  onOpen: any;
  getOptionSelected: (option: any, value: any) => boolean;
  loading: boolean;
  errorItems?: any;
}

interface IUseItemProps<T, TFilter> {
  GET_QUERY: any;
  getItems: (data: any) => T[];
  getItemId: (item: any) => string;
  filterToAPI?: () => TFilter;
  order?: any;
}
export const useAutoCompleteSimpleDataSource = <
  itemsList extends unknown,
  IItem extends unknown,
  TFilter extends unknown = undefined
>({
  GET_QUERY,
  getItems,
  getItemId,
  filterToAPI,
  order,
}: IUseItemProps<IItem, TFilter>): IUseItemState<IItem> => {
  const [items, setItems] = useState<IItem[]>([]);

  const [
    loadItems,
    {
      data: dataItems,
      loading: loadingItems,
      refetch: refetchItems,
      called: calledItems,
      error: errorItems,
    },
  ] = useLazyQuery<itemsList>(GET_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadPage = useCallback(() => {
    const variables = {
      filter: filterToAPI ? filterToAPI() : undefined,
      sort: order && order.length ? order : undefined,
    };

    if (calledItems) {
      refetchItems!(variables);
    } else {
      loadItems({ variables });
    }
  }, [loadItems, refetchItems, calledItems, filterToAPI, order]);

  useEffect(() => {
    if (!errorItems && dataItems && !loadingItems) {
      setItems((items) => {
        const oldIds = items.map((item) => getItemId(item));
        const appendItems = getItems(dataItems).filter(
          (item) => !oldIds.includes(getItemId(item))
        ) as IItem[];
        return [...items, ...appendItems];
      });
    }
  }, [dataItems, loadingItems, errorItems, getItemId, getItems]);

  const onOpen = useCallback(() => {
    if (!items.length) {
      setItems([]);
      loadPage();
    }
  }, [setItems, loadPage, items.length]);

  const getOptionSelected = useCallback((option: any, value: any) => {
    return option.id === value.id;
  }, []);

  return {
    items,
    loadPage,
    onOpen,
    getOptionSelected,
    loading: loadingItems,
    errorItems,
  };
};

import { FC, useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useComponentContext } from 'contexts/assets/AssetsListContext';

import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { assetsTableHead } from 'constants/assetsTable';

import { DEBOUNCE_TIMEOUT, TYPING_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { blue, grey } from '@mui/material/colors';
import { Box, Button, Grid, Tooltip, IconButton, Divider, Typography, Link } from '@mui/material';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

import { ExportToExcel } from './ExportToExcel/ExportToExcel';
import { CustomQuery } from 'components/CustomQuery/CustomQuery';
import { useAssetsQueryBuilderConfig } from 'hooks/assetsQueryBuilderConfigHook';
import { CustomValueEditor } from 'components/CustomQuery/components/CustomValueEditor/CustomValueEditor';
import { ActionElement } from 'react-querybuilder';

import FilterListIcon from '@mui/icons-material/FilterList';
import s from './style.module.scss';

export interface ITableViewProps {
  onReady?: () => void;
  printView?: boolean;
}

export const ReportAssetsList: FC<ITableViewProps> = ({ onReady, printView }) => {
  const assetsQueryBuilderConfig = useAssetsQueryBuilderConfig();
  const {
    query,
    filterValues: filterValuesQueryBuilder,
    clearAllFilters,
    filterApplied,
  } = assetsQueryBuilderConfig;

  const {
    totalItems,
    items: assets,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    // filterApplied,
    // clearAllFilters,
    onAssetSelect,
    filterOptions,
    filterValues,
  } = useComponentContext();

  const [expandQueryBuilder, setExpandQueryBuilder] = useState(true);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const onFilterChangeRef = useRef<any>(onFilterChange);
  const [prevFilterValuesQueryBuilder, setPrevFilterValuesQueryBuilder] = useState<string>();

  useEffect(() => {
    setExpandQueryBuilder(true);
  }, [query]);

  useEffect(() => {
    console.log('prevFilterValuesQueryBuilder', prevFilterValuesQueryBuilder);
  }, [prevFilterValuesQueryBuilder]);

  useEffect(() => {
    onFilterChangeRef.current = onFilterChange;
  }, [onFilterChange]);

  // debounce filterValuesQueryBuilder changes
  useEffect(() => {
    if (onFilterChangeRef.current && filterValuesQueryBuilder) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      const allowRefetch =
        prevFilterValuesQueryBuilder &&
        prevFilterValuesQueryBuilder !== JSON.stringify(filterValuesQueryBuilder);

      timer.current = setTimeout(() => {
        setPrevFilterValuesQueryBuilder(() => JSON.stringify(filterValuesQueryBuilder));
        if (allowRefetch) {
          onFilterChangeRef.current(filterValuesQueryBuilder);
        }
      }, TYPING_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [filterValuesQueryBuilder, prevFilterValuesQueryBuilder]);

  const ArrowIcon = (): JSX.Element => {
    if (!expandQueryBuilder) {
      return (
        <ArrowDropDownIcon
          fontSize="medium"
          style={{ marginLeft: '4px', verticalAlign: 'middle' }}
        />
      );
    }
    return (
      <ArrowDropUpIcon fontSize="medium" style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
    );
  };

  const Shadow = ({ ...props }): JSX.Element => {
    if (!expandQueryBuilder) {
      return (
        <div {...props}>
          <div className="shadow-holder" style={{ position: 'relative' }}></div>
          <Box mt={2} />
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Divider />
              <Box m={2} />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'right' }} {...props}>
        <Link component="button" onClick={debounce(toggleVisible, DEBOUNCE_TIMEOUT)}>
          <Typography variant="caption">{getHideShowTitle()}</Typography>
          <ArrowIcon />
        </Link>
      </div>
    );
  };

  const toggleVisible = (): void => {
    let v = expandQueryBuilder;
    v = !v;

    setExpandQueryBuilder(v);
  };

  const getHideShowTitle = (): string => (expandQueryBuilder ? 'collapse' : 'expand');

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2} className={s.container}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                // padding: '16px',
                // marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              order={{ xs: 1 }}
            >
              <div style={{ paddingLeft: '10px' }}>
                <Tooltip title="Toggle Filter Definition Visibility">
                  <IconButton
                    size="medium"
                    style={{ color: filterApplied ? blue[500] : grey[500] }}
                    onClick={debounce((e: any) => {
                      setExpandQueryBuilder((old) => !old);
                    }, DEBOUNCE_TIMEOUT)}
                    disabled={!filterApplied}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Reset Report
                  </Button>
                </Box>
              ) : undefined}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
              order={{ xs: 2, sm: 3 }}
            ></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0' }}>
          <div style={{ width: 'fit-content', padding: '10px 0px 0px 20px' }}>
            {filterApplied ? (
              <Link component="button" onClick={debounce(toggleVisible, DEBOUNCE_TIMEOUT)}>
                <Typography variant="caption">{getHideShowTitle()}</Typography>
                <ArrowIcon />
              </Link>
            ) : undefined}
            <div style={expandQueryBuilder ? undefined : { height: '70px', overflow: 'hidden' }}>
              <div
                style={{
                  width: 'fit-content',
                  padding: '5px 0',
                }}
              >
                <CustomQuery
                  {...assetsQueryBuilderConfig}
                  controlElements={{
                    addGroupAction: () => null,
                    combinatorSelector: () => null,
                    addRuleAction: (props: any) => (
                      <ActionElement
                        {...props}
                        label={props.testID === 'add-rule' ? '+ Add Condition' : props.label}
                      />
                    ),
                    valueEditor: CustomValueEditor,
                  }}
                />
              </div>
            </div>
            {filterApplied ? <Shadow /> : undefined}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              hideFilters={true}
              bottomOffset={20}
              totalItems={totalItems}
              dataCells={assets}
              headCells={assetsTableHead}
              loadPage={loadPage}
              handleSelect={onAssetSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              // onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={<ExportToExcel></ExportToExcel>}
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

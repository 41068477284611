import { useApolloClient } from '@apollo/client';
import { GET_ASSETS } from 'graphql/ams/assets';
import { assets } from 'graphql/ams/types/assets';
import { useCallback, useMemo, useState } from 'react';
import { useAssetsTableHook } from './assetsTableHook';

interface IAssetCheck {
  name: string;
}
export const useNewAssetValidation = ({ name }: IAssetCheck) => {
  const [validateAssetNameResult, setSameNameAsset] = useState<any | null | undefined>(undefined);
  const [similarAssets, setSimilarAssets] = useState<any[] | null | undefined>(undefined);

  const client = useApolloClient();

  const exactNameValidationFilter = useMemo(() => {
    return { filter: { name } };
  }, [name]);

  const similarNamesValidationFilter = useMemo(() => {
    return { filter: { nameContains: name } };
  }, [name]);

  const validateAssetNameTableDataSource = useAssetsTableHook({
    overridePageLoadParams: exactNameValidationFilter,
  });

  const similarAssetsTableDataSource = useAssetsTableHook({
    overridePageLoadParams: similarNamesValidationFilter,
  });

  const validateExactName = useCallback(async () => {
    const { data, error } = await client.query<assets>({
      query: GET_ASSETS,
      variables: exactNameValidationFilter,
      fetchPolicy: 'network-only',
    });

    if (!error && data.assets_assets.length) {
      const val = { ...data.assets_assets[0] };
      setSameNameAsset(val);
      return val;
    } else {
      setSameNameAsset(null);
      return null;
    }
  }, [client, exactNameValidationFilter]);

  const validateSimilarNames = useCallback(async () => {
    const { data, error } = await client.query<assets>({
      query: GET_ASSETS,
      variables: similarNamesValidationFilter,
      fetchPolicy: 'network-only',
    });

    if (!error && data.assets_assets.length) {
      const val = data.assets_assets.map((asset) => ({ ...asset }));
      setSimilarAssets(val);
      return val;
    } else {
      setSimilarAssets(null);
      return null;
    }
  }, [client, similarNamesValidationFilter]);

  const validateAssetName = useCallback(
    async (id?: string) => {
      const validateAssetNameResult = await validateExactName();
      const similarAssets = await validateSimilarNames();
      if (id) {
        return {
          validateAssetNameResult:
            validateAssetNameResult && validateAssetNameResult.id !== id
              ? validateAssetNameResult
              : undefined,
          similarAssets: similarAssets?.filter((asset) => asset.id !== id),
        };
      }
      return { validateAssetNameResult, similarAssets };
    },
    [validateExactName, validateSimilarNames]
  );

  return {
    validateAssetName,
    validateAssetNameResult,
    similarAssets,
    validateAssetNameTableDataSource,
    similarAssetsTableDataSource,
  };
};

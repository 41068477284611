import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetServiceProvidersCount: assetsByColumnsCount) =>
  dataAssetServiceProvidersCount.assets_assets_extensionCount;
const getItems = (dataAssetServiceProviders: assetsByColumns) =>
  dataAssetServiceProviders.assets_assets_extension?.map((serviceProvider) => ({
    id: serviceProvider.id,
    name: serviceProvider.serviceProvider,
  }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ serviceProviderContains: localSearch });
const distinctOn = [{ column: 'SERVICE_PROVIDER' }];
const gqlProps = { withServiceProvider: true };
const order = [{ column: 'SERVICE_PROVIDER', order: 'ASC' }];

export interface IAssetServiceProvidersAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetServiceProvidersAutocompleteDataHook = ({
  search,
}: IAssetServiceProvidersAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

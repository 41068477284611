import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { StickyHeaderTable } from 'components/ui/TableDnd/StickyHeaderTable';

import { Box, Button, Grid } from '@mui/material';
import { useAssetType } from 'hooks/assetTypeHook';
import { assetTypesTableHead } from 'constants/assetTypesTable';
import { AssetTypeRowActions } from './components/AssetTypeRowActions/AssetTypeRowActions';
import { EditAssetType } from './components/EditAssetType/EditAssetType';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useEditAssetTypeContext } from './components/EditAssetType/EditAssetTypeContext';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IAssetTypeTableProps {
  onSelect?: (item: any) => void;
  onClose: () => void;
  onRefresh: () => void;
}

export const AssetTypeTable: FC<IAssetTypeTableProps> = ({ onSelect, onClose, onRefresh }) => {
  const {
    types,
    loading,
    totalItems,
    totalRecords,
    deleteItem,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    moveItem,
    loadPage,
    onFilterChange,
    expandedTypes,
    parents,
    onExpandChange,
    hasPrevSibling,
    moveTreeItemRight,
    moveTreeItemLeft,
    hasParent,
  } = useAssetType();
  const [editRowId, setEditRowId] = useState<string | undefined>();
  const [editData, setEditData] = useState<any>();

  const {
    type: tempData,
    setType: setTempData,
    setProps: setEditAssetTypeContextProps,
    onSubmitValidate: onSubmitAssetValidate,
  } = useEditAssetTypeContext();

  const { checkAndProceed, formChanged, resetChanged } = useFormChangedDialogContext();

  useEffect(() => {
    setEditAssetTypeContextProps((props: any) => ({ ...props, totalItems: totalRecords }));
  }, [totalRecords, setEditAssetTypeContextProps]);

  useEffect(() => {
    setTempData({ ...editData });
  }, [editData, setTempData]);

  useEffect(() => {
    if (hasChanges) {
      formChanged && formChanged();
    } else {
      resetChanged && resetChanged();
    }
  }, [hasChanges, formChanged, resetChanged]);

  const gotoNewType = useCallback(() => {
    const newItem = addNew();
    setEditRowId(newItem.id);
    setEditData({ ...newItem });
  }, [addNew]);

  const onTypeSelect = useCallback(
    (id: string) => {
      const editRowFound = types.find((type) => type.id.toString() === id);
      if (editRowFound) {
        setEditRowId(id);
        setEditData({ ...editRowFound });
      }
      return true;
    },
    [types]
  );

  const filterOptions = useMemo(() => ({}), []);
  const filterValues = useMemo(() => ({}), []);

  const editDataRow = useMemo(() => {
    return {
      function: () => <EditAssetType></EditAssetType>,
    };
  }, []);

  const isEditRow = useCallback(
    (row: any) => {
      return row.id === editRowId;
    },
    [editRowId]
  );

  const transformDataCell = useMemo(() => {
    return {
      function: (data: any, row?: any, order?: SortOrder, sorted?: boolean) => (
        <AssetTypeRowActions
          onCancel={() => {
            const { isNew, changed } = editData;
            if (isNew && !changed) {
              deleteItem(editData.id);
            }
            setEditRowId(undefined);
          }}
          onSave={() => {
            if (onSubmitAssetValidate!()) {
              updateItem(tempData);
              setEditRowId(undefined);
            }
          }}
          onEdit={() => {
            const id = data?.row?.id.toString();
            const editRowFound = types.find((type) => type.id.toString() === id);
            if (editRowFound) {
              setEditRowId(data?.row?.id);
              setEditData({ ...editRowFound });
            }
          }}
          onSelect={
            !data?.row?.isNew && !data?.row?.changed
              ? () => {
                  const id = data?.row?.id.toString();
                  const selectedRowFound = types.find((type) => type.id.toString() === id);
                  if (selectedRowFound) {
                    checkAndProceed!(() => {
                      onSelect && onSelect(selectedRowFound);
                      onClose();
                    })();
                  }
                  return false;
                }
              : undefined
          }
          onDelete={
            data?.row?.isNew
              ? () => {
                  deleteItem(data?.row?.id);
                  setEditRowId(undefined);
                }
              : undefined
          }
          isEditMode={() => {
            return !!editRowId && data?.data?.id.toString() === editRowId;
          }}
        ></AssetTypeRowActions>
      ),
    };
  }, [
    editRowId,
    types,
    tempData,
    deleteItem,
    updateItem,
    editData,
    onSelect,
    onClose,
    checkAndProceed,
    onSubmitAssetValidate,
  ]);

  const handleSubmit = useCallback(async () => {
    onSubmit && (await onSubmit());
    onRefresh && onRefresh();
  }, [onSubmit, onRefresh]);

  const handleClose = useCallback(async () => {
    onClose && onClose();
  }, [onClose]);

  const onDragEnd = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      moveItem(sourceIndex, destinationIndex);
    },
    [moveItem]
  );

  const isExpanded = useCallback(
    (row: any) => {
      if (!parents[row.id]) {
        return null;
      }
      return !!expandedTypes[row.id];
    },
    [expandedTypes, parents]
  );

  const showArrowRight = useCallback(
    (row: any) => {
      return hasPrevSibling(row.id);
    },
    [hasPrevSibling]
  );

  const showArrowLeft = useCallback(
    (row: any) => {
      return hasParent(row.id);
    },
    [hasParent]
  );

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: '16px', marginTop: '16px' }} order={{ xs: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={gotoNewType}
                disabled={!!editRowId}
              >
                Add New Type
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              bottomOffset={130}
              totalItems={totalItems}
              totalRecords={totalRecords}
              dataCells={types}
              headCells={assetTypesTableHead}
              loadPage={loadPage}
              handleSelect={onTypeSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              hideFilters
              actionsDataCell={transformDataCell}
              editDataRow={editDataRow}
              isEditRow={isEditRow}
              draggable
              disableDrag={!!editRowId}
              onDragEnd={onDragEnd}
              isExpanded={isExpanded}
              onExpandChange={onExpandChange}
              showArrowRight={showArrowRight}
              onArrowRight={moveTreeItemRight}
              showArrowLeft={showArrowLeft}
              onArrowLeft={moveTreeItemLeft}
            />
          </Box>
        </Grid>
      </Grid>
      <Box m={5} />
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={debounce(handleSubmit, DEBOUNCE_TIMEOUT)}
                disabled={!hasChanges || !!editRowId}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ConfirmationButton
                action={handleClose}
                color="secondary"
                size="medium"
                text="Cancel"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </LoadingOverlay>
  );
};

import { useUI } from 'contexts/UiContext';
import { useMaintenanceMessage } from 'hooks/maintenanceMessage';
import { FC, useEffect } from 'react';

export const MaintenanceAlert: FC = () => {
  const { maintenanceText } = useMaintenanceMessage();
  const { addSnackbar } = useUI();

  useEffect(() => {
    if (maintenanceText) {
      addSnackbar!({
        text: maintenanceText,
        severity: 'warning',
      });
    }
  }, [addSnackbar, maintenanceText]);

  return <></>;
};

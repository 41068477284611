import { assetAssignmentUpdateVariables } from 'graphql/ams/types/assetAssignmentUpdate';
import { IAssetAssignment } from 'graphql/ams/types/IAssetAssignment';
import { omit, pick } from 'lodash';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import validate from 'validate.js';
import { validators } from 'constants/validators';
import { tryUpdateProcedure } from 'utils/apollo';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  ASSET_ASSIGNMENT_CREATE_MUTATION,
  ASSET_ASSIGNMENT_UPDATE_MUTATION,
} from 'graphql/ams/assetAssignees';
import { useUI } from 'contexts/UiContext';
import { getUpdates } from 'utils/extract';
import { AssetAssignmentType } from 'graphql/ams/types/graphql-types';

export interface IContextState {
  assignment: IAssignementData;
  isNew: boolean;
}

export interface IContextActions {
  setAssignment: any;
  initAssetAssignment: any;
  setAssetIsLoaner: any;
  onSubmitValidate: any;
  onSubmit: (assetId?: string) => Promise<boolean>;
}

const initialState: IContextState = {
  assignment: {
    assignmentType: AssetAssignmentType.PERMANENT,
    showValidator: false,
    isValid: false,
    isChanged: false,
    errors: [],
  },
  isNew: true,
};

const AssetAssignmentContext = createContext<IContextState & Partial<IContextActions>>(
  initialState
);

interface IAssetAssignmentProviderProps {
  children: any;
  assetId?: string;
  loadedAssagnee?: IAssetAssignment;
  isLoaner?: boolean;
}

export interface ISelectedUser {
  id: string;
  name: string;
  email: string | null;
}

export interface ISelectedRoom {
  id: string;
  name: string;
}

export interface IAssignementData extends Omit<assetAssignmentUpdateVariables, 'id'> {
  id?: string;
  selectedUser?: ISelectedUser;
  selectedRoom?: ISelectedRoom;
  contractorName?: string | null;
  contractorEmail?: string | null;
  showValidator: boolean;
  isValid: boolean;
  isChanged: boolean;
  errors: any;
}

const aliases: any = {
  selectedUser: 'Current Assignee',
};

const validatorOptions = {
  prettify: function prettify(string: string) {
    return aliases[string] || validate.prettify(string);
  },
};

export interface ILoadedIAssetAssignment extends IAssetAssignment {
  isChanged?: boolean;
}

export const AssetAssignmentProvider: FC<IAssetAssignmentProviderProps> = ({
  children,
  loadedAssagnee,
  isLoaner,
}) => {
  const { addSnackbar } = useUI();
  const client = useApolloClient();

  const [assetIsLoaner, setAssetIsLoaner] = useState(!!isLoaner);

  useEffect(() => {
    setAssetIsLoaner(!!isLoaner);
  }, [isLoaner]);

  const [assignment, setAssignment] = useState<IAssignementData>({
    ...initialState.assignment,
  });

  const [loadedAssignment, setLoadedAssignment] = useState<ILoadedIAssetAssignment | undefined>();

  const isFromArchiveCheck = useCallback(
    (assignment?: { endDate?: Date }) =>
      !!(assignment?.endDate && new Date(assignment.endDate) <= new Date()),
    []
  );

  const isLoadedFromArchive = useMemo(
    () => isFromArchiveCheck(loadedAssignment),
    [isFromArchiveCheck, loadedAssignment]
  );

  const readyToArchive = useMemo(
    () => isFromArchiveCheck(assignment),
    [assignment, isFromArchiveCheck]
  );

  const calculateAssignmentType = useMemo(() => {
    if (!readyToArchive || !isLoadedFromArchive) {
      return assetIsLoaner ? AssetAssignmentType.TEMPORARY : AssetAssignmentType.PERMANENT;
    } else {
      return loadedAssignment!.assignmentType;
    }
  }, [assetIsLoaner, isLoadedFromArchive, loadedAssignment, readyToArchive]);

  useEffect(() => {
    if (assignment && assignment.assignmentType !== calculateAssignmentType) {
      setAssignment((old) => ({ ...old, assignmentType: calculateAssignmentType }));
    }
  }, [assignment, calculateAssignmentType]);

  const [createAssignmentMutation] = useMutation(ASSET_ASSIGNMENT_CREATE_MUTATION);
  const [updateAssignmentMutation] = useMutation(ASSET_ASSIGNMENT_UPDATE_MUTATION);

  const { formChanged } = useFormChangedDialogContext();

  useEffect(() => {
    setLoadedAssignment(loadedAssagnee);
  }, [loadedAssagnee]);

  useEffect(() => {
    if (
      loadedAssignment &&
      (loadedAssignment.user || loadedAssignment.room || loadedAssignment.contractorName)
    ) {
      const { user, room, contractorName, contractorEmail, isChanged } = loadedAssignment;
      const newAssignment: IAssignementData = {
        ...omit(loadedAssignment, ['user', 'room', '__typename']),
        selectedUser: user ? { id: user.id, name: user.name, email: user.email } : undefined,
        selectedRoom: room ? { id: room.id, name: room.name } : undefined,
        contractorName,
        contractorEmail,
        showValidator: false,
        isValid: true,
        isChanged: !!isChanged,
        errors: {},
      };
      setAssignment((old) => ({ ...old, ...newAssignment }));
    } else {
      setAssignment({ ...initialState.assignment });
    }
  }, [loadedAssignment]);

  const assignmentValidators = useMemo(
    () => ({
      // selectedUser: validators.required,
      assignmentType: validators.required,
    }),
    []
  );

  const validateForm = useCallback(
    (newState: any) => {
      const { startDate, endDate, assignmentType } = newState;
      let errors = validate(newState, assignmentValidators, validatorOptions);
      if (assignmentType === AssetAssignmentType.TEMPORARY) {
        if (!startDate) {
          errors = { ...errors, startDate: ['Start Date is mandatory'] };
        }
        if (!endDate) {
          errors = { ...errors, endDate: ['End Date is mandatory'] };
        }
      }
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [assignmentValidators]
  );

  const initAssetAssignment = useCallback(
    (cb?: (oldState: IAssetAssignment | undefined) => IAssetAssignment) => {
      if (cb) {
        setLoadedAssignment((oldState) => {
          return cb(oldState);
        });
      } else {
        setLoadedAssignment(undefined);
      }
    },
    [setLoadedAssignment]
  );

  const onSetAssetAssignment = useCallback(
    (cb: (oldState: IAssignementData) => IAssignementData) => {
      formChanged && formChanged();
      setAssignment((oldState) => validateForm({ ...cb(oldState), isChanged: true }));
    },
    [setAssignment, formChanged, validateForm]
  );

  const isNew = useMemo(() => {
    return !assignment?.id;
  }, [assignment]);

  const onSubmitValidate = useCallback(() => {
    const { isValid, assignmentType, startDate, endDate } = assignment;
    if (
      !isValid ||
      (assignmentType === AssetAssignmentType.TEMPORARY && (!startDate || !endDate))
    ) {
      setAssignment(validateForm({ ...assignment, showValidator: true }));
      return false;
    }
    return true;
  }, [assignment, validateForm]);

  const compareAssignmentFunctions = useMemo(
    () => ({
      userId: (id: string) => loadedAssignment?.user?.id === id,
      roomId: (id: string) => loadedAssignment?.room?.id === id,
    }),
    [loadedAssignment]
  );

  const onSubmit = useCallback(
    async (assetId?: string) => {
      if (!onSubmitValidate()) {
        return false;
      }

      let isErrorFound = false;
      if (!isNew) {
        const prepareData = {
          ...pick(assignment, ['id', 'assignmentType', 'startDate', 'endDate', 'isReturned']),
        };

        const { saveData, updateFound } = getUpdates({
          source: loadedAssignment,
          updates: prepareData,
          baseOmitKeys: [],
          requiredKeys: ['id', 'assignmentType'],
          compareFunctions: compareAssignmentFunctions,
        });

        if (updateFound) {
          const { errors, isError } = await tryUpdateProcedure({
            mutation: () =>
              updateAssignmentMutation({
                variables: saveData,
              }),
            parseResult: (data: any) => data?.assets_assetAssignmentCreate?.id,
          });

          isErrorFound = isError;

          if (isError) {
            console.log('Errors', errors);

            addSnackbar!({
              text: errors?.join(' ') || 'Error',
              severity: 'error',
            });
          }
        }

        if (!isErrorFound) {
          addSnackbar!({
            text: 'Asset Assignment is saved',
            severity: 'success',
          });
          await client.resetStore();
        }

        return !isErrorFound;
      } else {
        if (!assetId) {
          return false;
        }
        const saveData = {
          userId: assignment.selectedUser?.id,
          roomId: assignment.selectedRoom?.id,
          ...pick(assignment, ['assignmentType', 'startDate', 'endDate', 'isReturned']),
          assetId,
        };

        const { errors, isError } = await tryUpdateProcedure({
          mutation: () =>
            createAssignmentMutation({
              variables: saveData,
            }),
          parseResult: (data: any) => data?.assets_assetAssignmentCreate?.id,
        });

        if (isError) {
          console.log('Errors', errors);

          addSnackbar!({
            text: errors?.join(' ') || 'Error',
            severity: 'error',
          });
        }

        if (!isError) {
          addSnackbar!({
            text: 'IT Asset Assignment is created',
            severity: 'success',
          });
          await client.resetStore();
        }

        return !isError;
      }
    },
    [
      addSnackbar,
      assignment,
      client,
      createAssignmentMutation,
      updateAssignmentMutation,
      isNew,
      onSubmitValidate,
      compareAssignmentFunctions,
      loadedAssignment,
    ]
  );

  return (
    <AssetAssignmentContext.Provider
      value={{
        assignment,
        setAssignment: onSetAssetAssignment,
        initAssetAssignment,
        setAssetIsLoaner,
        isNew,
        onSubmitValidate,
        onSubmit,
      }}
    >
      {children}
    </AssetAssignmentContext.Provider>
  );
};

export const useAssetAssignmentContext = () => useContext(AssetAssignmentContext);

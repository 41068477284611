import { DatePicker } from '@mui/lab';
import {
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { SelectUser } from 'components/SelectUser/SelectUser';
import { assignedTypeOptions } from 'constants/selectOptions';
import { omit } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { useAssetAssignmentContext } from './AssetAssignmentContext';
import { Asterisks } from 'components/Asterisks/Asterisks';
import { AssetAssignmentType } from 'graphql/ams/types/graphql-types';

export interface IAssetAssignment {
  isLoaner?: boolean | null;
}

export const AssetAssignment: FC<IAssetAssignment> = ({ isLoaner }) => {
  const { assignment, setAssignment, isNew, setAssetIsLoaner } = useAssetAssignmentContext();
  const { startDate, endDate, showValidator, errors, selectedUser, assignmentType, isReturned } =
    assignment;

  useEffect(() => setAssetIsLoaner(isLoaner), [isLoaner, setAssetIsLoaner]);

  const selectedAssignmentType = useMemo(() => {
    return assignedTypeOptions.find((option) => option.id === assignmentType);
  }, [assignmentType]);

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      {isNew ? (
        <Grid item xs={12}>
          <SelectUser
            onSelectChange={(user) => {
              setAssignment((assignment: any) => ({
                ...assignment,
                selectedUser: user ? omit(user, '__typename') : null,
              }));
            }}
            selectedItem={selectedUser}
            error={showValidator && errors?.selectedUser}
          ></SelectUser>
        </Grid>
      ) : undefined}
      {!isNew ? (
        <>
          <Grid item xs={4} md={4} xl={2}>
            <Typography variant="body2" className="label-title">
              Assigned To:
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} xl={4}>
            {selectedUser?.name}{' '}
            {selectedUser?.email ? (
              <>
                {'('}
                <Link href={`mailto:${selectedUser?.name} <${selectedUser.email}>`}>
                  {selectedUser.email}
                </Link>
                {')'}
              </>
            ) : (
              ''
            )}
          </Grid>
        </>
      ) : undefined}
      <Grid item xs={12} md={4} xl={3}>
        <Typography variant="body2" className="label-title">
          Assignment Type:
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={3}>
        {selectedAssignmentType?.name}
      </Grid>
      <Grid item xs={12} md={4} xl={2}>
        <Typography variant="body2" className="label-title">
          Start Date
          {assignmentType === AssetAssignmentType.TEMPORARY ? (
            <Asterisks count={1} tooltipTitle="Start Date is mandatory when Loaner is selected" />
          ) : undefined}
          :
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={4}>
        <div style={{ maxWidth: '150px' }}>
          <DatePicker
            value={startDate || null}
            onChange={(date: Date | null) => {
              setAssignment!((assignment: any) => {
                return { ...assignment, startDate: date };
              });
            }}
            clearable
            renderInput={(props) => (
              <TextField {...props} error={props.error || !!(showValidator && errors?.startDate)} />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <Typography variant="body2" className="label-title">
          End Date
          {assignmentType === AssetAssignmentType.TEMPORARY ? (
            <Asterisks count={1} tooltipTitle="End Date is mandatory when Loaner is selected" />
          ) : undefined}
          :
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={3}>
        <div style={{ maxWidth: '150px' }}>
          <DatePicker
            value={endDate || null}
            onChange={(date: Date | null) => {
              setAssignment!((assignment: any) => {
                return { ...assignment, endDate: date };
              });
            }}
            clearable
            renderInput={(props) => (
              <TextField {...props} error={props.error || !!(showValidator && errors?.endDate)} />
            )}
          />
        </div>
      </Grid>
      {assignmentType === AssetAssignmentType.TEMPORARY ? (
        <>
          <Grid item xs={12} md={4} xl={2}>
            <Typography variant="body2" className="label-title">
              Returned:
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} xl={4}>
            <RadioGroup
              row
              aria-label="isOriginParty"
              name="position"
              value={isReturned ? '1' : '0'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAssignment!((assignment: any) => {
                  return {
                    ...assignment,
                    isReturned: e.target.value === '1',
                  };
                });
              }}
            >
              <FormControlLabel value="1" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="0" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </Grid>
        </>
      ) : undefined}
    </Grid>
  );
};

import { FC } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { AssetProvider } from 'contexts/asset/AssetContext';
import { ViewFrame } from 'template/ViewFrame/ViewFrame';
import { AssetHeader } from 'template/AssetHeader/AssetHeader';
import { AssetTabs } from 'template/AssetTabs/AssetTabs';
import { assetTabs } from 'constants/tabs/assetTabs';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { useAssetManager } from 'hooks/assetManagerHook';
import { AssetAssignmentProvider } from 'template/AssetAssignment/AssetAssignmentContext';

interface IUrlParams {
  id: string;
  tab: string;
}

export interface IProps {
  routes: Array<any>;
}

export const AssetPage: FC<IProps> = ({ routes }) => {
  const { id, tab } = useParams<IUrlParams>();
  const assetManager = useAssetManager({ assetId: id });
  return (
    <AssetAssignmentProvider>
      <AssetProvider assetManager={assetManager}>
        <ViewFrame
          header={<AssetHeader></AssetHeader>}
          tabs={
            <AssetTabs
              currentView={tab}
              tabs={assetTabs.map((item) => ({
                ...item,
                path: item.path.replace(':id', id).replace(':tab', tab),
              }))}
            ></AssetTabs>
          }
        >
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </ViewFrame>
      </AssetProvider>
    </AssetAssignmentProvider>
  );
};

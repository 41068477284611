import { Box, Button, Grid } from '@mui/material';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { Table } from 'components/ui/Table/Table';
import { assignmentsTableHead } from 'constants/assignmentsTable';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { SortOrder } from 'hooks/tableDataSourceHook';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { AssetAssignment } from 'template/AssetAssignment/AssetAssignment';
import { useAssetAssignmentContext } from 'template/AssetAssignment/AssetAssignmentContext';
import { AssetRoomAssignment } from 'template/AssetAssignment/AssetRoomAssignment';
import { useAssetAssigneesContext } from './AssetAssigneesContext';
import { AssetContractorAssignment } from 'template/AssetAssignment/AssetContractorAssignment';

export interface IAssetAssignees {
  assetId?: string;
  refetch?: any;
  disabled: boolean;
  isLoaner: boolean;
}

export const AssetAssignees: FC<IAssetAssignees> = ({ assetId, refetch, disabled, isLoaner }) => {
  const [openEdit, setOpenEdit] = useState(false);

  const {
    assignment,
    initAssetAssignment,
    isNew: isNewAssetAssignment,
    onSubmitValidate: onSubmitAssignmentValidate,
    onSubmit: onSubmitAssignment,
  } = useAssetAssignmentContext();

  const { assignments, totalItems, loadPage, onFilterChange, pageLoadParams } =
    useAssetAssigneesContext();

  const onAssetAssignmentSelect = useCallback(
    (id: string) => {
      const selectedItem = assignments.find((assignment) => assignment.id === id);
      if (!selectedItem) {
        return false;
      }

      initAssetAssignment(() => selectedItem);

      setOpenEdit(true);
      return true;
    },
    [assignments, initAssetAssignment]
  );

  const filterOptions = useMemo(() => ({}), []);
  const filterValues = useMemo(() => ({}), []);
  const printView = useMemo(() => false, []);

  return (
    <>
      <DialogForm
        open={openEdit}
        dialogTitle={'Asset Assignment'}
        onClose={() => {
          setOpenEdit(false);
        }}
        maxWidth={'md'}
      >
        <div style={{ padding: '16px 40px 40px 40px' }}>
          {assignment.selectedUser ? (
            <AssetAssignment isLoaner={isLoaner}></AssetAssignment>
          ) : undefined}
          {assignment.selectedRoom ? <AssetRoomAssignment></AssetRoomAssignment> : undefined}
          {assignment.contractorName ? (
            <AssetContractorAssignment isLoaner={isLoaner}></AssetContractorAssignment>
          ) : undefined}
          <Box m={5} />
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(() => {
                  setOpenEdit(false);
                }, DEBOUNCE_TIMEOUT)}
              >
                Cancel
              </Button>
            </Grid>
            {!isNewAssetAssignment || assetId ? (
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (onSubmitAssignmentValidate()) {
                      if (onSubmitAssignment && (await onSubmitAssignment(assetId))) {
                        setOpenEdit(false);
                        refetch && refetch();
                      }
                    }
                  }}
                  disabled={disabled}
                >
                  Submit
                </Button>
              </Grid>
            ) : undefined}
          </Grid>
        </div>
      </DialogForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <Table
              totalItems={totalItems}
              dataCells={assignments}
              headCells={assignmentsTableHead}
              loadPage={loadPage}
              handleSelect={onAssetAssignmentSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
            ></Table>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

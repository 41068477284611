import { useMemo, useState, useCallback, useEffect } from 'react';
import { assetsTableHead, mappedAssetsTableHead } from 'constants/assetsTable';
import {
  assignedTypeFilterOptions,
  dollarValueRangeOptions,
  isCurrentlyAssignedFilterOptions,
} from 'constants/selectOptions';

import { RuleGroupType } from 'react-querybuilder';

import { useAssetLocationAll } from './assetLocationAllHook';
import { useAssetStatusAll } from './assetStatusAllHook';
import { useAssetTypesTree } from './assetTypesTreeHook';
import { keyBy, mapValues } from 'lodash';
import { dateVariableTypes } from './assetsTableHook';

const initQuery = {
  combinator: 'and',
  rules: [],
};

export const useAssetsQueryBuilderConfig = () => {
  const { types: assetTypes } = useAssetTypesTree({ expandAll: true });
  const { assetStatuses } = useAssetStatusAll();
  const { assetLocations } = useAssetLocationAll();

  const [queryState, setQueryState] = useState<string | undefined>(
    sessionStorage.getItem('QueryBuilder') || JSON.stringify(initQuery)
  );

  useEffect(() => {
    if (queryState) {
      sessionStorage.setItem('QueryBuilder', queryState);
    } else {
      if (sessionStorage.getItem('QueryBuilder')) {
        sessionStorage.removeItem('QueryBuilder');
      }
    }
  }, [queryState]);

  const query = useMemo(() => {
    if (queryState) {
      return JSON.parse(queryState);
    } else {
      return undefined;
    }
  }, [queryState]);

  const setQuery = useCallback((query: RuleGroupType | undefined) => {
    setQueryState(query ? JSON.stringify(query) : undefined);
  }, []);

  const filterOptions: any = useMemo(() => {
    return {
      isCurrentlyAssigned: [{ id: 'All', name: 'All' }, ...isCurrentlyAssignedFilterOptions],
      isLoaner: [{ id: 'All', name: 'All' }, ...assignedTypeFilterOptions],
      dollarValue: [{ id: 'All', name: 'All' }, ...dollarValueRangeOptions],
      type: [...assetTypes],
      status: [...assetStatuses],
      location: [...assetLocations],
    };
  }, [assetTypes, assetStatuses, assetLocations]);

  const config = useMemo(() => {
    return {
      fields: [
        ...assetsTableHead
          .filter(({ label }) => !!label)
          .sort(({ label: a }, { label: b }) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
          .map(({ id, label, filter }) => ({
            name: id,
            label,
            inputType: filter === 'date' ? 'date' : undefined,
            operators:
              filter === 'date'
                ? [
                    { name: '=', label: 'on' },
                    { name: '<', label: 'before' },
                    { name: '>', label: 'after' },
                    { name: 'between', label: 'between' },
                    // { name: 'past', label: 'is in the past' },
                    // { name: 'future', label: 'is in the future' },
                    { name: 'younger', label: 'less than' },
                    { name: 'older', label: 'greater than' },
                    // { name: 'sooner', label: 'today or in the period' },
                    // { name: 'later', label: 'after the period' },
                  ]
                : filter === 'dropdown'
                ? [{ name: '=', label: '=' }]
                : filter === 'hierarchy-multiple'
                ? [{ name: '=', label: '=' }]
                : [{ name: 'contains', label: 'contains' }],

            dataType: filter,
            filterOptions: filterOptions[id] || undefined,
          })),
      ],
    };
  }, [filterOptions]);

  const filterValues = useMemo(() => {
    const values = mapValues(keyBy(query.rules, 'field'), (item: any) => {
      let mappedValue;
      if (mappedAssetsTableHead[item.field].filter === 'date') {
        switch (item.operator) {
          case '=':
            mappedValue = item.value
              ? { type: dateVariableTypes.indexOf('date'), date: item.value }
              : undefined;
            break;
          case '<':
            mappedValue = item.value
              ? { type: dateVariableTypes.indexOf('to'), date: item.value }
              : undefined;
            break;
          case '>':
            mappedValue = item.value
              ? { type: dateVariableTypes.indexOf('from'), date: item.value }
              : undefined;
            break;
          case 'between':
            if (!(typeof item.value === 'string' || item.value instanceof String)) {
              mappedValue = undefined;
              break;
            }

            const dates = (item.value as string).split(',');
            mappedValue = item.value
              ? {
                  type: dateVariableTypes.indexOf('between'),
                  date: dates[0] || undefined,
                  dateTo: (dates.length > 1 && dates[1]) || undefined,
                }
              : undefined;
            break;
          case 'past':
            mappedValue = { type: dateVariableTypes.indexOf('past') };
            break;
          case 'future':
            mappedValue = { type: dateVariableTypes.indexOf('future') };
            break;
          case 'older':
          case 'younger':
          case 'sooner':
          case 'later':
            mappedValue = item.value
              ? { type: dateVariableTypes.indexOf(item.operator), ...item.value }
              : undefined;
            break;

          default:
            mappedValue = item.value;
        }
      } else {
        mappedValue = item.value;
      }
      return mappedValue;
    });

    return values;
  }, [query]);

  const clearAllFilters = useCallback(() => {
    setQueryState(JSON.stringify(initQuery));
  }, []);

  const filterApplied = useMemo(() => {
    return !!query?.rules?.length;
  }, [query?.rules?.length]);

  const usedOptions = useMemo(
    () =>
      query?.rules?.reduce(
        (acc: any, rule: any) => ({
          ...acc,
          [rule.field]: acc[rule.field]
            ? {
                count: (acc[rule.field].count ?? 0) + 1,
                ids: [...acc[rule.field].ids, rule.id],
              }
            : { count: 1, ids: [rule.id] },
        }),

        // return ({ ...acc, [rule.field]: (acc[rule.field] ?? 0) + 1 })},
        {}
      ),
    [query]
  );

  const validationResult = useMemo(
    () =>
      Object.keys(usedOptions).reduce((acc: any, optionKey: string) => {
        return {
          ...acc,
          ...usedOptions[optionKey].ids.reduce(
            (optionAcc: any, id: string) => ({
              ...optionAcc,
              [id]: { valid: usedOptions[optionKey].count < 2 },
            }),
            {}
          ),
        };
      }, {}),
    [usedOptions]
  );

  const validator = useCallback(() => validationResult, [validationResult]);

  return {
    config,
    query,
    setQuery,
    filterValues,
    controlClassnames: { queryBuilder: 'queryBuilder-branches' },
    clearAllFilters,
    filterApplied,
    validator,
  };
};

import { FC, useEffect, useRef } from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';

export interface IDateRange {
  years: number;
  months: number;
  days: number;
}

export interface IDateRangeProps {
  value: IDateRange;
  onChange: (value: { [id: string]: any }) => void;
  title: string;
}

export const DateRange: FC<IDateRangeProps> = ({ value, onChange, title }) => {
  const { years, days, months } = value;
  const handleChange = (field: string) => (event: any) => {
    onChange({ [field]: event.target.value });
  };

  const onChangeRef = useRef<any>(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    onChangeRef.current({ years, days, months });
  }, [years, days, months]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          backgroundColor: 'white',
          padding: '2px 5px',
          border: '1px solid gray',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputLabel id="select-years">Years</InputLabel>
          <Select
            labelId="select-years"
            id="select-years"
            value={years || 0}
            label="Years"
            onChange={handleChange('years')}
            style={{ margin: '5px' }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((no) => (
              <MenuItem value={no} key={no}>
                {no}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputLabel id="select-months">Months</InputLabel>
          <Select
            labelId="select-months"
            id="select-months"
            value={months || 0}
            label="Months"
            onChange={handleChange('months')}
            style={{ margin: '5px' }}
          >
            {Array.from({ length: 12 }, (_, i) => i - 1).map((no) => (
              <MenuItem value={no} key={no}>
                {no}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputLabel id="select-days">Days</InputLabel>
          <Select
            labelId="select-days"
            id="select-days"
            value={days || 0}
            label="Days"
            onChange={handleChange('days')}
            style={{ margin: '5px' }}
          >
            {Array.from({ length: 31 }, (_, i) => i).map((no) => (
              <MenuItem value={no} key={no}>
                {no}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
      </div>
    </>
  );
};

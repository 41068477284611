import { useLazyQuery } from '@apollo/client';
import { GET_ASSET_TYPE_NAMES } from 'graphql/ams/assetTypes';
import { assetTypeNames, assetTypeNames_assets_assetTypes } from 'graphql/ams/types/assetTypeNames';
import { useCallback, useEffect, useMemo } from 'react';

export interface IUseSelectedTypeDataState {
  items: assetTypeNames_assets_assetTypes[];
  loading: boolean;
  error?: any;
}

interface IUseSelectedTypeDataProps {
  IDs?: string[];
}

export const useSelectedAssetTypesDataItems = ({
  IDs,
}: IUseSelectedTypeDataProps): IUseSelectedTypeDataState => {
  const [loadTypes, { data, loading, refetch, called, error }] = useLazyQuery<assetTypeNames>(
    GET_ASSET_TYPE_NAMES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadTypes({ variables });
      }
    },
    [loadTypes, refetch, called]
  );

  useEffect(() => {
    if (IDs?.length) {
      loadPage(IDs);
    }
  }, [IDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.assets_assetTypes) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items, loading, error };
};

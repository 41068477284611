import EasyAutoComplete from 'components/EasyAutoComplete';
import { GET_ASSET_LOCATIONS } from 'graphql/ams/assetLocations';
import { assetLocations } from 'graphql/ams/types/assetLocations';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';

import { FC, useMemo } from 'react';

export interface IAssetLocationData {
  id: string;
  name: string;
}

export interface ISelectAssetLocationProps {
  status?: IAssetLocationData;
  onSelectChange: (value: IAssetLocationData | undefined) => void;
  [id: string]: any;
}

export const SelectAssetLocation: FC<ISelectAssetLocationProps> = ({
  status,
  onSelectChange,
  ...easyAutoCompleteProps
}) => {
  const statusesQuery = useMemo(() => {
    return {
      GET_QUERY: GET_ASSET_LOCATIONS,
      getItemId: (item: any) => item.id,
      getItems: (data: assetLocations) =>
        [...data.assets_assetLocations]
          .filter((item) => item.isSelectable)
          .sort((a, b) => a.order - b.order)
          .map(({ id, name }) => ({ id, key: name })),
    };
  }, []);

  const statusesProps = useAutoCompleteSimpleDataSource<any, any, any>(statusesQuery);

  const updateAssetLocation =
    (getValue: (props: any) => IAssetLocationData | undefined) => (props: any) => {
      const value = getValue(props);
      onSelectChange(value);
    };

  const selectedItem = useMemo(() => {
    if (!status) {
      return null;
    } else {
      const { id, name } = status;
      return { id: id, key: name };
    }
  }, [status]);

  return (
    <EasyAutoComplete
      disableClearable={false}
      selected={selectedItem}
      key={`stockExchanges`}
      label=""
      optionsLabel="key"
      textFieldStyle="outlined"
      {...statusesProps}
      selectedChanged={updateAssetLocation((value: any) =>
        value ? { id: value.id, name: value.key } : undefined
      )}
      getSelectedLabel={(option: any) => {
        return option.key;
      }}
      {...easyAutoCompleteProps}
    />
  );
};

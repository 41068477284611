import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import {
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  SettingsBackupRestore as UncancelIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { useUI } from 'contexts/UiContext';
import {
  ASSET_CANCEL_MUTATION,
  ASSET_DELETE_MUTATION,
  ASSET_UNCANCEL_MUTATION,
} from 'graphql/ams/assets';
import { debounce } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: red[400] + ' !important',
    },
  },
  hoverInfo: {
    '&:hover': {
      color: (theme.palette.primary?.main || blue[600]) + ' !important',
    },
  },
}));

export interface IAssetActionsProps {
  isCanceled: boolean;
  id: string;
}

export const AssetActions: FC<IAssetActionsProps> = ({ isCanceled, id }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { addSnackbar } = useUI();
  const client = useApolloClient();

  const canCancel = !isCanceled;
  const canDelete = !!isCanceled;
  const canUncancel = !!isCanceled;

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);
  const [displayUncancelWarning, showUncancelWarning] = useState(false);

  const [deleteAssetMutation] = useMutation(ASSET_DELETE_MUTATION);
  const [cancelAssetMutation] = useMutation(ASSET_CANCEL_MUTATION);
  const [uncancelAssetMutation] = useMutation(ASSET_UNCANCEL_MUTATION);

  const AssetDelete = useCallback(async () => {
    let success = false;
    const variables = { id };
    try {
      const { data } = await deleteAssetMutation({
        variables,
      });
      if (data?.assets_assetDelete) {
        addSnackbar!({ text: 'Asset is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteAssetMutation, id, client]);

  const AssetCancel = useCallback(async () => {
    let success = false;
    const variables = { id };
    try {
      const { data } = await cancelAssetMutation({
        variables,
      });
      if (data?.assets_assetCancel) {
        addSnackbar!({ text: 'Asset is canceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, cancelAssetMutation, id, client]);

  const AssetUncancel = useCallback(async () => {
    let success = false;
    const variables = { id };
    try {
      const { data } = await uncancelAssetMutation({
        variables,
      });
      if (data?.assets_assetUncancel) {
        addSnackbar!({ text: 'Asset is uncanceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, uncancelAssetMutation, id, client]);

  const handleCancel = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        if (await AssetCancel!()) {
          await client.resetStore();
        }
      }
      showCancelWarning(() => false);
    },
    [AssetCancel, client]
  );

  const handleUncancel = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        if (await AssetUncancel!()) {
          await client.resetStore();
        }
      }
      showUncancelWarning(() => false);
    },
    [AssetUncancel, client]
  );

  const handleDelete = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        if (await AssetDelete!()) {
          await client.resetStore();
        }
      }
      showDeleteWarning(() => false);
    },
    [AssetDelete, client]
  );

  return (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        e.cancelBubble = true;
        e.stopPropagation();
      }}
      style={{ display: 'flex' }}
    >
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Asset deletion"
        message={'Selected Asset will be deleted! The action is irreversible!'}
        onClose={handleDelete}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Asset cancelation"
        message={'Selected Asset will be canceled!'}
        onClose={handleCancel}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayUncancelWarning}
        title="Please confirm Asset uncancel"
        message={'Selected Asset will be uncanceled!'}
        onClose={handleUncancel}
      />
      {canCancel ? (
        <div>
          <Tooltip title="Cancel Asset">
            <IconButton
              size="small"
              style={{ color: grey[500] }}
              className={classes.hoverWarning}
              onClick={debounce((e: any) => {
                showCancelWarning(true);
              }, DEBOUNCE_TIMEOUT)}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ) : undefined}
      {canUncancel ? (
        <div>
          <Tooltip title="Uncancel Asset">
            <IconButton
              size="small"
              style={{ color: grey[500] }}
              className={classes.hoverInfo}
              onClick={debounce((e: any) => {
                showUncancelWarning(true);
              }, DEBOUNCE_TIMEOUT)}
            >
              <UncancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ) : undefined}
      {canDelete ? (
        <div>
          <Tooltip title="Delete Asset">
            <IconButton
              size="small"
              style={{ color: grey[500] }}
              className={classes.hoverWarning}
              onClick={debounce((e: any) => {
                showDeleteWarning(true);
              }, DEBOUNCE_TIMEOUT)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ) : undefined}
    </div>
  );
};

import { AssetEventType } from 'constants/enums';

export const assetStatusColor = (val: Record<string, any>): string => {
  switch (val.eventType) {
    case AssetEventType.ASSET_CREATED:
      return 'black';

    default:
      return 'black';
  }
};

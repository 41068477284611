import React, { FC, useCallback } from 'react';
import { uniqueId, startCase, toLower } from 'lodash';

import { EventCommentLogProps } from './interfaces';

import { AssetEventType } from 'constants/enums';
import { UserInitials } from 'components';
import { assetStatusColor } from 'utils/asset';

import { formatTime } from 'utils/time';

import s from './style.module.scss';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { usePayloadConvert } from './hook/payloadConvertHook';
import { usePayloadConvert as useAssetAssignmentPayloadConvert } from 'template/AssetAssignment/EventsLog/hook/payloadConvertHook';
import { getAsset_assets_asset_events } from 'graphql/ams/types/getAsset';

export interface IEvent extends Omit<getAsset_assets_asset_events, '__typename'> {}

const changeReportStatusName = (status: string): string => {
  return status
    .replace(/Archived/gi, 'Retired')
    .replace(/Archive/gi, 'Retire')
    .replace(/Report/gi, 'Asset')
    .replace(/Party/gi, 'Other Party')
    .replace(/Asset Docusign Envelope/gi, 'DocuSign');
};

export const getVersion = (data: any): string =>
  data?.version ? ` v1.${data?.version?.version}` : 'v0.0';

export const EventCommentLog: FC<EventCommentLogProps> = ({
  events = [],
}: EventCommentLogProps) => {
  const { payloadConvert } = usePayloadConvert({ events });
  const { payloadConvert: payloadConvertAssetAssignment } = useAssetAssignmentPayloadConvert({
    events,
  });

  const commentByEvent = (val: IEvent): JSX.Element => {
    let text;
    if (val?.payload) {
      try {
        text = JSON.parse(val?.payload).comment;
      } catch {
        console.log('Payload is not in JSON format');
      }
    }

    return (
      <>
        {text && '.'}
        {text && (
          <>
            <br />
            {text}
          </>
        )}
      </>
    );
  };

  const eventType = (val: IEvent): JSX.Element => (
    <span style={{ color: assetStatusColor(val) }}>
      {changeReportStatusName(startCase(toLower(val?.eventType)))}
    </span>
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const assetCreated = (val: IEvent): JSX.Element => (
    <div className={s.eventLogItem}>
      {eventType(val)}
      {commentByEvent(val)}
    </div>
  );

  const assetUpdated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);

      return (
        <div>
          <div>Asset Information Updated</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const assetAssignmentCreated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvertAssetAssignment(payload);

      return (
        <div>
          <div>Asset Assignment Created</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvertAssetAssignment]
  );

  const assetAssignmentUpdated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvertAssetAssignment(payload);

      return (
        <div>
          <div>Asset Assignment Updated</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvertAssetAssignment]
  );

  const content = (val: IEvent): JSX.Element => {
    switch (val.eventType) {
      case AssetEventType.ASSET_CREATED:
        return assetCreated(val);

      case AssetEventType.ASSET_UPDATED:
        return assetUpdated(val);

      case AssetEventType.ASSET_ASSIGNMENT_CREATED:
        return assetAssignmentCreated(val);

      case AssetEventType.ASSET_ASSIGNMENT_UPDATED:
        return assetAssignmentUpdated(val);

      default:
        return <div>{eventType(val)}</div>;
    }
  };

  const filterValues = (): IEvent[] => {
    const result = events;

    return result;
  };

  const user = (data: any): string => (data?.user ? `${data?.user?.name}` : 'Undefined Author');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={3}>
      {filterValues()
        .slice()
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
        .map((data) => {
          return (
            <Grid item xs={12} key={uniqueId()}>
              <Grid container spacing={0}>
                <Grid item xs={2} lg={1} style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute' }}>
                    <UserInitials {...data?.user} />
                  </div>
                </Grid>
                <Grid item xs={10} lg={11} className="event-comment-log-header">
                  <span className="event-comment-log-header-title">
                    <strong>{user(data)}</strong>
                  </span>
                  <span className="event-comment-log-header-date">
                    {isSmall ? <br /> : ' - '}
                    {formatTime(data.createdAt)}
                  </span>
                </Grid>
                <Grid item xs={2} lg={1} />
                <Grid item xs={10} lg={11}>
                  {content(data)}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

// APP
export const APPLICATION = process.env.REACT_APP_APPLICATION || 'CONTRACTS';

// Backend
export const API_BASE = process.env?.REACT_APP_API_BASE || '';

// Azure
export const AZURE_CLIENT_ID = process.env?.REACT_APP_AZURE_CLIENT_ID || '';
export const AZURE_AUTHORITY = process.env?.REACT_APP_AZURE_AUTHORITY || '';
export const POST_LOGOUT_REDIRECT_URI = process.env?.REACT_APP_POST_LOGOUT_REDIRECT_URI || '';

// Typing
export const TYPING_TIMEOUT =
  process.env.REACT_APP_TYPING_TIMEOUT === undefined
    ? 400
    : parseInt(process.env.REACT_APP_TYPING_TIMEOUT);
export const DEBOUNCE_TIMEOUT =
  process.env.REACT_APP_DEBOUNCE_TIMEOUT === undefined
    ? 300
    : parseInt(process.env.REACT_APP_DEBOUNCE_TIMEOUT);

// MUI
export const SNACKBAR_AUTOHIDE = parseInt(process.env.REACT_APP_SNACKBAR_AUTOHIDE || '2') * 1000;

// Table
export const DEFAULT_ROWS_PER_PAGE = parseInt(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE || '20');
export const TABLE_PAGINATION = (process.env.REACT_APP_TABLE_PAGINATION || '20,50,100')
  .split(',')
  .map((x) => parseInt(x));

// Limits
export const NAME_LENGTH_LIMIT = parseInt(process.env.REACT_NAME_LENGTH_LIMIT || '60'); // set undefined for unlimited length
export const MAX_ASSET_VALUE = process.env.REACT_APP_MAX_ASSET_VALUE
  ? parseFloat(process.env.REACT_APP_MAX_ASSET_VALUE)
  : undefined; // set undefined for unlimited length

export const MAINTENANCE_PERIOD_URL =
  process.env.REACT_APP_MAINTENANCE_PERIOD_URL === ''
    ? undefined
    : process.env.REACT_APP_MAINTENANCE_PERIOD_URL || '/maintenance.json';

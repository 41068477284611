import { IHeadCell } from 'components/ui/TableDnd/components/TableHead/TableHead';

enum AssetsSortableColumn {
  NAME = 'NAME',
}

export const relatedAssetsTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Asset Name',
    sortable: true,
    sortBy: AssetsSortableColumn.NAME,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: 'Brand',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: false,
    label: 'Model',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  // {
  //   id: 'serialNumber',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Asset SN',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'factoryIdentifier',
    numeric: false,
    disablePadding: false,
    label: 'Serial No/Service Tag/IMEI',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'expressCode',
    numeric: false,
    disablePadding: false,
    label: 'Express Code',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'cpu',
    numeric: false,
    disablePadding: false,
    label: 'CPU',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'ram',
    numeric: false,
    disablePadding: false,
    label: 'RAM',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'storage',
    numeric: false,
    disablePadding: false,
    label: 'Storage Size',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'screenSize',
    numeric: false,
    disablePadding: false,
    label: 'Screen Size',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'serviceProvider',
    numeric: false,
    disablePadding: false,
    label: 'Service Provider',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  // {
  //   id: 'ipAddress',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'IP Address',
  //   sortable: false,
  //   dataCell: {
  //     connected: 'right',
  //     shrink: 'content',
  //   },
  // },
];

import { assetUpdateVariables } from 'graphql/ams/types/assetUpdate';
import { IAssetManagerValues } from 'hooks/assetManagerHook';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { validators } from 'constants/validators';
import validate from 'validate.js';
import { useNewAssetValidation } from 'hooks/assetNameValidationHook';
import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { pick } from 'lodash';
import {
  ASSET_CANCEL_MUTATION,
  ASSET_CREATE_MUTATION,
  ASSET_DELETE_MUTATION,
  ASSET_UNCANCEL_MUTATION,
  ASSET_UPDATE_MUTATION,
} from 'graphql/ams/assets';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import { tryUpdateProcedure } from 'utils/apollo';
import { useUI } from 'contexts/UiContext';
import { useHistory } from 'react-router-dom';
import { paths } from 'constants/paths';
import { IAssetEvent } from 'graphql/ams/types/IAssetEvent';
import { IAssetAssignment } from 'graphql/ams/types/IAssetAssignment';
import { useAssetAssignmentContext } from 'template/AssetAssignment/AssetAssignmentContext';
import {
  ASSET_ASSIGNMENT_CREATE_MUTATION,
  ASSET_ASSIGNMENT_UPDATE_MUTATION,
} from 'graphql/ams/assetAssignees';
import { getUpdates } from 'utils/extract';
import { AssetAssignmentType } from 'graphql/ams/types/graphql-types';
import { IUserRef } from 'graphql/ams/types/IUserRef';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useAssetValidation } from 'hooks/assetValidationHook';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';
import { getAsset_assets_asset } from 'graphql/ams/types/getAsset';

const newAssetTemplate: IAssetData = {
  name: '',
  isValid: true,
  showValidator: false,
  events: [],
  assignments: [],
  currentAssignment: null,
  canCancel: false,
  canDelete: false,
  canUncancel: false,
  isCanceled: false,
};
export interface IAssetContextState {
  asset: IAssetData;
  loadedAsset?: getAsset_assets_asset;
  loading: boolean;
  isNewAsset: boolean;
  allowSubmit: boolean;
  validateAssetNameResult?: any | null | undefined;
  similarAssets?: any[] | null | undefined;
  validateAssetNameTableDataSource?: any;
  similarAssetsTableDataSource?: any;
  openAssetAssignmentForm?: AssetAssignmentObjectType;
  ts: number;
  validationResult?: any;
  validateAssetDetailsResult?: { validateAssetNameResult: any; similarAssets: any };
}

export interface IAssetContextActions {
  validateExactFactoryIdentifier: ({
    factoryIdentifier,
    id,
  }: {
    factoryIdentifier?: null | string;
    id?: string;
  }) => Promise<boolean>;

  validateExactExpressCode: ({
    expressCode,
    id,
  }: {
    expressCode?: null | string;
    id?: string;
  }) => Promise<boolean>;

  onSubmit: () => void;
  onSubmitValidate: () => boolean;
  onValidateAssetDetails: () => Promise<boolean>;
  onSetAsset: (cb: (oldAsset: IAssetData) => IAssetData) => void;
  onUndoAsset: (key: string) => void;
  setOpenAssetAssignmentForm: any;
  refetch: any;
  AssetDelete: () => Promise<boolean>;
  AssetCancel: () => Promise<boolean>;
  AssetUncancel: () => Promise<boolean>;
}

const initialState: IAssetContextState = {
  asset: { ...newAssetTemplate },
  loading: false,
  isNewAsset: true,
  allowSubmit: false,
  ts: Date.now(),
};

const AssetContext = createContext<IAssetContextState & Partial<IAssetContextActions>>(
  initialState
);

export interface ISelectionPair {
  id: string;
  name: string;
}

export interface IAssetData extends Omit<assetUpdateVariables, 'id'> {
  id?: string;
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  createdAt?: Date;
  updatedAt?: Date;
  selectedAssetStatus?: ISelectionPair;
  selectedAssetType?: ISelectionPair;
  selectedAssetLocation?: ISelectionPair;
  events: IAssetEvent[];
  currentAssignment: IAssetAssignment | null;
  assignments: IAssetAssignment[];
  createdBy?: IUserRef | null;
  updatedBy?: IUserRef | null;
  canDelete: boolean;
  canCancel: boolean;
  canUncancel: boolean;
  isCanceled: boolean;
}

const aliases: any = {
  name: 'Asset Name',
  selectedAssetType: 'Asset Type',
  selectedAssetLocation: 'Asset City',
  selectedAssetStatus: 'Asset Status',
};

const validatorOptions = {
  prettify: function prettify(string: string) {
    return aliases[string] || validate.prettify(string);
  },
};
interface IAssetProviderProps {
  children: any;
  assetManager: IAssetManagerValues;
}

export enum AssetAssignmentObjectType {
  USER = 'USER',
  ROOM = 'ROOM',
  CONTRACTOR = 'CONTRACTOR',
}

export const AssetProvider: FC<IAssetProviderProps> = ({ children, assetManager }) => {
  const history = useHistory();
  const { getBasePageInfo } = useMainTabsHistory();

  const { addSnackbar } = useUI();
  const client = useApolloClient();
  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const [asset, setAsset] = useState<IAssetData>({ ...newAssetTemplate });
  const [openAssetAssignmentForm, setOpenAssetAssignmentForm] = useState<
    AssetAssignmentObjectType | undefined
  >();
  const [ts, setTs] = useState<number>(Date.now());
  const [validateAssetDetailsResult, setValidateAssetDetailsResult] = useState<{
    validateAssetNameResult: any;
    similarAssets: any;
  }>();

  const {
    asset: loadedAsset,
    loading: assetLoading,
    error: assetError,
    isNewAsset,
    refetch,
  } = assetManager;

  const {
    assignment: assetAssignment,
    initAssetAssignment,
    isNew: isNewAssetAssignment,
    onSubmitValidate: onSubmitAssignmentValidate,
  } = useAssetAssignmentContext();

  const [createAssetMutation] = useMutation(ASSET_CREATE_MUTATION);
  const [updateAssetMutation] = useMutation(ASSET_UPDATE_MUTATION);
  const [deleteAssetMutation] = useMutation(ASSET_DELETE_MUTATION);
  const [cancelAssetMutation] = useMutation(ASSET_CANCEL_MUTATION);
  const [uncancelAssetMutation] = useMutation(ASSET_UNCANCEL_MUTATION);
  const [createAssignmentMutation] = useMutation(ASSET_ASSIGNMENT_CREATE_MUTATION);
  const [updateAssignmentMutation] = useMutation(ASSET_ASSIGNMENT_UPDATE_MUTATION);

  const assetName = useMemo(() => {
    return asset.name || '';
  }, [asset.name]);

  const { validationResult, validateExactExpressCode, validateExactFactoryIdentifier } =
    useAssetValidation();

  const {
    validateAssetName,
    validateAssetNameResult,
    similarAssets,
    validateAssetNameTableDataSource,
    similarAssetsTableDataSource,
  } = useNewAssetValidation({
    name: assetName,
  });

  const { currentAssignment } = asset;

  useEffect(() => {
    if (loadedAsset && !assetLoading && !assetError) {
      const { status, type, location, currentAssignment, isLoaner, isCanceled } = loadedAsset;

      const assetData: IAssetData = {
        ...newAssetTemplate,
        ...loadedAsset,
        selectedAssetStatus: status ? pick(status, ['id', 'name']) : undefined,
        selectedAssetType: type ? pick(type, ['id', 'name']) : undefined,
        selectedAssetLocation: location ? pick(location, ['id', 'name']) : undefined,
        canCancel: !isCanceled,
        canDelete: !!isCanceled,
        canUncancel: !!isCanceled,
      };

      setAsset(assetData);

      if (currentAssignment?.user) {
        setOpenAssetAssignmentForm(AssetAssignmentObjectType.USER);
      } else if (currentAssignment?.room) {
        setOpenAssetAssignmentForm(AssetAssignmentObjectType.ROOM);
      } else if (currentAssignment?.contractorName) {
        setOpenAssetAssignmentForm(AssetAssignmentObjectType.CONTRACTOR);
      } else {
        setOpenAssetAssignmentForm(undefined);
      }

      if (currentAssignment) {
        const { assignmentType } = currentAssignment;
        const isChanged = isLoaner && assignmentType !== AssetAssignmentType.TEMPORARY;
        if (isChanged) {
          formChanged && formChanged();
        }

        initAssetAssignment(() => ({
          ...currentAssignment,
          assignmentType: isLoaner ? AssetAssignmentType.TEMPORARY : AssetAssignmentType.PERMANENT,
          isChanged,
        }));
      } else {
        initAssetAssignment();
      }
    }
  }, [loadedAsset, assetLoading, assetError, initAssetAssignment, formChanged]);

  useEffect(() => {
    if (isNewAsset === true) {
      setAsset({ ...newAssetTemplate });
    }
  }, [isNewAsset]);

  const AssetDelete = useCallback(async () => {
    let success = false;
    const variables = { id: asset.id };
    try {
      const { data } = await deleteAssetMutation({
        variables,
      });
      if (data?.assets_assetDelete) {
        addSnackbar!({ text: 'Asset is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, deleteAssetMutation, asset, client]);

  const AssetCancel = useCallback(async () => {
    let success = false;
    const variables = { id: asset.id };
    try {
      const { data } = await cancelAssetMutation({
        variables,
      });
      if (data?.assets_assetCancel) {
        addSnackbar!({ text: 'Asset is canceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, cancelAssetMutation, asset, client]);

  const AssetUncancel = useCallback(async () => {
    let success = false;
    const variables = { id: asset.id };
    try {
      const { data } = await uncancelAssetMutation({
        variables,
      });
      if (data?.assets_assetUncancel) {
        addSnackbar!({ text: 'Asset is uncanceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, uncancelAssetMutation, asset, client]);

  const allowSubmit = useMemo(() => {
    if (asset) {
      const { name, isCanceled } = asset;
      return !isCanceled && !!name;
    } else {
      return false;
    }
  }, [asset]);

  const assetValidators = useMemo(
    () => ({
      name: validators.simpleText,
      selectedAssetStatus: validators.required,
      selectedAssetType: validators.required,
      selectedAssetLocation: validators.required,
    }),
    []
  );

  const validateForm = useCallback(
    (newState: any) => {
      const errors = validate(newState, assetValidators, validatorOptions);
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [assetValidators]
  );

  const onSubmitValidate = useCallback(() => {
    const { isValid, name, currentAssignment } = asset;

    const assignmentValidationResult =
      openAssetAssignmentForm || currentAssignment ? onSubmitAssignmentValidate() : undefined;

    if (!isValid || !name || assignmentValidationResult === false) {
      setAsset(validateForm({ ...asset, showValidator: true }));
      return false;
    }
    return true;
  }, [asset, validateForm, openAssetAssignmentForm, onSubmitAssignmentValidate]);

  const onValidateAssetDetails = useCallback(async () => {
    const { validateAssetNameResult, similarAssets } = await validateAssetName(asset.id);
    setValidateAssetDetailsResult({ validateAssetNameResult, similarAssets });

    return !validateAssetNameResult && !similarAssets?.length;
  }, [validateAssetName, asset]);

  const compareFunctions = useMemo(
    () => ({
      assetTypeId: (id: string) => loadedAsset?.type?.id === id,
      assetStatusId: (id: string) => loadedAsset?.status?.id === id,
      assetLocationId: (id: string) => loadedAsset?.location?.id === id,
    }),
    [loadedAsset]
  );

  const compareAssignmentFunctions = useMemo(
    () => ({
      userId: (id: string) => loadedAsset?.currentAssignment?.user?.id === id,
    }),
    [loadedAsset]
  );

  const onSubmit = async () => {
    if (!onSubmitValidate()) {
      return;
    }

    let isErrorFound = false;
    let refreshRequired = false;

    if (!isNewAsset) {
      const prepareData = {
        ...pick(asset, [
          'id',
          'name',
          'model',

          'brand',
          'cpu',
          'ram',
          'storage',
          'screenSize',
          'purchaseDate',
          'warrantyExpirationDate',
          'serviceProvider',
          'comment',

          'factoryIdentifier',
          'expressCode',
          'dollarValue',
          'office',
        ]),
        isLoaner: !!asset?.isLoaner,
        assetStatusId: asset?.selectedAssetStatus?.id,
        assetTypeId: asset?.selectedAssetType?.id,
        assetLocationId: asset?.selectedAssetLocation?.id,
      };

      const { saveData, updateFound } = getUpdates({
        source: loadedAsset,
        updates: prepareData,
        baseOmitKeys: [],
        requiredKeys: ['id'],
        compareFunctions: compareFunctions,
      });

      if (updateFound) {
        const { isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            updateAssetMutation({
              variables: saveData,
            }),
          parseResult: (data: any) => data?.assets_assetUpdate.id,
        });

        if (isError) {
          console.log('Errors', errors);
          isErrorFound = true;

          addSnackbar!({
            text: errors?.join(' ') || 'Error',
            severity: 'error',
          });
        } else {
          refreshRequired = true;
        }
      }

      if (
        !isErrorFound &&
        assetAssignment.isChanged &&
        (openAssetAssignmentForm || currentAssignment)
      ) {
        if (isNewAssetAssignment) {
          const saveData = {
            assetId: asset.id,
            userId:
              openAssetAssignmentForm === AssetAssignmentObjectType.USER
                ? assetAssignment.selectedUser?.id
                : undefined,
            roomId:
              openAssetAssignmentForm === AssetAssignmentObjectType.ROOM
                ? assetAssignment.selectedRoom?.id
                : undefined,
            contractorName:
              openAssetAssignmentForm === AssetAssignmentObjectType.CONTRACTOR
                ? assetAssignment.contractorName
                : undefined,
            contractorEmail:
              openAssetAssignmentForm === AssetAssignmentObjectType.CONTRACTOR
                ? assetAssignment.contractorEmail
                : undefined,
            ...pick(assetAssignment, ['assignmentType', 'startDate', 'endDate', 'isReturned']),
          };

          const { errors, isError } = await tryUpdateProcedure({
            mutation: () =>
              createAssignmentMutation({
                variables: saveData,
              }),
            parseResult: (data: any) => data?.assets_assetAssignmentCreate?.id,
          });

          if (isError) {
            console.log('Errors', errors);
            isErrorFound = true;

            addSnackbar!({
              text: errors?.join(' ') || 'Error',
              severity: 'error',
            });
          } else {
            refreshRequired = true;
          }
        } else {
          const prepareData = {
            userId: assetAssignment.selectedUser?.id,
            ...pick(assetAssignment, [
              'id',
              'assignmentType',
              'startDate',
              'endDate',
              'isReturned',
            ]),
          };

          const { saveData, updateFound } = getUpdates({
            source: loadedAsset?.currentAssignment,
            updates: prepareData,
            baseOmitKeys: [],
            requiredKeys: ['id', 'assignmentType'],
            compareFunctions: compareAssignmentFunctions,
          });

          if (updateFound) {
            const { errors, isError } = await tryUpdateProcedure({
              mutation: () =>
                updateAssignmentMutation({
                  variables: saveData,
                }),
              parseResult: (data: any) => data?.assets_assetAssignmentUpdate?.id,
            });

            if (isError) {
              console.log('Errors', errors);
              isErrorFound = true;

              addSnackbar!({
                text: errors?.join(' ') || 'Error',
                severity: 'error',
              });
            } else {
              refreshRequired = true;
            }
          }
        }
      }

      if (!isErrorFound) {
        addSnackbar!({
          text: 'IT Asset is saved',
          severity: 'success',
        });
      }

      if (refreshRequired) {
        await client.resetStore();
        refetch();
      }
      setTs(() => Date.now());
    } else {
      const saveData = {
        ...pick(asset, [
          'name',
          'model',

          'brand',
          'cpu',
          'ram',
          'storage',
          'screenSize',
          'purchaseDate',
          'warrantyExpirationDate',
          'serviceProvider',
          'comment',

          'factoryIdentifier',
          'expressCode',
          'dollarValue',
          'office',
        ]),
        isLoaner: !!asset?.isLoaner,
        assetStatusId: asset?.selectedAssetStatus?.id,
        assetTypeId: asset?.selectedAssetType?.id,
        assetLocationId: asset?.selectedAssetLocation?.id,
      };

      const {
        result: newAssetId,
        errors,
        isError,
      } = await tryUpdateProcedure({
        mutation: () =>
          createAssetMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => data?.assets_assetCreate?.id,
      });

      if (isError) {
        console.log('Errors', errors);
        isErrorFound = true;

        addSnackbar!({
          text: errors?.join(' ') || 'Error',
          severity: 'error',
        });
      }

      if (
        !isErrorFound &&
        assetAssignment.isChanged &&
        (openAssetAssignmentForm || currentAssignment)
      ) {
        const saveData = {
          assetId: newAssetId,
          userId: assetAssignment.selectedUser?.id,
          ...pick(assetAssignment, ['assignmentType', 'startDate', 'endDate', 'isReturned']),
        };

        const { errors, isError } = await tryUpdateProcedure({
          mutation: () =>
            createAssignmentMutation({
              variables: saveData,
            }),
          parseResult: (data: any) => data?.assets_assetAssignmentCreate?.id,
        });

        if (isError) {
          console.log('Errors', errors);
          isErrorFound = true;

          addSnackbar!({
            text: errors?.join(' ') || 'Error',
            severity: 'error',
          });
        }
      }

      if (!isErrorFound) {
        addSnackbar!({
          text: 'IT Asset is created',
          severity: 'success',
        });
      }

      if (newAssetId && !isError) {
        await client.resetStore();
        history.push(
          getBasePageInfo().url + paths.client.ASSET_DETAILS_SUB_PAGE.replace(':id', newAssetId)
        );
      }
    }
    resetChanged && resetChanged();
  };

  const onSetAsset = useCallback(
    (cb: (oldState: IAssetData) => IAssetData) => {
      formChanged && formChanged();
      setAsset((oldState) => {
        const newState = cb(oldState);
        return validateForm(newState);
      });
    },
    [setAsset, formChanged, validateForm]
  );

  const onUndoAsset = useCallback(
    (key: string) => {
      setAsset((oldState) => {
        return validateForm({
          ...oldState,
          [key]: loadedAsset
            ? loadedAsset[key as keyof typeof loadedAsset]
            : newAssetTemplate[key as keyof typeof loadedAsset] || '',
        });
      });
    },
    [setAsset, validateForm, loadedAsset]
  );

  return (
    <AssetContext.Provider
      value={{
        asset,
        loadedAsset,
        loading: assetLoading,
        isNewAsset,
        allowSubmit,

        onSubmitValidate,
        onSubmit,
        onValidateAssetDetails,
        validateAssetDetailsResult,
        onSetAsset,
        onUndoAsset,

        validateAssetNameResult,
        similarAssets,
        validateAssetNameTableDataSource,
        similarAssetsTableDataSource,

        validationResult,
        validateExactExpressCode,
        validateExactFactoryIdentifier,

        openAssetAssignmentForm,
        setOpenAssetAssignmentForm,

        refetch: assetManager.refetch,

        ts,

        AssetDelete,
        AssetCancel,
        AssetUncancel,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export const useAssetContext = () => useContext(AssetContext);

import { AssetAssignmentType } from 'graphql/ams/types/graphql-types';

export interface IGeneralListItem {
  id: string;
  name: string;
}

export const assignedTypeOptions: IGeneralListItem[] = [
  { id: AssetAssignmentType.PERMANENT, name: 'Permanent' },
  { id: AssetAssignmentType.TEMPORARY, name: 'Loan' },
];

export const isCurrentlyAssignedFilterOptions: IGeneralListItem[] = [
  { id: 'NO', name: 'No' },
  { id: 'YES', name: 'Yes' },
];

export const assignedTypeFilterOptions: IGeneralListItem[] = [
  { id: AssetAssignmentType.PERMANENT, name: 'No' },
  { id: AssetAssignmentType.TEMPORARY, name: 'Yes' },
];

export const dollarValueRangeOptions = [
  { id: '1', name: '$0,00 up to $999.99' },
  { id: '2', name: '$1,000.00 up to $9,999.99' },
  { id: '3', name: '$10,000.00 or more' },
];

import React, { FC, ReactElement } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { HeaderCell, SortOrder } from '../HeaderCell/HeaderCell';
import s from './style.module.scss';
import cn from 'classnames';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

interface IItem {
  id: string;
  name: string;
}
export interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable?: boolean;
  hierarchy?: boolean;
  hierarchyActions?: boolean;
  sortBy?: string;
  align?: 'left' | 'center' | 'right';
  padding?: 'half';
  dataCell?: {
    align?: 'left' | 'center' | 'right';
    wrap?: 'nowrap' | 'box';
    connected?: 'left' | 'middle' | 'right';
    shrink?: 'content';
    padding?: 'half';
  };
  transformDataCell?:
    | ((data: any, row?: any, order?: SortOrder, sorted?: boolean) => ReactElement<any, any> | null)
    | null;
  transformFunction?: (
    data: any | undefined,
    row?: any,
    order?: SortOrder,
    sorted?: boolean
  ) => string;
  filter?:
    | 'text'
    | 'dropdown'
    | 'date'
    | 'date-variable-type'
    | 'component'
    | 'hierarchy-multiple'
    | 'text-dropdown';
  filterProps?: any;
  filterComponent?: ({
    onSelectChange,
    selectedItem,
  }: {
    onSelectChange?: (selectedItem: IItem) => void;
    selectedItem?: IItem | null;
    [key: string]: any;
  }) => ReactElement<any, any> | null;
  colSpan?: number;
  excelHeadStyle?: any;
  excelRowStyle?: any;
  isActionsCell?: boolean;
}

export interface TableHeadProps {
  headCells: IHeadCell[];
  orderBy?: string;
  order?: SortOrder;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  printView?: boolean;
  showDraggColumn?: boolean;
  stickyHeader?: boolean;
}

export const TableHead: FC<TableHeadProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
  printView,
  showDraggColumn,
  stickyHeader,
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  let count = -1;
  return (
    <TableRow>
      {[
        showDraggColumn ? (
          <TableCell align="left" key="order" style={{ backgroundColor: 'white' }}>
            &nbsp;
          </TableCell>
        ) : undefined,
        ...headCells.map((headCell) => {
          count++;
          return (
            <TableCell
              key={count.toString() + '_' + headCell.id}
              className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
              style={stickyHeader ? { backgroundColor: 'white' } : undefined}
            >
              <div
                onClick={
                  headCell.sortable
                    ? debounce(createSortHandler(headCell.sortBy || headCell.id), DEBOUNCE_TIMEOUT)
                    : undefined
                }
              >
                <HeaderCell
                  key={headCell.id}
                  label={headCell.label}
                  sortable={
                    headCell.sortable &&
                    (!printView || orderBy === headCell.sortBy || orderBy === headCell.id)
                  }
                  sort={orderBy === headCell.sortBy || orderBy === headCell.id ? order : undefined}
                ></HeaderCell>
              </div>
            </TableCell>
          );
        }),
      ]}
    </TableRow>
  );
};

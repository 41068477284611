import { Tooltip } from '@mui/material';
import { FC } from 'react';
import s from './style.module.scss';

const tooltips = {
  '*': 'minimum needed details for creating a draft proposal',
  '**': 'mandatory details for completing proposal creation',
};

export interface IAsterisksProps {
  count?: number;
  tooltipTitle?: string;
}

export const Asterisks: FC<IAsterisksProps> = ({ count, tooltipTitle }) => {
  if (!count) {
    return <></>;
  }
  let line = '';
  for (var i = 0; i < count; i++) {
    line += '*';
  }
  return (
    <div className={s.asterisksBlock}>
      {tooltips.hasOwnProperty(line) || tooltipTitle ? (
        <Tooltip title={tooltipTitle || line + ' - ' + tooltips[line as keyof typeof tooltips]}>
          <div>{line}</div>
        </Tooltip>
      ) : (
        line
      )}
    </div>
  );
};

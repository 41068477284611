import React, { createContext, FC, useContext } from 'react';

export interface IContextState {}

export interface IContextActions {}

const initialState: IContextState = {};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children }) => {
  return <ComponentContext.Provider value={{}}>{children}</ComponentContext.Provider>;
};

export const useComponentContext = () => useContext(ComponentContext);

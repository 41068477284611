import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetExpressCodesCount: assetsByColumnsCount) =>
  dataAssetExpressCodesCount.assets_assets_extensionCount;
const getItems = (dataAssetExpressCodes: assetsByColumns) =>
  dataAssetExpressCodes.assets_assets_extension?.map((expressCode) => ({
    id: expressCode.id,
    name: expressCode.expressCode,
  }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ expressCodeContains: localSearch });
const distinctOn = [{ column: 'EXPRESS_CODE' }];
const gqlProps = { withExpressCode: true };
const order = [{ column: 'EXPRESS_CODE', order: 'ASC' }];

export interface IAssetExpressCodesAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetExpressCodesAutocompleteDataHook = ({
  search,
}: IAssetExpressCodesAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

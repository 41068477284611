import { FC } from 'react';

import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';
import EasyAutoComplete from 'components/EasyAutoComplete';

interface IItem {
  id: string;
  name: string;
  email: string | null;
}

export interface ISelectUserProps {
  onSelectChange?: (selectedItem: IItem) => void;
  selectedItem?: IItem | null;
  error?: boolean;
}

export const SelectUser: FC<ISelectUserProps> = ({ onSelectChange, selectedItem, error }) => {
  const usersProps = useUsersAutocompleteDataHook({
    search: '',
  });

  return (
    <EasyAutoComplete
      key={selectedItem?.id || '_'}
      label=""
      textFieldStyle="outlined"
      optionsLabel="ddLabel"
      selected={selectedItem}
      selectedChanged={(value: any): void => {
        onSelectChange && onSelectChange(value);
      }}
      {...usersProps}
      getOptionSelected={(option: any, value: any) => {
        return option.id === value.id || (value.id === '0' && option.email === value.email);
      }}
      placeholder="Select D&M User"
      disableClearable={false}
      error={error}
    />
  );
};

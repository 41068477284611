import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { GET_ASSETS } from 'graphql/ams/assets';
import { assets } from 'graphql/ams/types/assets';

export const useAssetValidation = () => {
  const [validationResult, setValidationResult] = useState<{ expressCode?: string }>({});
  const client = useApolloClient();

  const validateExactFactoryIdentifier = useCallback(
    async ({ factoryIdentifier, id }: { factoryIdentifier?: null | string; id?: string }) => {
      if (!factoryIdentifier) {
        setValidationResult((old) => ({ ...old, factoryIdentifier: undefined }));
        return false;
      }

      const { data, error } = await client.query<assets>({
        query: GET_ASSETS,
        variables: { filter: { factoryIdentifier } },
        fetchPolicy: 'network-only',
      });

      if (error) {
        throw error;
      }

      if (data.assets_assets.length) {
        const val = data.assets_assets.filter((asset) => asset.id !== id);
        if (val.length) {
          setValidationResult((old) => ({ ...old, factoryIdentifier }));
          return true;
        }
      }
      setValidationResult((old) => ({ ...old, factoryIdentifier: undefined }));
      return false;
    },
    [client]
  );

  const validateExactExpressCode = useCallback(
    async ({ expressCode, id }: { expressCode?: null | string; id?: string }) => {
      if (!expressCode) {
        setValidationResult((old) => ({ ...old, expressCode: undefined }));
        return false;
      }

      const { data, error } = await client.query<assets>({
        query: GET_ASSETS,
        variables: { filter: { expressCode } },
        fetchPolicy: 'network-only',
      });

      if (error) {
        throw error;
      }

      if (data.assets_assets.length) {
        const val = data.assets_assets.filter((asset) => asset.id !== id);
        if (val.length) {
          setValidationResult((old) => ({ ...old, expressCode }));
          return true;
        }
      }
      setValidationResult((old) => ({ ...old, expressCode: undefined }));
      return false;
    },
    [client]
  );

  return {
    validationResult,
    validateExactExpressCode,
    validateExactFactoryIdentifier,
  };
};

import {
  Cancel as CancelIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC } from 'react';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

const useStyles = makeStyles((theme: any) => ({
  hoverWarning: {
    '&:hover': {
      color: red[400] + ' !important',
    },
  },
  hoverInfo: {
    '&:hover': {
      color: blue[400] + ' !important',
    },
  },
}));

export interface IAssetLocationRowActionsProps {
  isEditMode: () => boolean;
  onSave: () => void;
  onDelete?: () => void;
  onCancel: () => void;
  onEdit: () => void;
  onSelect?: () => void;
}
export const AssetLocationRowActions: FC<IAssetLocationRowActionsProps> = ({
  isEditMode,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  onSelect,
}) => {
  const classes = useStyles();
  if (isEditMode()) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '28px' }}>
        <Tooltip title="Save">
          <IconButton
            size="small"
            style={{ color: blue[500] }}
            className={classes.hoverInfo}
            onClick={debounce((e: any) => {
              onSave();
            }, DEBOUNCE_TIMEOUT)}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton
            size="small"
            style={{ color: blue[500] }}
            className={classes.hoverWarning}
            onClick={debounce((e: any) => {
              onCancel();
            }, DEBOUNCE_TIMEOUT)}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  } else
    return (
      <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '28px' }}>
        <Tooltip title="Edit">
          <IconButton
            size="small"
            style={{ color: blue[500] }}
            className={classes.hoverInfo}
            onClick={debounce((e: any) => {
              onEdit();
            }, DEBOUNCE_TIMEOUT)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {!!onDelete ? (
          <Tooltip title="Delete">
            <IconButton
              size="small"
              style={{ color: blue[500] }}
              className={classes.hoverWarning}
              onClick={debounce((e: any) => {
                onDelete && onDelete();
              }, DEBOUNCE_TIMEOUT)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : undefined}

        <Tooltip title={!!onSelect ? 'Select and Close' : 'Save changes first'}>
          <div>
            <IconButton
              size="small"
              style={!onSelect ? { color: grey[500] } : { color: blue[500] }}
              className={classes.hoverInfo}
              onClick={(e: any) => {
                e.stopPropagation();
                onSelect && onSelect();
              }}
              disabled={!onSelect}
            >
              <CheckCircleOutlinedIcon fontSize="small" />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
};

import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import { assets } from 'graphql/ams/types/assets';
import { assetCount } from 'graphql/ams/types/assetCount';
import { GET_ASSETS, GET_ASSET_COUNT } from 'graphql/ams/assets';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadAssetsStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getAssets', name: 'Loading Pages' },
  ],
  progress: { calculate: { steps: 1, current: 0 }, getAssets: { steps: 1, current: 0 } },
});

export const useLoadAssets = () => {
  const client = useApolloClient();
  const [loadAssetsStatus, setLoadAssetsStatus] = useState<IProcess>(getInitialLoadAssetsStatus());

  const setLoadAssetStepCount = useCallback((step: string, count: number) => {
    setLoadAssetsStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadAssetStepProgress = useCallback((step: string) => {
    setLoadAssetsStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
      },
    }));
  }, []);

  const loadAssets = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadAssetsStatus(() => getInitialLoadAssetsStatus());
      setLoadAssetsStatus((old) => ({ ...old, status: 'run' }));
      let result: { data: any; error: ApolloError | undefined };
      if (!allPages) {
        incLoadAssetStepProgress('calculate');

        const { data, error } = await client.query<assets>({
          query: GET_ASSETS,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        incLoadAssetStepProgress('getAssets');

        if (data.assets_assets) {
          result = { data: data?.assets_assets, error };
        } else {
          result = { data: [], error };
        }
      } else {
        const { data: assetCount, error: assetCountError } = await client.query<assetCount>({
          query: GET_ASSET_COUNT,
          variables: { ...variables },
        });
        incLoadAssetStepProgress('calculate');

        if (assetCount && !assetCountError) {
          setLoadAssetStepCount('getAssets', Math.ceil(assetCount.assets_assetCount / rowsPerPage));
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<assets>({
            query: GET_ASSETS,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.assets_assets?.length;
          if (!lastPage) {
            incLoadAssetStepProgress('getAssets');
            result.data.push(...data.assets_assets);
          }
        }
      }

      setLoadAssetsStatus((old) => ({ ...old, status: 'fin' }));
      return result;
    },
    [client, incLoadAssetStepProgress, setLoadAssetStepCount]
  );

  return {
    loadAssets,
    loadAssetsStatus,
  };
};

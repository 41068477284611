import { gql } from '@apollo/client';
import { IAssetStatus } from './fragments';

export const GET_ASSET_STATUS_NAMES = gql`
  query assetStatusNames {
    assets_assetStatuses {
      id
      name
    }
  }
`;

export const GET_ASSET_STATUSES = gql`
  query assetStatuses {
    assets_assetStatuses {
      ...IAssetStatus
    }
  }
  ${IAssetStatus}
`;

export const ASSET_STATUS_CREATE_MUTATION = gql`
  mutation assetStatusCreate(
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetStatusCreate(
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

export const ASSET_STATUS_UPDATE_MUTATION = gql`
  mutation assetStatusUpdate(
    $id: ID!
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetStatusUpdate(
      id: $id
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IUseItemState<T> {
  items: T[];
  totalItems: number;
  maxPage: number;
  loadPage: (page: number) => void;
  loadPageCount: () => void;
  pagination: any;
  onOpen: any;
  onClose: any;
  onInputChange: (event: object, value: string, reason: string) => void;
  getOptionSelected: (option: any, value: any) => boolean;
  loading: boolean;
  error: boolean;
  errorCount?: any;
  errorItems?: any;
}

export interface IColumn {
  column: string;
}

const rowsPerPage = 20;

interface IUseItemProps<T, TFilter> {
  search: string;
  distinctOn?: IColumn[];
  GET_QUERY: any;
  GET_COUNT_QUERY: any;
  getCount: (data: any) => number;
  getItems: (data: any) => T[];
  getItemId: (item: any) => string;
  filterToAPI?: (filterValues: any) => TFilter;

  order?: any;
  gqlProps?: any;
}
export const useAutoCompleteDataSource = <
  itemsList extends unknown,
  itemsCount extends unknown,
  IItem extends unknown,
  TFilter extends unknown = undefined
>({
  search,
  distinctOn,
  GET_QUERY,
  GET_COUNT_QUERY,
  getCount,
  getItems,
  getItemId,
  filterToAPI,
  order,
  gqlProps,
}: IUseItemProps<IItem, TFilter>): IUseItemState<IItem> => {
  const [localSearch, setLocalSearch] = useState(search);
  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState<IItem[]>([]);

  const [
    loadItems,
    {
      data: dataItems,
      loading: loadingItems,
      refetch: refetchItems,
      called: calledItems,
      error: errorItems,
    },
  ] = useLazyQuery<itemsList>(GET_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    loadItemsCount,
    {
      data: dataItemsCount,
      loading: loadingItemsCount,
      refetch: refetchItemsCount,
      called: calledItemsCount,
      error: errorCount,
    },
  ] = useLazyQuery<itemsCount>(GET_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  const loadPage = useCallback(
    (page: any) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : [{ column: 'NAME', order: 'ASC' }],
        filter: filterToAPI
          ? filterToAPI(localSearch)
          : {
              nameContains: localSearch,
              isActive: true,
            },
        distinctOn,
        ...gqlProps,
      };

      if (calledItems) {
        refetchItems!(variables);
      } else {
        loadItems({ variables });
      }
    },
    [loadItems, refetchItems, calledItems, localSearch, filterToAPI, order, distinctOn, gqlProps]
  );

  const loadPageCount = useCallback(() => {
    const variables = {
      filter: filterToAPI
        ? filterToAPI(localSearch)
        : {
            nameContains: localSearch,
            isActive: true,
          },
      sort: order && order.length ? order : [{ column: 'NAME', order: 'ASC' }],
      distinctOn,
      ...gqlProps,
    };

    if (calledItemsCount) {
      refetchItemsCount!(variables);
    } else {
      loadItemsCount({ variables });
    }
  }, [
    loadItemsCount,
    refetchItemsCount,
    calledItemsCount,
    localSearch,
    filterToAPI,
    distinctOn,
    order,
    gqlProps,
  ]);

  useEffect(() => {
    if (!loadingItemsCount && dataItemsCount) {
      setTotalItems(getCount(dataItemsCount));
    }
  }, [loadingItemsCount, dataItemsCount, getCount]);

  useEffect(() => {
    if (!errorItems && dataItems && !loadingItems) {
      setItems((items) => {
        const oldIds = items.map((item) => getItemId(item));
        const appendItems = getItems(dataItems).filter(
          (item) => !oldIds.includes(getItemId(item))
        ) as IItem[];
        return [...items, ...appendItems];
      });
    }
  }, [dataItems, loadingItems, errorItems, getItemId, getItems]);

  useEffect(() => {
    setLocalSearch(search);
  }, [search]);

  useEffect(() => {
    setItems([]);
    loadPage(0);
    loadPageCount();
  }, [loadPage, loadPageCount, localSearch]);

  const pagination = useMemo(
    () => ({
      pages: maxPage,
      loadPage: (page: number) => {
        loadPage(page);
        loadPageCount();
      },
    }),
    [loadPage, loadPageCount, maxPage]
  );

  const onOpen = useCallback(() => {
    if (!items.length) {
      setItems([]);
      loadPage(0);
      loadPageCount();
    }
  }, [setItems, loadPage, loadPageCount, items.length]);

  const onClose = useCallback(() => {
    setItems([]);
  }, [setItems]);

  const onInputChange = useCallback((event: object, value: string, reason: string) => {
    setLocalSearch(value);
  }, []);

  const getOptionSelected = useCallback((option: any, value: any) => {
    return option.id === value.id || (value.id === '0' && option.email === value.email);
  }, []);

  return {
    items,
    totalItems,
    maxPage,
    loadPage,
    loadPageCount,
    pagination,
    onOpen,
    onClose,
    onInputChange,
    getOptionSelected,
    loading: loadingItems || loadingItemsCount,
    error: !!errorItems || !!errorCount,
    errorCount,
    errorItems,
  };
};

import React, { useCallback } from 'react';
import { FC } from 'react';
import s from './style.module.scss';
import cn from 'classnames';

import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { Tab, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';

export interface IAssetTabsProps {
  currentView: string;
  tabs: { label: string; value: string[]; path: string }[];
}

export const AssetTabs: FC<IAssetTabsProps> = ({ currentView, tabs }) => {
  const history = useHistory();
  const { getBasePageInfo } = useMainTabsHistory();

  const changeView = useCallback(
    (tabValue: string) => {
      const foundTab = tabs.find((tab) => tab.value.includes(tabValue));
      if (foundTab) {
        history.push(getBasePageInfo().url + foundTab?.path);
      }
    },
    [history, tabs, getBasePageInfo]
  );

  const { confirmRequest } = useFormChangedDialogContext();

  return (
    <div style={{ width: 'fit-content' }}>
      <Tabs
        value={currentView}
        onChange={(e, val) => {
          confirmRequest!((confirmed) => {
            confirmed && changeView!(val);
          });
        }}
        variant="fullWidth"
        className={s.folderTypeTabs}
        TabIndicatorProps={{ style: { background: '#006AD4' } }}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              label={tab.label}
              value={tab.value[0]}
              key={tab.value[0]}
              className={cn(
                s.allTabs,
                tab.value.includes(currentView) ? s.activeTab : s.inactiveTab
              )}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

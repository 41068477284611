import { gql } from '@apollo/client';

const typeDefs = gql`
  extend type Query {
    fileArchives: [FileArchive!]
    owner: Boolean
    account: Account
    token: String
  }

  extend type Mutation {
    setIsLoggedIn: Boolean
    setAccount(account: Account): Account
    setAuthenticated: Boolean
    setToken: String
    logout: Boolean
  }

  type Account {
    accountIdentifier: String
    environment: String
    homeAccountIdentifier: String
    idToken: IdToken
    idTokenClaims: IdTokenClaims
    name: String
    sid: String
    userName: String
  }

  type IdToken {
    acct: Int
    aio: String
    aud: String
    auth_time: Int
    ctry: String
    email: String
    exp: Int
    family_name: String
    given_name: String
    home_oid: String
    iat: Int
    idp: String
    ipaddr: String
    iss: String
    name: String
    nbf: Int
    nonce: String
    oid: String
    platf: String
    preferred_username: String
    sid: String
    sub: String
    tenant_ctry: String
    tenant_region_scope: String
    tid: String
    uti: String
    ver: String
    xms_tpl: String
  }

  type IdTokenClaims {
    acct: Int
    aio: String
    aud: String
    auth_time: Int
    ctry: String
    email: String
    exp: Int
    family_name: String
    given_name: String
    home_oid: String
    iat: Int
    idp: String
    ipaddr: String
    iss: String
    name: String
    nbf: Int
    nonce: String
    oid: String
    platf: String
    preferred_username: String
    sid: String
    sub: String
    tenant_ctry: String
    tenant_region_scope: String
    tid: String
    uti: String
    ver: String
    xms_tpl: String
  }

  type FileArchive {
    stakeholder: String
    file: String
    date: String
  }
`;

export default typeDefs;

import { Grid } from '@mui/material';
import { chunk } from 'lodash';
import { Children, FC } from 'react';
export interface IGroupByTwoGridProps {
  children: any;
}

export const GroupByTwoGrid: FC<IGroupByTwoGridProps> = ({ children }) => {
  const childrenArr = Children.toArray(children);
  const groupByTwo = chunk(childrenArr, 2);
  let count = 0;
  return (
    <>
      {groupByTwo.map(([left, right]) => {
        count++;
        return (
          <Grid item xs={12} key={count}>
            <Grid
              container
              spacing={2}
              columnSpacing={{ xs: 2, md: 7 }}
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} alignContent="center" alignItems="center">
                  {left}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} alignContent="center" alignItems="center">
                  {right}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );

  // cloneElement(child as JSX.Element, props),
};

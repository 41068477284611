import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetOfficesCount: assetsByColumnsCount) =>
  dataAssetOfficesCount.assets_assets_extensionCount;
const getItems = (dataAssetOffices: assetsByColumns) =>
  dataAssetOffices.assets_assets_extension?.map((office) => ({
    id: office.id,
    name: office.office,
  }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ officeContains: localSearch });
const distinctOn = [{ column: 'OFFICE' }];
const gqlProps = { withOffice: true };
const order = [{ column: 'OFFICE', order: 'ASC' }];

export interface IAssetOfficesAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetOfficesAutocompleteDataHook = ({
  search,
}: IAssetOfficesAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

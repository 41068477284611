import { FC } from 'react';
import { Container, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

export interface ITableFrameProps {
  children: any;
}

export const TableFrame: FC<ITableFrameProps> = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        {children}
      </Paper>
    </Container>
  );
};

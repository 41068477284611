import { ApolloError, useApolloClient } from '@apollo/client';
import { GET_ASSET } from 'graphql/ams/assets';
import { getAsset, getAsset_assets_asset } from 'graphql/ams/types/getAsset';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface IAssetManagerValues {
  asset: getAsset_assets_asset | undefined;
  error?: ApolloError;
  loading: boolean;
  isNewAsset: boolean;
  refetch: any;
}

export interface IAssetManagerProps {
  assetId?: string;
}
export const useAssetManager = ({ assetId }: IAssetManagerProps): IAssetManagerValues => {
  const client = useApolloClient();

  const [asset, setAsset] = useState<{
    asset: getAsset_assets_asset | undefined;
    loading: boolean;
    error: ApolloError | undefined;
  }>({ loading: false, error: undefined, asset: undefined });

  const getData = useCallback(
    async (variables: any) => {
      const { data, error } = await client.query<getAsset>({
        query: GET_ASSET,
        variables: { ...variables },
        fetchPolicy: 'network-only',
      });
      return { data, error };
    },
    [client]
  );

  const loadItem = useCallback(async () => {
    const variables = {
      id: assetId,
    };
    setAsset((old) => ({ ...old, loading: true }));
    const { data, error } = await getData(variables);
    if (!error && data?.assets_asset) {
      setAsset(() => ({ asset: { ...data.assets_asset }, loading: false, error }));
    } else {
      setAsset(() => ({ asset: undefined, loading: false, error }));
    }
  }, [assetId, getData]);

  useEffect(() => {
    if (!!assetId) {
      loadItem();
    }
  }, [assetId, loadItem]);

  const isNewAsset = useMemo(() => {
    return !assetId;
  }, [assetId]);

  return {
    ...asset,
    isNewAsset,
    refetch: loadItem,
  };
};

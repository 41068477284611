import { getMsal1Account } from '../authorization/auth-utils-msal2';
import { getTokenFromLocalStorage } from '../utils/user';

export const resolvers = {
  Query: {
    account: () => {
      return getMsal1Account();
    },
    token: () => {
      return getTokenFromLocalStorage();
    },
  },
};

import { ApolloClient, ApolloLink, InMemoryCache, gql } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { resolvers } from './resolvers';
import { typeDefs } from './types';
import { getTokenFromLocalStorage } from '../utils/user';
import server from '../constants/paths/server';

const httpLink = createUploadLink({
  uri: server.GRAPHQL_API,
});

const cache = new InMemoryCache();

const authorizationLink = new ApolloLink((operation, forward) => {
  const token = getTokenFromLocalStorage();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authorizationLink, httpLink as any]),
  typeDefs,
  resolvers,
});

cache.writeQuery({
  query: gql`
    query {
      token
      account
    }
  `,
  data: {
    account: null,
    token: getTokenFromLocalStorage(),
  },
});

export default client;

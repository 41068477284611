import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  IPrintingData,
  IPrintingMeta,
  PrintingFrame,
} from './components/PrintingFrame/PrintingFrame';
import { useReactToPrint } from 'react-to-print';

export interface IPrintProps {
  printing: boolean;
  setPrinting: any;
  PrintingMeta: FC<IPrintingMeta>;
  PrintingData?: FC<IPrintingData>;
  landscape?: boolean;
  subtitle?: string;
  children: any;
}
export const Print: FC<IPrintProps> = ({
  printing,
  children,
  setPrinting,
  PrintingMeta,
  PrintingData,
  landscape,
  subtitle,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [printReady, setPrintReady] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrinting(false);
    },
    pageStyle: !!landscape ? '@page { size: landscape } html { font-size: 12px }' : undefined,
  });

  useEffect(() => {
    if (printReady && handlePrint) {
      setTimeout(handlePrint, 1500);
      setPrintReady(() => false);
    }
  }, [printReady, handlePrint]);

  const onReady = useCallback(() => {
    setPrintReady(() => true);
  }, []);

  return (
    <>
      {printing ? (
        <div style={{ display: 'none' }}>
          <PrintingFrame
            onReady={onReady}
            ref={componentRef}
            DocumentPrintingMeta={PrintingMeta}
            DocumentPrintingData={PrintingData}
            subtitle={subtitle}
          >
            {children}
          </PrintingFrame>
        </div>
      ) : undefined}
    </>
  );
};

import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetRamsCount: assetsByColumnsCount) =>
  dataAssetRamsCount.assets_assets_extensionCount;
const getItems = (dataAssetRams: assetsByColumns) =>
  dataAssetRams.assets_assets_extension?.map((ram) => ({ id: ram.id, name: ram.ram }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ ramContains: localSearch });
const distinctOn = [{ column: 'RAM' }];
const gqlProps = { withRAM: true };
const order = [{ column: 'RAM', order: 'ASC' }];

export interface IAssetRamsAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetRamsAutocompleteDataHook = ({ search }: IAssetRamsAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

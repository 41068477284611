export type FieldType =
  | 'label'
  | 'text'
  | '$'
  | 'easyAutoComplete'
  | 'textareaAutosize'
  | 'date'
  | 'msal'
  | 'boolean'
  | 'related';

export interface ITwoColumnItem {
  title: string;
  id: string;
  field?:
    | {
        type: FieldType;
        props?: any;
      }
    | undefined;
  parse?: (value: any) => any;
  parentField?: string;
  hidden?: boolean;
}

export interface IFieldsDescription extends ITwoColumnItem {
  showColapsed?: boolean;
  group?: number;
  isDisabled?: any;
  hidden?: boolean;
}
export const customFields: Array<Array<IFieldsDescription>> = [
  [
    {
      id: 'name',
      title: 'Asset Name',
    },
    {
      id: 'brand',
      title: 'Brand',
    },

    {
      id: 'model',
      title: 'Model',
    },
    {
      id: 'serialNumber',
      title: 'SN',
    },
    {
      id: 'cpu',
      title: 'CPU',
    },
    {
      id: 'ram',
      title: 'RAM',
    },
    {
      id: 'storage',
      title: 'Storage Size',
    },
    {
      id: 'screenSize',
      title: 'Screen Size',
    },
    {
      id: 'serviceProvider',
      title: 'Service Provider',
    },
    {
      id: 'comment',
      title: 'Notes',
    },

    {
      id: 'factoryIdentifier',
      title: 'Serial No/Service Tag/IMEI',
    },
    {
      id: 'expressCode',
      title: 'Express Code',
    },
    {
      id: 'office',
      title: 'Office',
    },
    {
      id: 'dollarValue',
      title: 'Value',
      field: { type: '$' },
    },

    // Boolean
    {
      id: 'isLoaner',
      title: 'Is Loaner',
      field: { type: 'boolean' },
    },

    // Date
    {
      id: 'purchaseDate',
      title: 'Purchase Date',
      field: { type: 'date' },
    },
    {
      id: 'warrantyExpirationDate',
      title: 'Warranty Expiration Date',
      field: { type: 'date' },
    },

    // Related
    {
      id: 'assetType',
      title: 'Asset Type',
      field: { type: 'related' },
    },
    {
      id: 'assetStatus',
      title: 'Asset Status',
      field: { type: 'related' },
    },
    {
      id: 'assetLocation',
      title: 'Asset City',
      field: { type: 'related' },
    },
  ],
];

import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetScreenSizesCount: assetsByColumnsCount) =>
  dataAssetScreenSizesCount.assets_assets_extensionCount;
const getItems = (dataAssetScreenSizes: assetsByColumns) =>
  dataAssetScreenSizes.assets_assets_extension?.map((screenSize) => ({
    id: screenSize.id,
    name: screenSize.screenSize,
  }));
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ screenSizeContains: localSearch });
const distinctOn = [{ column: 'SCREEN_SIZE' }];
const gqlProps = { withScreenSize: true };
const order = [{ column: 'SCREEN_SIZE', order: 'ASC' }];

export interface IAssetScreenSizesAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetScreenSizesAutocompleteDataHook = ({
  search,
}: IAssetScreenSizesAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

import EasyAutoComplete from 'components/EasyAutoComplete';
import { GET_ASSET_TYPES } from 'graphql/ams/assetTypes';
import { assetTypes } from 'graphql/ams/types/assetTypes';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';

import { FC, useMemo } from 'react';
import { hierarchySort } from 'utils/hierarchySort';

export interface IAssetTypeData {
  id: string;
  name: string;
}

export interface ISelectAssetTypeProps {
  assetType?: IAssetTypeData;
  onSelectChange: (value: IAssetTypeData | undefined) => void;
  [id: string]: any;
}

export const SelectAssetType: FC<ISelectAssetTypeProps> = ({
  assetType,
  onSelectChange,
  ...easyAutoCompleteProps
}) => {
  const statusesQuery = useMemo(() => {
    return {
      GET_QUERY: GET_ASSET_TYPES,
      getItemId: (item: any) => item.id,
      getItems: (data: assetTypes) =>
        hierarchySort(
          data.assets_assetTypes.map(({ id, name, order, parent, isSelectable }) => ({
            id,
            key: name,
            order,
            parent: parent?.id,
            disabled: !isSelectable,
          }))
        ),
    };
  }, []);

  const statusesProps = useAutoCompleteSimpleDataSource<any, any, any>(statusesQuery);

  const updateAssetType =
    (getValue: (props: any) => IAssetTypeData | undefined) => (props: any) => {
      const value = getValue(props);
      onSelectChange(value);
    };

  const selectedItem = useMemo(() => {
    if (!assetType) {
      return null;
    } else {
      const { id, name } = assetType;
      return { id: id, key: name };
    }
  }, [assetType]);

  return (
    <EasyAutoComplete
      disableClearable={false}
      selected={selectedItem}
      key={`stockExchanges`}
      label=""
      optionsLabel="key"
      textFieldStyle="outlined"
      {...statusesProps}
      selectedChanged={updateAssetType((value: any) =>
        value ? { id: value.id, name: value.key } : undefined
      )}
      getSelectedLabel={(option: any) => {
        return option.key;
      }}
      {...easyAutoCompleteProps}
      showHierarchyLevel
    />
  );
};

import { Grid, Typography } from '@mui/material';
import { useAssetContext } from 'contexts/asset/AssetContext';
import { FC } from 'react';
import { EventCommentLog } from 'template/Asset/components/AssetEventsLog';

export const AssetEvents: FC = () => {
  const { asset } = useAssetContext();
  const { events } = asset;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" className="label-title-nocase">
          Events Log:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EventCommentLog events={events}></EventCommentLog>
      </Grid>
    </Grid>
  );
};

import React, { createContext, FC, useCallback, useContext, useState } from 'react';

export interface IContextState {
  changed: boolean;
  confirmCallback?: (confirmed: boolean) => void;
}

export interface IContextActions {
  formChanged: () => void;
  resetChanged: () => void;
  confirmRequest: (cb: (confirmed: boolean) => void) => void;
  leaveAllow: () => void;
  leaveDecline: () => void;
  checkAndProceed: (cb: any) => () => void;
}

const initialState: IContextState = {
  changed: false,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children }) => {
  const [changed, setChanged] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState<(confirmed: boolean) => void>();

  const formChanged = useCallback(() => {
    setChanged(true);
  }, []);

  const resetChanged = useCallback(() => {
    setChanged(false);
  }, []);

  const confirmRequest = useCallback(
    (cb: (confirmed: boolean) => void) => {
      if (changed) {
        setConfirmCallback(() => cb);
      } else {
        cb(true);
      }
    },
    [changed]
  );

  const leaveAllow = () => {
    setChanged(false);
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(true);
  };

  const leaveDecline = () => {
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(false);
  };

  const checkAndProceed = (callback: () => void) => () => {
    confirmRequest!((confirmed) => {
      confirmed && callback();
    });
  };

  return (
    <ComponentContext.Provider
      value={{
        formChanged,
        resetChanged,
        confirmRequest,
        leaveDecline,
        leaveAllow,
        changed,
        confirmCallback,
        checkAndProceed,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);

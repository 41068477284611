import { FormControlLabel, Grid, Paper, Switch, TextareaAutosize, TextField } from '@mui/material';
import { FC } from 'react';
import { FieldRow } from 'template/Asset/components/AssetDetailsFormFields/components/FieldRow/FieldRow';
import { useEditAssetStatusContext } from './EditAssetStatusContext';

export interface IEditAssetStatusProps {}

export const EditAssetStatus: FC<IEditAssetStatusProps> = () => {
  const { status, setStatus } = useEditAssetStatusContext();
  const { name, description, isSelectable, orderNo, showValidator, errors } = status;

  return (
    <Paper style={{ padding: '1rem', margin: '1rem' }} elevation={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <FieldRow title="Order" md1={4} md2={8}>
              <TextField
                placeholder="Input order number..."
                variant="outlined"
                size="small"
                value={orderNo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus!((oldStatus: any) => {
                    return { ...oldStatus, orderNo: e.target.value };
                  });
                }}
                error={showValidator && errors?.orderNo}
                style={{ paddingRight: '12px' }}
              />
            </FieldRow>
            <FieldRow title="Status" isRequired md1={4} md2={8}>
              <TextField
                placeholder="Input status name..."
                variant="outlined"
                size="small"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus!((oldStatus: any) => {
                    return { ...oldStatus, name: e.target.value };
                  });
                }}
                fullWidth
                error={showValidator && errors?.name}
                style={{ paddingRight: '12px' }}
              />
            </FieldRow>
            <FieldRow title="Description" md1={4} md2={8}>
              <div style={{ paddingRight: '12px' }}>
                <TextareaAutosize
                  className="MuiInputBase-input"
                  color="grey"
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    minWidth: '100%',
                    minHeight: '2rem',
                    padding: 10,
                    borderRadius: 4,
                    border: '1px #ccc solid',
                    overflow: 'auto',
                    marginBottom: '1rem',
                  }}
                  aria-label="minimum height"
                  minRows={1}
                  placeholder="Write a description..."
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setStatus!((oldStatus: any) => {
                      return { ...oldStatus, description: e.target.value };
                    });
                  }}
                  value={description || ''}
                />
              </div>
            </FieldRow>
            <FieldRow title="Is Selectable" md1={4} md2={8}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={!!isSelectable}
                    onChange={() => {
                      setStatus!((oldStatus: any) => {
                        return { ...oldStatus, isSelectable: !isSelectable };
                      });
                    }}
                  />
                }
                label={!!isSelectable ? 'Yes' : 'No'}
              />
            </FieldRow>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

import { getMsal1Account } from '../authorization/auth-utils-msal2';
import { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';

export const useMsalAccount = () => {
  const [msalAccount, setMsalAccount] = useState<any>();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      setMsalAccount(getMsal1Account());
    } else {
      setMsalAccount(undefined);
    }
  }, [isAuthenticated]);

  return { msalAccount };
};

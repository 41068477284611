import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { GET_ASSET_NAMES, GET_ASSET_NAMES_COUNT } from 'graphql/ams/assetNames';
import { assetNamesList, assetNamesList_assets_assets } from 'graphql/ams/types/assetNamesList';
import { assetNamesCount } from 'graphql/ams/types/assetNamesCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetNamesCount: assetNamesCount) => dataAssetNamesCount.assets_assetCount;
const getItems = (dataAssetNames: assetNamesList) => dataAssetNames.assets_assets;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface IAssetNamesAutocompleteDataProps {
  search: string;
}

export const useAssetNamesAutocompleteDataHook = ({ search }: IAssetNamesAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<
    assetNamesList,
    assetNamesCount,
    assetNamesList_assets_assets,
    AssetFilter
  >({
    GET_QUERY: GET_ASSET_NAMES,
    GET_COUNT_QUERY: GET_ASSET_NAMES_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};

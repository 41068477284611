import { gql } from '@apollo/client';
import { IAssetType } from './fragments';

export const GET_ASSET_TYPE_NAMES = gql`
  query assetTypeNames {
    assets_assetTypes {
      id
      name
    }
  }
`;

export const GET_ASSET_TYPES = gql`
  query assetTypes($filter: AssetTypeFilter = {}) {
    assets_assetTypes(filter: $filter) {
      ...IAssetType
    }
  }
  ${IAssetType}
`;

export const ASSET_TYPE_CREATE_MUTATION = gql`
  mutation assetTypeCreate(
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
    $parentAssetTypeId: ID
  ) {
    assets_assetTypeCreate(
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
      parentAssetTypeId: $parentAssetTypeId
    ) {
      id
    }
  }
`;

export const ASSET_TYPE_UPDATE_MUTATION = gql`
  mutation assetTypeUpdate(
    $id: ID!
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
    $parentAssetTypeId: ID
  ) {
    assets_assetTypeUpdate(
      id: $id
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
      parentAssetTypeId: $parentAssetTypeId
    ) {
      id
    }
  }
`;

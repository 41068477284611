import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { FC, ReactElement, useEffect } from 'react';
import { IHeadCell, TableHead } from './components/TableHead/TableHead';
import { forEach } from 'lodash';
import { SortOrder } from './components/HeaderCell/HeaderCell';
import { useCallback } from 'reactn';
import { FiltersRow } from './components/FiltersRow/FiltersRow';
import { IPagination, Pagination } from './components/Pagination/Paginations';
import cn from 'classnames';
import s from './style.module.scss';
import { DEFAULT_ROWS_PER_PAGE, TABLE_PAGINATION } from 'constants/config';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import {
  Reorder as ReorderIcon,
  ExpandLess as ExpandLessIcon,
  AccountTree as ExpandMoreIcon,
  East as ArrowRightIcon,
  West as ArrowLeftIcon,
} from '@mui/icons-material';

export interface ITableProps {
  headCells: IHeadCell[];
  dataCells: Array<any>;
  handleSelect?: (id: string) => boolean;
  totalItems: number;
  totalRecords?: number;
  loadPage?: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => boolean;
  initRowsPerPage?: number;
  hideFilters?: boolean;
  printView?: boolean;
  initOrder?: SortOrder;
  initOrderBy?: string;
  paginationProps?: Partial<IPagination>;
  hidePagination?: boolean;
  actionsDataCell?: {
    function: (
      data: any,
      row?: any,
      order?: SortOrder,
      sorted?: boolean
    ) => ReactElement<any, any> | null;
  };
  editDataRow?: {
    function: (data: any) => ReactElement<any, any> | null;
  };
  isEditRow?: (data: any) => boolean;
  draggable?: boolean;
  disableDrag?: boolean;
  onDragEnd?: (sourceIndex: number, destinationIndex: number) => void;

  isExpanded?: (data: any) => boolean | null;
  onExpandChange?: (id: string) => void;

  showArrowRight?: (data: any) => boolean;
  onArrowRight?: (id: string) => void;

  showArrowLeft?: (data: any) => boolean;
  onArrowLeft?: (id: string) => void;
  paginationSideComponent?: any;
  stickyHeader?: boolean;
  maxHeight?: string;
}

export const Table: FC<ITableProps> = ({
  loadPage,
  totalItems,
  totalRecords,
  handleSelect,
  dataCells,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
  hideFilters,
  hidePagination,
  printView,
  initOrder,
  initOrderBy,
  paginationProps,
  actionsDataCell,
  editDataRow,
  isEditRow,
  disableDrag,
  draggable,
  onDragEnd,
  isExpanded,
  onExpandChange,
  showArrowRight,
  onArrowRight,
  showArrowLeft,
  onArrowLeft,
  paginationSideComponent,
  stickyHeader,
  maxHeight,
}) => {
  const [order, setOrder] = React.useState<SortOrder>(initOrder || SortOrder.ASC);
  const [orderBy, setOrderBy] = React.useState<string | undefined>(initOrderBy);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initRowsPerPage || DEFAULT_ROWS_PER_PAGE);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        setPage(0);
      }
    },
    [onFilterChange]
  );

  const refresh = useCallback(() => {
    if (loadPage) {
      loadPage(order, orderBy, page, rowsPerPage);
    }
  }, [order, orderBy, page, rowsPerPage, loadPage]);

  useEffect(() => {
    refresh();
  }, [order, orderBy, page, rowsPerPage, refresh]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = orderBy === property && order === SortOrder.ASC;
      // toggle sort order
      setOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);

      const newOrderBy = orderBy === property && order === SortOrder.DESC ? undefined : property;
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      if (!handleSelect || !handleSelect(name)) newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDragEnd = useCallback(
    (result: DropResult, provided?: ResponderProvided) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      onDragEnd && onDragEnd(result.source!.index, result.destination!.index);
    },
    [onDragEnd]
  );

  const handleOnExpandChange = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onExpandChange && onExpandChange(id);
  };

  const handleOnArrowRight = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onArrowRight && onArrowRight(id);
  };

  const handleOnArrowLeft = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onArrowLeft && onArrowLeft(id);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight }}>
        <MuiTable stickyHeader={stickyHeader}>
          <MuiTableHead>
            <TableHead
              headCells={headCells}
              onRequestSort={handleRequestSort}
              orderBy={orderBy}
              order={order}
              printView={printView}
              showDraggColumn={draggable}
              stickyHeader={stickyHeader}
            ></TableHead>
            {!hideFilters ? (
              <FiltersRow
                headCells={headCells}
                filterOptions={filterOptions}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                showDraggColumn={draggable}
                stickyHeader={stickyHeader}
              ></FiltersRow>
            ) : undefined}
          </MuiTableHead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided: DroppableProvided) => (
                <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {dataCells?.map((row, index) => {
                    const isItemSelected = isSelected(row.id?.toString());
                    const columns: any[] = [];
                    let count = 0;
                    let colSpan = 0;

                    const isExpandedRow = isExpanded ? isExpanded(row) : null;
                    const toShowArrowRight = showArrowRight ? showArrowRight(row) : null;
                    const toShowArrowLeft = showArrowLeft ? showArrowLeft(row) : null;

                    if (isEditRow && isEditRow(row)) {
                      columns.push(
                        <TableCell
                          component="th"
                          id={`tc-${index}`}
                          scope="row"
                          padding="none"
                          key={'EditCell'}
                          colSpan={headCells.length - 1 + (draggable ? 1 : 0)}
                        >
                          {editDataRow?.function ? (
                            <editDataRow.function data={row}></editDataRow.function>
                          ) : undefined}
                        </TableCell>
                      );
                      columns.push(
                        <TableCell
                          component="th"
                          id={`tc-${index}`}
                          scope="row"
                          padding="none"
                          key={'ActionsCell'}
                          colSpan={headCells.length - 1}
                        >
                          {actionsDataCell?.function ? (
                            <actionsDataCell.function data={row}></actionsDataCell.function>
                          ) : undefined}
                        </TableCell>
                      );
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {columns}
                        </TableRow>
                      );
                    } else {
                      forEach(headCells, (headCell) => {
                        count++;
                        if (colSpan > 1) {
                          colSpan--;
                          return undefined;
                        }
                        if (headCell.colSpan && headCell.colSpan > 1) {
                          colSpan = headCell.colSpan;
                        }
                        const cellClassName = cn({
                          [s.nowrap]: headCell.dataCell?.wrap === 'nowrap',
                          [s.box]: headCell.dataCell?.wrap === 'box',
                          [s.paddingHalf]: headCell.dataCell?.padding === 'half',
                          [s.noPaddingLeft]:
                            headCell.dataCell?.connected &&
                            ['left', 'middle'].includes(headCell.dataCell.connected),
                          [s.noPaddingRight]:
                            headCell.dataCell?.connected &&
                            ['middle', 'right'].includes(headCell.dataCell.connected),
                          [s.shrinkToContent]: headCell.dataCell?.shrink === 'content',
                        });

                        var cellContent = (
                          <>
                            {actionsDataCell?.function && headCell.isActionsCell ? (
                              <actionsDataCell.function
                                data={row[headCell.id]}
                                row={row}
                                order={order}
                                sorted={orderBy === headCell.sortBy || orderBy === headCell.id}
                              ></actionsDataCell.function>
                            ) : undefined}
                            {headCell.transformDataCell ? (
                              <headCell.transformDataCell
                                data={row[headCell.id]}
                                row={row}
                                order={order}
                                sorted={orderBy === headCell.sortBy || orderBy === headCell.id}
                              ></headCell.transformDataCell>
                            ) : headCell.transformFunction ? (
                              headCell.transformFunction(
                                row[headCell.id],
                                row,
                                order,
                                orderBy === headCell.sortBy || orderBy === headCell.id
                              )
                            ) : (
                              row[headCell.id]
                            )}
                          </>
                        );

                        if (headCell.hierarchy) {
                          cellContent = (
                            <div
                              style={{
                                display: 'flex',
                                whiteSpace: 'nowrap',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  paddingLeft: (20 * row?.level).toString() + 'px',
                                }}
                              ></div>
                              <div>{cellContent}</div>
                              {!!isExpandedRow ? (
                                <div
                                  style={{
                                    paddingLeft: '13px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={(event: any) =>
                                    handleOnExpandChange(event, row.id.toString())
                                  }
                                >
                                  <ExpandLessIcon fontSize="small" style={{ opacity: '.7' }} />
                                </div>
                              ) : undefined}
                              {isExpandedRow === false ? (
                                <div
                                  style={{
                                    paddingLeft: '15px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={(event: any) =>
                                    handleOnExpandChange(event, row.id.toString())
                                  }
                                >
                                  <ExpandMoreIcon fontSize="small" style={{ opacity: '.7' }} />
                                </div>
                              ) : undefined}
                            </div>
                          );
                        }

                        if (headCell.hierarchyActions) {
                          cellContent = (
                            <div
                              style={{
                                display: 'flex',
                                whiteSpace: 'nowrap',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {toShowArrowLeft ? (
                                <div
                                  style={{
                                    padding: '0 4px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(event: any) =>
                                    handleOnArrowLeft(event, row.id.toString())
                                  }
                                >
                                  <ArrowLeftIcon fontSize="small" style={{ opacity: '.7' }} />
                                </div>
                              ) : undefined}
                              <div>{cellContent}</div>
                              {toShowArrowRight ? (
                                <div
                                  style={{
                                    padding: '0 4px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(event: any) =>
                                    handleOnArrowRight(event, row.id.toString())
                                  }
                                >
                                  <ArrowRightIcon fontSize="small" style={{ opacity: '.7' }} />
                                </div>
                              ) : undefined}
                            </div>
                          );
                        }

                        if (count === 1)
                          columns.push(
                            <TableCell
                              component="th"
                              id={`tc-${index}`}
                              scope="row"
                              padding="none"
                              key={count.toString() + '_' + headCell.id}
                              align={headCell.dataCell?.align}
                              colSpan={headCell.colSpan}
                              className={cellClassName}
                              title={
                                headCell.dataCell?.wrap === 'box'
                                  ? headCell.transformFunction
                                    ? headCell.transformFunction(
                                        row[headCell.id],
                                        row,
                                        order,
                                        orderBy === headCell.sortBy || orderBy === headCell.id
                                      )
                                    : row[headCell.id]
                                  : undefined
                              }
                            >
                              {cellContent}
                            </TableCell>
                          );
                        else {
                          columns.push(
                            <TableCell
                              align={headCell.dataCell?.align || 'left'}
                              key={count.toString() + '_' + headCell.id}
                              colSpan={headCell.colSpan}
                              className={cellClassName}
                              title={
                                headCell.dataCell?.wrap === 'box'
                                  ? headCell.transformFunction
                                    ? headCell.transformFunction(
                                        row[headCell.id],
                                        row,
                                        order,
                                        orderBy === headCell.sortBy || orderBy === headCell.id
                                      )
                                    : row[headCell.id]
                                  : undefined
                              }
                            >
                              {cellContent}
                            </TableCell>
                          );
                        }
                      });
                    }
                    return (
                      <Draggable key={row.id} draggableId={row.id} index={index}>
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              onClick={debounce(
                                (event: any) => handleClick(event, row.id.toString()),
                                DEBOUNCE_TIMEOUT
                              )}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={{
                                cursor: 'pointer',
                                ...draggableProvided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? 'rgba(245,245,245, 0.75)'
                                  : undefined,
                              }}
                            >
                              {[
                                draggable ? (
                                  <TableCell
                                    align="left"
                                    style={{ width: '20px', padding: '0 16px' }}
                                    key={'Draggable'}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        whiteSpace: 'nowrap',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div style={{ padding: '0 16px 0 0' }}>
                                        {Number.isInteger(row.index)
                                          ? row.index + 1
                                          : index + page * rowsPerPage + 1}
                                        .
                                      </div>
                                      <div
                                        {...(!disableDrag
                                          ? draggableProvided.dragHandleProps
                                          : undefined)}
                                      >
                                        <ReorderIcon
                                          fontSize="small"
                                          style={disableDrag ? { opacity: '.3' } : undefined}
                                        />
                                      </div>
                                    </div>
                                  </TableCell>
                                ) : undefined,
                                ...columns,
                              ]}
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </MuiTable>
      </TableContainer>
      {!hidePagination ? (
        <Pagination
          rowsPerPageOptions={TABLE_PAGINATION}
          count={totalItems}
          totalCount={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          leftSideComponent={paginationSideComponent}
          {...paginationProps}
        ></Pagination>
      ) : undefined}
    </div>
  );
};

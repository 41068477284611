import { omit } from 'lodash';

export interface IGetUpdatesProps {
  source: any;
  updates: any;
  requiredKeys: string[];
  baseOmitKeys: string[];
  compareFunctions: { [id: string]: (value: any) => boolean };
}

const checkFunction = (
  key: string,
  source: any,
  updates: any,
  compareFunctions: { [id: string]: (value: any) => boolean },
  compareFunctionsKeys: string[],
  sourceKeys: string[]
) => {
  if (
    Array.isArray(updates[key as keyof typeof updates]) &&
    !updates[key as keyof typeof updates].length
  ) {
    return true;
  }

  if (
    sourceKeys.includes(key) &&
    updates[key as keyof typeof updates] === source[key as keyof typeof source]
  ) {
    return true;
  }

  if (typeof source[key as keyof typeof source] == 'boolean') {
    if (!source[key as keyof typeof source] === !updates[key as keyof typeof updates]) {
      return true;
    }
  }

  if (
    compareFunctionsKeys.includes(key) &&
    compareFunctions[key as keyof typeof compareFunctions](updates[key as keyof typeof updates])
  ) {
    return true;
  }
  return false;
};

export const getUpdates = ({
  source,
  updates,
  requiredKeys,
  baseOmitKeys,
  compareFunctions,
}: IGetUpdatesProps) => {
  const omitKeys = [...baseOmitKeys];
  let updateFound = false;
  if (source) {
    const prepareDateKeys = Object.keys(updates);
    const compareFunctionsKeys = Object.keys(compareFunctions);
    const sourceKeys = Object.keys(source);

    prepareDateKeys.forEach((key) => {
      if (requiredKeys.includes(key)) {
        return;
      }
      if (checkFunction(key, source, updates, compareFunctions, compareFunctionsKeys, sourceKeys)) {
        omitKeys.push(key);
      }
    });

    updateFound = !!prepareDateKeys.find(
      (key) =>
        !checkFunction(key, source, updates, compareFunctions, compareFunctionsKeys, sourceKeys)
    );
  }

  return { saveData: omitKeys.length ? omit(updates, omitKeys) : updates, updateFound };
};

import AlertDialog from 'components/AlertDialog';
import { FC, useMemo } from 'react';
import { useFormValidationReportContext } from './FormValidationReportContext';

export interface IFormValidationReportProps {
  errors: any[];
}

export const FormValidationReport: FC<IFormValidationReportProps> = ({ errors }) => {
  const { showValidationResult, closeValidationResult } = useFormValidationReportContext();

  const validationSummary = useMemo(() => {
    const summary: any[] = [];

    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((errorKey) => {
        if (!!errors[errorKey as keyof typeof errors]) {
          summary.push(errors[errorKey as keyof typeof errors].join('. '));
        }
      });
    }

    return summary;
  }, [errors]);

  const alertMessage = useMemo(() => {
    if (!showValidationResult) {
      return undefined;
    }
    return (
      <div>
        Please make sure following fields are filled in correctly:
        <ul>
          {validationSummary.map((err) => (
            <li>{Array.isArray(err) ? err.join('. ') : err}</li>
          ))}
        </ul>
      </div>
    );
  }, [showValidationResult, validationSummary]);

  return (
    <>
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={showValidationResult}
        onClose={() => {
          closeValidationResult && closeValidationResult();
        }}
      />
    </>
  );
};

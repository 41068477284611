export interface IHierarchySortItem {
  id: string;
  parent: string | null | undefined;
  order: number;
}

export interface IHierarchySortResultItem {
  id: string;
  parent: string | null | undefined;
  order: number;
  level: number;
}

function hierarchySortFunc(a: IHierarchySortItem, b: IHierarchySortItem) {
  return a.order - b.order;
}

const recursiveHierarchySort = (
  hashArr: { [id: string]: IHierarchySortItem[] },
  key: string,
  result: IHierarchySortResultItem[],
  level: number
) => {
  if (!hashArr[key]) return;
  var arr = hashArr[key].sort(hierarchySortFunc);
  for (var i = 0; i < arr.length; i++) {
    result.push({ ...arr[i], level });
    recursiveHierarchySort(hashArr, arr[i].id, result, level + 1);
  }
  return result;
};

export const hierarchySort = (arr: IHierarchySortItem[]) => {
  const hashArr: { [id: string]: any } = {};
  for (var i = 0; i < arr.length; i++) {
    const key = arr[i].parent ? (arr[i].parent as string) : '0';
    if (!hashArr[key]) hashArr[key] = [];
    hashArr[key].push(arr[i]);
  }

  const result: IHierarchySortResultItem[] = [];
  recursiveHierarchySort(hashArr, '0', result, 0);
  return result;
};

import { gql } from '@apollo/client';

export const IUserRef = gql`
  fragment IUserRef on User {
    id
    oid
    email
    name
    isActive
  }
`;

export const IRoomRef = gql`
  fragment IRoomRef on AssetRoom {
    id
    name
    isSelectable
  }
`;

export const IAssetType = gql`
  fragment IAssetType on AssetType {
    id
    name
    description
    order
    isSelectable
    parent {
      id
      name
    }
    createdAt
    updatedAt
  }
`;
export const IAssetStatus = gql`
  fragment IAssetStatus on AssetStatus {
    id
    name
    description
    order
    isSelectable
    createdAt
    updatedAt
  }
`;

export const IAssetLocation = gql`
  fragment IAssetLocation on AssetLocation {
    id
    name
    description
    order
    isSelectable
    createdAt
    updatedAt
  }
`;

export const IAssetRoom = gql`
  fragment IAssetRoom on AssetRoom {
    id
    name
    description
    order
    isSelectable
    createdAt
    updatedAt
  }
`;

export const IAssetAssignment = gql`
  fragment IAssetAssignment on AssetAssignment {
    id
    assignmentType
    isReturned
    startDate
    endDate
    createdAt
    updatedAt
    contractorName
    contractorEmail
    user {
      ...IUserRef
    }
    room {
      ...IRoomRef
    }
  }
  ${IUserRef}
  ${IRoomRef}
`;
export const IAssetEvent = gql`
  fragment IAssetEvent on AssetEvent {
    eventType
    payload
    createdAt
    updatedAt
    user {
      ...IUserRef
    }
  }
  ${IUserRef}
`;

export const IAsset = gql`
  fragment IAsset on Asset {
    id
    name

    isLoaner
    isCurrentlyAssigned

    brand
    model
    serialNumber
    serviceTag
    cpu
    ram
    storage
    screenSize
    purchaseDate
    warrantyExpirationDate
    serviceProvider
    ipAddress
    comment

    factoryIdentifier
    expressCode
    dollarValue
    office

    createdAt
    updatedAt
    type {
      ...IAssetType
    }
    status {
      ...IAssetStatus
    }
    location {
      ...IAssetLocation
    }
    currentAssignment {
      ...IAssetAssignment
    }
    assignments {
      ...IAssetAssignment
    }
    events {
      ...IAssetEvent
    }
    createdBy {
      ...IUserRef
    }
    updatedBy {
      ...IUserRef
    }

    isCanceled
  }
  ${IAssetType}
  ${IAssetStatus}
  ${IAssetLocation}
  ${IAssetAssignment}
  ${IAssetEvent}
  ${IUserRef}
`;

export const IAssetListItem = gql`
  fragment IAssetListItem on Asset {
    id
    name

    isLoaner
    isCurrentlyAssigned

    brand
    model
    serialNumber
    serviceTag
    cpu
    ram
    storage
    screenSize
    purchaseDate
    warrantyExpirationDate
    serviceProvider
    ipAddress
    comment

    factoryIdentifier
    expressCode
    dollarValue
    office

    createdAt
    updatedAt
    type {
      ...IAssetType
    }
    status {
      ...IAssetStatus
    }
    location {
      ...IAssetLocation
    }
    currentAssignment {
      ...IAssetAssignment
    }
    lastAssignment {
      ...IAssetAssignment
    }
    createdBy {
      ...IUserRef
    }
    updatedBy {
      ...IUserRef
    }

    isCanceled
  }
  ${IAssetType}
  ${IAssetStatus}
  ${IAssetLocation}
  ${IAssetAssignment}
  ${IUserRef}
`;

// #region React Core

import React, { FC, useState, useEffect } from 'react';

// #endregion

// #region Material-UI Components

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Box,
  Container,
  Grid,
  TextareaAutosize,
} from '@mui/material';

import { uniqueId } from 'lodash';

// #endregion

// #region Component definition interface

import { MultiButtonDialogProps } from './interfaces';

// #endregion

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { IDialog } from 'components/ConfirmationDialog/interfaces';

//
// Displays generic Confirmation Dialog with title and message
// with handler when Ok or Cancel button is clicked
//
export const MultiButtonDialog: FC<MultiButtonDialogProps> = ({
  open,
  onClose,
  confirmButtonProps,
  reverseColors,
  notes,
  confirmButtons,
  ...props
}) => {
  // #region State

  const [dialogOpen, setOpen] = useState(false);

  // #endregion

  // #region Effects

  useEffect((): void => {
    setOpen(open);
  }, [open]);

  // #endregion

  // #region Form Methods

  const closeHandler = (): void => {
    setOpen(false);
    onClose && onClose();
  };

  const confirmHandler = (cb: any): void => {
    setOpen(false);
    cb();
  };

  // #endregion

  const setDialogContent = (dialogProps: IDialog): JSX.Element => (
    <div key={dialogProps.title || uniqueId()}>
      <DialogTitle>
        <Typography>
          <span style={{ fontWeight: 800, fontSize: '0.9rem' }}>
            <div dangerouslySetInnerHTML={{ __html: dialogProps?.title ?? 'Confirmation' }} />
          </span>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText style={{ color: '#444', fontSize: '0.9rem', ...dialogProps?.style }}>
          <div dangerouslySetInnerHTML={{ __html: dialogProps?.message ?? '' }} />
          {notes ? notes : undefined}
          {!!dialogProps?.setComment ? (
            <>
              <Box marginTop={1}></Box>
              <TextareaAutosize
                color="grey"
                style={{
                  width: '95%',
                  minHeight: '1rem',
                  padding: 10,
                  borderRadius: 4,
                  overflow: 'auto',
                  border: '1px #ccc solid',
                }}
                aria-label="minimum height"
                minRows={4}
                placeholder="Write comment (optional)..."
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  dialogProps.setComment!(event.target.value);
                }}
                value={dialogProps?.comment}
                className="MuiInputBase-input"
              />
            </>
          ) : undefined}
        </DialogContentText>
      </DialogContent>
    </div>
  );

  return (
    <Dialog maxWidth="sm" open={dialogOpen}>
      <Container>
        {props?.dialogs
          ? props?.dialogs.map(setDialogContent)
          : setDialogContent({
              title: props?.title,
              message: props?.message ?? '',
              comment: props?.comment,
              setComment: props?.setComment,
              style: props?.style,
            })}

        <Box marginBottom={1} />
        <DialogActions>
          <Grid style={{ margin: 'auto', justifyContent: 'center' }} container spacing={3}>
            <Grid item>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: 'center',
                }}
              >
                {confirmButtons.map((confirmButton) => (
                  <Grid item key={confirmButton.title}>
                    <Button
                      key={confirmButton.title}
                      color={!reverseColors ? 'primary' : 'secondary'}
                      variant="contained"
                      size="medium"
                      onClick={debounce((): void => {
                        confirmHandler(confirmButton.onClick);
                      }, DEBOUNCE_TIMEOUT)}
                      {...confirmButtonProps}
                      style={{ fontSize: '0.85rem', ...confirmButtonProps?.style }}
                    >
                      {confirmButton.title}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <Button
                color={!reverseColors ? 'secondary' : 'primary'}
                variant="contained"
                size="medium"
                onClick={debounce((): void => {
                  closeHandler();
                }, DEBOUNCE_TIMEOUT)}
                style={{ fontSize: '0.85rem' }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <Box marginBottom={2} />
      </Container>
    </Dialog>
  );
};

import { AppLogin } from 'pages/Login';
import { NoAccess } from 'pages/NoAccess/NoAccess';
import { paths } from './paths';

import { Pages } from 'pages/Pages';
import { AssetsPage } from 'pages/Assets';
import { AssetPage } from 'pages/Asset';
import { AssetDetails } from 'pages/Asset/components/AssetDetails/AssetDetails';
import { AssetFileManagement } from 'pages/Asset/components/AssetFileManagement/AssetFileManagement';
import { AssetEvents } from 'pages/Asset/components/AssetEvents/AssetEvents';
import { NewAssetDetails } from 'pages/NewAsset/components/NewAssetDetails/AssetDetails';
import { NewAssetPage } from 'pages/NewAsset';
import { ReportAssetsPage } from 'pages/ReportAssets';

export const getAssetsSubRoute = (base: string) => {
  return [
    {
      path: base + paths.client.NEW_ASSET_SUB_PAGE,
      exact: false,
      component: NewAssetPage,
      private: true,
      guest: false,
      routes: [
        {
          path: base + paths.client.NEW_ASSET_SUB_PAGE,
          exact: true,
          component: NewAssetDetails,
          private: true,
          guest: false,
        },
      ],
    },
    {
      path: base + paths.client.ASSET_PAGE_SUB_PAGE,
      exact: false,
      component: AssetPage,
      private: true,
      guest: false,
      routes: [
        {
          path: base + paths.client.ASSET_DETAILS_SUB_PAGE,
          exact: true,
          component: AssetDetails,
          private: true,
          guest: false,
        },
        {
          path: base + paths.client.ASSET_FM_SUB_PAGE,
          exact: true,
          component: AssetFileManagement,
          private: true,
          guest: false,
        },
        {
          path: base + paths.client.ASSET_EVENTS_SUB_PAGE,
          exact: true,
          component: AssetEvents,
          private: true,
          guest: false,
        },
      ],
    },
  ];
};

export const routes = [
  {
    path: paths.client.LOGIN,
    exact: true,
    component: AppLogin,
    guest: true,
  },
  {
    path: paths.client.NOACCESS,
    exact: true,
    component: NoAccess,
    guest: true,
  },
  ...getAssetsSubRoute(''),
  {
    path: paths.client.BASE,
    exact: false,
    component: Pages,
    private: true,
    guest: false,
    routes: [
      ...getAssetsSubRoute(paths.client.REPORTS),
      {
        path: paths.client.REPORTS,
        exact: true,
        component: ReportAssetsPage,
        private: true,
        guest: false,
      },
      {
        path: paths.client.BASE,
        exact: true,
        component: AssetsPage,
        private: true,
        guest: false,
      },
    ],
  },
];

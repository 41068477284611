import React from 'react';
import { Snackbar as MuiSnackbar, Alert as MuiAlert, AlertProps, Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

type severityType = 'error' | 'warning' | 'info' | 'success';

interface CustomizedSnackbarsProps {
  severity: severityType;
  text: string;
  onClose?: any;
  id: string;
  autoHideDuration?: number;
}

export function Snackbar({
  severity,
  text,
  onClose,
  id,
  autoHideDuration,
}: CustomizedSnackbarsProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (onClose) {
      onClose(id);
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <MuiSnackbar
        open={open}
        autoHideDuration={autoHideDuration || 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div>
          <Alert onClose={handleClose} severity={severity}>
            {text}
          </Alert>
        </div>
      </MuiSnackbar>
    </div>
  );
}

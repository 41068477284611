import { IHeadCell } from 'components/ui/TableDnd/components/TableHead/TableHead';
import { AssetSortableColumn } from 'graphql/ams/types/graphql-types';
import { TransformCurrencyUS } from 'utils/TableTransformFunctions';

export const exportAssetsTableHead: IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Asset Name',
    sortable: true,
    sortBy: AssetSortableColumn.NAME,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'isLoaner',
    numeric: false,
    disablePadding: false,
    label: 'Is Loaner',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },
  {
    id: 'currentAssignment',
    numeric: false,
    disablePadding: false,
    label: 'Assigned To',
    sortable: true,
    sortBy: AssetSortableColumn.CURRENT_ASSIGNMENT_USER_NAME,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (assignment) => (assignment?.user ? assignment.user.name : '--'),
  },
  {
    id: 'currentAssignmentEmail',
    numeric: false,
    disablePadding: false,
    label: `Assignee's Email`,
    sortable: true,
    sortBy: AssetSortableColumn.CURRENT_ASSIGNEE_EMAIL,
    filter: 'text-dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (_, row) => {
      if (row?.currentAssignment?.user?.email) {
        return row.currentAssignment.user?.email;
      }
      if (row?.currentAssignment?.contractorEmail) {
        return row?.currentAssignment?.contractorEmail;
      }
      return '--';
    },
  },
  {
    id: 'currentAssignment.startDate',
    numeric: false,
    disablePadding: false,
    label: 'Assigned from Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data, row) => {
      return row?.currentAssignment?.startDate
        ? new Date(row?.currentAssignment?.startDate).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--';
    },
  },
  {
    id: 'currentAssignment.endDate',
    numeric: false,
    disablePadding: false,
    label: 'Assigned to Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data, row) => {
      return row?.currentAssignment?.endDate
        ? new Date(row?.currentAssignment?.endDate).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--';
    },
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Asset Type',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Asset Status',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data?.name || '--',
  },
  {
    id: 'office',
    numeric: false,
    disablePadding: false,
    label: 'Office',
    sortable: true,
    sortBy: AssetSortableColumn.OFFICE,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: 'Brand',
    sortable: true,
    sortBy: AssetSortableColumn.BRAND,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: false,
    label: 'Model',
    sortable: true,
    sortBy: AssetSortableColumn.MODEL,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'factoryIdentifier',
    numeric: false,
    disablePadding: false,
    label: 'Serial No/Service Tag/IMEI',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'expressCode',
    numeric: false,
    disablePadding: false,
    label: 'Express Code',
    sortable: true,
    sortBy: AssetSortableColumn.SERVICE_TAG,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'cpu',
    numeric: false,
    disablePadding: false,
    label: 'CPU',
    sortable: true,
    sortBy: AssetSortableColumn.CPU,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'ram',
    numeric: false,
    disablePadding: false,
    label: 'RAM',
    sortable: true,
    sortBy: AssetSortableColumn.RAM,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'storage',
    numeric: false,
    disablePadding: false,
    label: 'Storage Size',
    sortable: true,
    sortBy: AssetSortableColumn.STORAGE,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'screenSize',
    numeric: false,
    disablePadding: false,
    label: 'Screen Size',
    sortable: true,
    sortBy: AssetSortableColumn.SCREEN_SIZE,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'serviceProvider',
    numeric: false,
    disablePadding: false,
    label: 'Service Provider',
    sortable: true,
    sortBy: AssetSortableColumn.SERVICE_PROVIDER,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'dollarValue',
    numeric: true,
    disablePadding: false,
    label: 'Value',
    sortable: true,
    sortBy: AssetSortableColumn.DOLLAR_VALUE,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: any) => (data ? data : '-- '),
    transformDataCell: TransformCurrencyUS,
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'purchaseDate',
    numeric: false,
    disablePadding: false,
    label: 'Purchase Date',
    sortable: true,
    sortBy: AssetSortableColumn.PURCHASE_DATE,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'warrantyExpirationDate',
    numeric: false,
    disablePadding: false,
    label: 'Warranty Expiration Date',
    sortable: true,
    sortBy: AssetSortableColumn.WARRANTY_EXPIRATION_DATE,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'comment',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    sortable: true,
    sortBy: AssetSortableColumn.COMMENT,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    sortable: true,
    sortBy: AssetSortableColumn.CREATED_AT,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified Date',
    sortable: true,
    sortBy: AssetSortableColumn.UPDATED_AT,
    filter: 'date',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: false,
    label: 'Created By',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) => date.name,
  },
  {
    id: 'updatedBy',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified By',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date) => date.name,
  },
  {
    id: 'isCanceled',
    numeric: false,
    disablePadding: false,
    label: 'Is Canceled',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },
];

import { IHeadCell } from 'components/ui/TableDnd/components/TableHead/TableHead';
import { assignedTypeOptions } from './selectOptions';

export const assignmentsTableHead: IHeadCell[] = [
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Assignee',
    sortable: false,
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data, row: any | undefined) =>
      row?.user?.name || row?.room?.name || row?.contractorName,
  },
  {
    id: 'assignmentType',
    numeric: false,
    disablePadding: false,
    label: 'Assignment Type',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (type: any) =>
      assignedTypeOptions.find((option) => option.id === type)?.name || '--',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date: any) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'End Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date: any) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '--',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Create Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date: any) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Change Date',
    sortable: false,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (date: any) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
];

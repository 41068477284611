import React, { FC, useCallback, useEffect, useState } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import { Routes, Header, Theme } from './components';
import { client } from './graphql';

import './globalState';
import './index.scss';
import { Footer } from './components/Footer';

import { ManagedUIContext } from 'contexts/UiContext';
import { Layout } from 'components/Layout/Layout';

import { Provider as ChangedDialogContextProvider } from 'components/FormChangedDialog/FormChangedDialogContext';

import { FormChangedDialog } from 'components/FormChangedDialog/FormChangedDialog';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { getAccessToken } from 'authorization/auth-utils-msal2';
import { MaintenanceAlert } from 'components/Maintenance/MaintenenceAlert';

export const AppMsal: FC<any> = () => {
  const { inProgress } = useMsal();

  const [token, setToken] = useState<string | undefined>();

  const refreshToken = useCallback(async () => {
    const result = await getAccessToken();
    if (result) {
      sessionStorage?.setItem('msal.idtoken', result);
    }
    setToken(result ? result : undefined);
  }, []);

  useEffect(() => {
    if (!token) {
      sessionStorage?.removeItem('msal.idtoken');
    }
  }, [token]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      // no need to await
      refreshToken();
    }
  }, [refreshToken, inProgress]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ChangedDialogContextProvider>
        <FormChangedDialog></FormChangedDialog>
        <ApolloProvider client={client}>
          <Theme>
            <Router>
              <Header />
              <ManagedUIContext>
                <AuthenticatedTemplate>
                  {token ? (
                    <Layout>
                      <MaintenanceAlert />
                      <Routes />
                    </Layout>
                  ) : undefined}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Layout>
                    <Routes />
                  </Layout>
                </UnauthenticatedTemplate>
              </ManagedUIContext>
              <Footer />
            </Router>
          </Theme>
        </ApolloProvider>
      </ChangedDialogContextProvider>
    </LocalizationProvider>
  );
};

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { useTableStorage } from 'components/ui/TableDnd/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { useUI } from 'contexts/UiContext';
import {
  ASSET_LOCATION_CREATE_MUTATION,
  ASSET_LOCATION_UPDATE_MUTATION,
  GET_ASSET_LOCATIONS,
} from 'graphql/ams/assetLocations';

import { assetLocations } from 'graphql/ams/types/assetLocations';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { tryUpdateProcedure } from 'utils/apollo';
import { arrayMoveItem } from 'utils/array';
import { v4 as uuidv4 } from 'uuid';

export interface IAssetLocationesFilter {}
export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: IAssetLocationesFilter;
}

export const useAssetLocation = () => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const [createLocationMutation] = useMutation(ASSET_LOCATION_CREATE_MUTATION);
  const [updateLocationMutation] = useMutation(ASSET_LOCATION_UPDATE_MUTATION);

  const [locationes, setLocationes] = useState<any[]>([]);

  const [reordered, setReordered] = useState(false);

  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  const { data, loading, error } = useQuery<assetLocations>(GET_ASSET_LOCATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { setItem, getItem } = useTableStorage({
    key: 'AssetLocationesList',
  });

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {},
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const visibleLocationes = useMemo(() => {
    const { rowsPerPage, page } = pageLoadParams;
    const from = page * rowsPerPage;
    const toNotIncluded = from + rowsPerPage;
    return locationes.slice(from, toNotIncluded);
  }, [pageLoadParams, locationes]);

  const getAssetLocationes = useCallback(() => {
    const { page, rowsPerPage } = pageLoadParams;
    let index = page * rowsPerPage;
    return (
      data?.assets_assetLocations
        .sort((a, b) => a.order - b.order)
        .map((location) => {
          index++;
          return { ...location, orderNo: index };
        }) || []
    );
  }, [data, pageLoadParams]);

  const getAssetLocationesRef = useRef(getAssetLocationes);
  useEffect(() => {
    getAssetLocationesRef.current = getAssetLocationes;
  }, [getAssetLocationes]);

  useEffect(() => {
    if (data && !loading) {
      setLocationes(getAssetLocationesRef.current());
    }
  }, [data, loading, getAssetLocationesRef]);

  const totalItems = useMemo(() => {
    return locationes?.length || 0;
  }, [locationes]);

  const deleteItem = useCallback(
    (id: string) => {
      const found = locationes.find((location: any) => location.id === id);
      if (found && !found.isNew) {
        setDeletedItems((old: string[]) => [...old, id]);
      }

      const index = locationes.findIndex((location: any) => location.id === id);
      const newLocationes = [...locationes];
      newLocationes.splice(index, 1);
      setLocationes(newLocationes);
    },
    [locationes]
  );

  const removeItem = useCallback(
    (id: string) => {
      const index = locationes.findIndex((location: any) => location.id === id);
      const newLocationes = [...locationes];
      newLocationes.splice(index, 1);
      setLocationes(newLocationes);
    },
    [locationes]
  );

  const updateItem = useCallback((item: any) => {
    const updateValues = { ...item };
    setLocationes((locationes: any[]) => {
      const newLocationes = [...locationes];

      const foundItemIndex = newLocationes.findIndex(
        (location: any) => location.id === updateValues.id
      );
      const foundItem = newLocationes[foundItemIndex];
      Object.assign(foundItem, { ...updateValues, changed: true });

      const { orderNo } = updateValues;
      const newItemIndex = orderNo - 1;
      if (newItemIndex !== foundItemIndex) {
        return arrayMoveItem(newLocationes, foundItemIndex, newItemIndex);
      } else {
        return newLocationes;
      }
    });
  }, []);

  const onSubmit = useCallback(async () => {
    let orderNo = 1;
    for (const location of locationes) {
      if (location.order !== orderNo) {
        location.order = orderNo;
        location.changed = true;
      }
      orderNo++;
    }

    const createItems = locationes.filter((location: any) => !!location.isNew);
    const updateItems = locationes.filter((location: any) => !location.isNew && !!location.changed);

    let foundError = false;
    let allErrors: string[] = [];
    let lastResult = undefined;

    for (const item of createItems) {
      const saveData = pick(item, ['name', 'description', 'order', 'isSelectable']);
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createLocationMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.isNew = false;
        item.changed = false;
        item.id = result?.assets_assetLocationCreate?.id;
      }
    }

    for (const item of updateItems) {
      const saveData = pick(item, ['id', 'name', 'description', 'order', 'isSelectable']);
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          updateLocationMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.changed = false;
      }
    }

    if (foundError) {
      addSnackbar!({
        text: allErrors?.join(' '),
        severity: 'error',
      });
    } else {
      if (lastResult) {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
      } else {
        addSnackbar!({
          text: 'Nothing to Save',
          severity: 'info',
        });
      }
      await client.resetStore();
    }
    if (deletedItems.length) {
      addSnackbar!({
        text: 'Location deletion is not supported',
        severity: 'error',
      });
    }

    if (!foundError) {
      setReordered(() => false);
    }
    setLocationes((old) => [...old]);
  }, [
    locationes,
    addSnackbar,
    createLocationMutation,
    updateLocationMutation,
    deletedItems.length,
    client,
  ]);

  const hasChanges = useMemo(() => {
    return (
      reordered ||
      deletedItems.length ||
      !!locationes.find((location: any) => location.isNew || !!location.changed)
    );
  }, [reordered, deletedItems, locationes]);

  const addNew = useCallback(() => {
    const newLocationItem: any = {
      id: uuidv4(),
      isSelectable: true,
      order: 1,
      name: '',
      description: '',
      isNew: true,
      orderNo: 1,
    };
    setLocationes((old: any[]) => {
      return [newLocationItem, ...old];
    });
    return newLocationItem;
  }, []);

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      setReordered(true);
      setLocationes((old: any[]) => {
        const { page, rowsPerPage } = pageLoadParams;
        const offset = page * rowsPerPage;
        return arrayMoveItem(old, offset + fromIndex, offset + toIndex);
      });
    },
    [pageLoadParams]
  );

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onFilterChange = useCallback(() => false, []);

  return {
    locationes: visibleLocationes,
    loading,
    error,
    totalItems,
    deleteItem,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    removeItem,
    moveItem,
    loadPage,
    onFilterChange,
  };
};

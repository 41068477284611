import { paths } from 'constants/paths';

export const assetTabs = [
  {
    label: 'Asset Details',
    value: ['details'],
    path: paths.client.ASSET_DETAILS_SUB_PAGE,
  },
  //   {
  //     label: 'File Management',
  //     value: ['file-management'],
  //     path: paths.client.ASSET_FM,
  //   },
  {
    label: 'Event Log',
    value: ['events-log'],
    path: paths.client.ASSET_EVENTS_SUB_PAGE,
  },
];

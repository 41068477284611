import { gql } from '@apollo/client';

export const ASSET_ASSIGNMENT_CREATE_MUTATION = gql`
  mutation assetAssignmentCreate(
    $assetId: ID!
    $userId: ID
    $roomId: ID
    $contractorName: String
    $contractorEmail: String
    $assignmentType: AssetAssignmentType!
    $isReturned: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    assets_assetAssignmentCreate(
      assetId: $assetId
      userId: $userId
      roomId: $roomId
      contractorName: $contractorName
      contractorEmail: $contractorEmail
      assignmentType: $assignmentType
      isReturned: $isReturned
      startDate: $startDate
      endDate: $endDate
    ) {
      id
    }
  }
`;

export const ASSET_ASSIGNMENT_UPDATE_MUTATION = gql`
  mutation assetAssignmentUpdate(
    $id: ID!
    $assignmentType: AssetAssignmentType!
    $isReturned: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    assets_assetAssignmentUpdate(
      id: $id
      assignmentType: $assignmentType
      isReturned: $isReturned
      startDate: $startDate
      endDate: $endDate
    ) {
      id
    }
  }
`;

import { gql } from '@apollo/client';
import { IAsset, IAssetListItem } from './fragments';

export const GET_ASSET = gql`
  query getAsset($id: ID!) {
    assets_asset(id: $id) {
      ...IAsset
    }
  }
  ${IAsset}
`;

export const GET_ASSET_COUNT = gql`
  query assetCount($filter: AssetFilter) {
    assets_assetCount(filter: $filter)
  }
`;

export const GET_ASSETS = gql`
  query assets(
    $sort: [AssetSort!] = []
    $filter: AssetFilter = {}
    $take: Int = 100
    $skip: Int = 0
  ) {
    assets_assets(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IAssetListItem
    }
  }
  ${IAssetListItem}
`;

export const ASSETS_BY_COLUMNS = gql`
  query assetsByColumns(
    $sort: [AssetSort!] = []
    $filter: AssetFilter = {}
    $take: Int = 100
    $skip: Int = 0
    $distinctOn: [AssetDistinctOn!] = []
    $withName: Boolean! = false
    $withBrand: Boolean! = false
    $withModel: Boolean! = false
    $withFactoryIdentifier: Boolean! = false
    $withExpressCode: Boolean! = false
    $withCPU: Boolean! = false
    $withRAM: Boolean! = false
    $withStorage: Boolean! = false
    $withScreenSize: Boolean! = false
    $withOffice: Boolean! = false
    $withServiceProvider: Boolean! = false
    $withAssignee: Boolean! = false
    $withLastAssignment: Boolean! = false
    $withStatus: Boolean! = false
  ) {
    assets_assets_extension(
      sort: $sort
      filter: $filter
      take: $take
      skip: $skip
      distinctOn: $distinctOn
    ) {
      id
      ... @include(if: $withName) {
        name
      }
      ... @include(if: $withBrand) {
        brand
      }
      ... @include(if: $withModel) {
        model
      }
      ... @include(if: $withFactoryIdentifier) {
        factoryIdentifier
      }
      ... @include(if: $withExpressCode) {
        expressCode
      }
      ... @include(if: $withCPU) {
        cpu
      }
      ... @include(if: $withRAM) {
        ram
      }
      ... @include(if: $withStorage) {
        storage
      }
      ... @include(if: $withScreenSize) {
        screenSize
      }
      ... @include(if: $withOffice) {
        office
      }
      ... @include(if: $withServiceProvider) {
        serviceProvider
      }
      ... @include(if: $withStatus) {
        status {
          id
          name
          description
          order
          isSelectable
          createdAt
          updatedAt
        }
      }
      ... @include(if: $withAssignee) {
        currentAssignment {
          id
          assignmentType
          isReturned
          startDate
          endDate
          createdAt
          updatedAt
          contractorName
          contractorEmail
          user {
            id
            oid
            email
            name
            isActive
          }
          room {
            id
            name
            isSelectable
          }
        }
      }
      ... @include(if: $withLastAssignment) {
        lastAssignment {
          id
          assignmentType
          isReturned
          startDate
          endDate
          createdAt
          updatedAt
          user {
            id
            oid
            email
            name
            isActive
          }
          room {
            id
            name
            isSelectable
          }
        }
      }
    }
  }
`;

export const ASSETS_BY_COLUMNS_COUNT = gql`
  query assetsByColumnsCount(
    $sort: [AssetSort!] = []
    $filter: AssetFilter = {}
    $distinctOn: [AssetDistinctOn!] = []
  ) {
    assets_assets_extensionCount(sort: $sort, filter: $filter, distinctOn: $distinctOn)
  }
`;

export const ASSET_CREATE_MUTATION = gql`
  mutation assetCreate(
    $assetTypeId: ID!
    $assetStatusId: ID!
    $assetLocationId: ID!
    $isLoaner: Boolean!
    $name: String
    $brand: String
    $model: String
    $serialNumber: String
    $serviceTag: String
    $cpu: String
    $ram: String
    $storage: String
    $screenSize: String
    $purchaseDate: DateTime
    $warrantyExpirationDate: DateTime
    $serviceProvider: String
    $ipAddress: String
    $comment: String
    $factoryIdentifier: String
    $expressCode: String
    $dollarValue: Float
    $office: String
  ) {
    assets_assetCreate(
      assetTypeId: $assetTypeId
      assetStatusId: $assetStatusId
      assetLocationId: $assetLocationId
      isLoaner: $isLoaner
      name: $name

      brand: $brand
      model: $model
      serialNumber: $serialNumber
      serviceTag: $serviceTag
      cpu: $cpu
      ram: $ram
      storage: $storage
      screenSize: $screenSize
      purchaseDate: $purchaseDate
      warrantyExpirationDate: $warrantyExpirationDate
      serviceProvider: $serviceProvider
      ipAddress: $ipAddress
      comment: $comment

      factoryIdentifier: $factoryIdentifier
      expressCode: $expressCode
      dollarValue: $dollarValue
      office: $office
    ) {
      id
    }
  }
`;

export const ASSET_UPDATE_MUTATION = gql`
  mutation assetUpdate(
    $id: ID!
    $assetTypeId: ID
    $assetStatusId: ID
    $assetLocationId: ID
    $isLoaner: Boolean
    $name: String
    $brand: String
    $model: String
    $serialNumber: String
    $serviceTag: String
    $cpu: String
    $ram: String
    $storage: String
    $screenSize: String
    $purchaseDate: DateTime
    $warrantyExpirationDate: DateTime
    $serviceProvider: String
    $ipAddress: String
    $comment: String
    $factoryIdentifier: String
    $expressCode: String
    $dollarValue: Float
    $office: String
  ) {
    assets_assetUpdate(
      id: $id
      assetTypeId: $assetTypeId
      assetStatusId: $assetStatusId
      assetLocationId: $assetLocationId
      isLoaner: $isLoaner
      name: $name

      brand: $brand
      model: $model
      serialNumber: $serialNumber
      serviceTag: $serviceTag
      cpu: $cpu
      ram: $ram
      storage: $storage
      screenSize: $screenSize
      purchaseDate: $purchaseDate
      warrantyExpirationDate: $warrantyExpirationDate
      serviceProvider: $serviceProvider
      ipAddress: $ipAddress
      comment: $comment

      factoryIdentifier: $factoryIdentifier
      expressCode: $expressCode
      dollarValue: $dollarValue
      office: $office
    ) {
      id
    }
  }
`;

export const ASSET_CANCEL_MUTATION = gql`
  mutation assetCancel($id: ID!) {
    assets_assetCancel(id: $id) {
      id
    }
  }
`;

export const ASSET_UNCANCEL_MUTATION = gql`
  mutation assetUncancel($id: ID!) {
    assets_assetUncancel(id: $id) {
      id
    }
  }
`;

export const ASSET_DELETE_MUTATION = gql`
  mutation assetDelete($id: ID!) {
    assets_assetDelete(id: $id) {
      id
    }
  }
`;

import { FC } from 'react';
import { StatusLabel } from 'components/StatusLabel/StatusLabel';

export const ShowCanceledStatus: FC<{
  data: string;
  row: any;
}> = ({ data, row }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      {data}
      {row.isCanceled ? (
        <StatusLabel tooltip={'canceled'} tooltipTitle="Asset is canceled" />
      ) : undefined}
    </div>
  );
};

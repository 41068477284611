//
// Zoran Bosnjak, 2020
// Just a Material AutoComplete helper component
// in order to avoid redundant code and code duplication
//
// Usage:
// <EasyAutoComplete
//     items={employees}                            (JSON array)
//     selected={selectedEmployee}                  (one element in JSON array)
//     label=""                                     (same as original AutoComplete)
//     textFieldStyle="outlined"                    (same as original AutoComplete)
//     optionsLabel="discipline"                    (property in JSON array)
//     optionsLabel2="name"                         (optional)
//
//     selectedChanged={(value) => {                (event handler when selected item changes, value -> element in JSON array)
//         setSelectedEmployee(value);
//     }}
//
// />

// #region React Core

import React, { FC, useState, useEffect, useMemo, ComponentType, useCallback } from 'react';

// #endregion

// #region Material-UI Components

import {
  Typography,
  TextField,
  Popper,
  PopperProps,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';

// #endregion

// #region External libraries

import { uniqueId } from 'lodash';
import LoadingOverlay from 'react-loading-overlay-ts';
import { TooltipChip } from './components/TooltipChip/TooltipChip';
import { withStyles } from '@mui/styles';

// #endregion

export interface IPagination {
  pages: number;
  loadPage: (page: number) => void;
}

const PopperMy = withStyles({
  popper: {
    minWidth: 'fit-content',
    zIndex: 1300,
  },
})(function (props: any) {
  const { classes } = props;
  return <Popper {...props} className={classes.popper} placement="bottom-start" />;
});

const getOptionSelected = (option: any, value: any) => {
  return (
    (option && option === value) ||
    (option.id && option.id === value.id) ||
    (option.key && option.key === value.key)
  );
};

const EasyAutoComplete: FC<any> = (props: any) => {
  // #region State

  const [items, setItems] = useState(props.items);
  const [selected, setSelected] = useState(props.selected);
  const [name, setName] = useState(props.name ?? uniqueId());
  const [label, setLabel] = useState(props.label);
  const [placeholder, setPlaceholder] = useState(props.placeholder);
  const [optionsLabel, setOptionsLabel] = useState(props.optionsLabel); // E.g. "Id"
  const [optionsLabel2, setOptionsLabel2] = useState(props.optionsLabel2); // E.g. "Name"
  const [optionsLabelSuffix, setOptionsLabelSuffix] = useState(props.optionsLabelSuffix);

  const [textFieldStyle, setTextFieldStyle] = useState(props.textFieldStyle || 'standard');
  const [labelBold, setLabelBold] = useState(props.labelBold || true);
  const [disableClearable, setDisableClearable] = useState(props.disableClearable || true);
  const [showHierarchyLevel, setShowHierarchyLevel] = useState(props.showHierarchyLevel || false);
  const [page, setPage] = useState(0);

  const { pagination }: { pagination: IPagination } = props;

  // #endregion

  // #region Effects
  useEffect(() => {
    if (props?.items) {
      setItems(props?.items);
    }
  }, [props?.items]);

  useEffect(() => {
    if (selected !== props?.selected) setSelected(props?.selected);
    if (name !== props?.name) setName(props?.name);
    if (label !== props?.label) setLabel(props?.label);
    if (placeholder !== props?.placeholder) setPlaceholder(props?.placeholder);
    if (textFieldStyle !== props?.textFieldStyle) setTextFieldStyle(props?.textFieldStyle);
    if (optionsLabel !== props?.optionsLabel) setOptionsLabel(props?.optionsLabel);
    if (optionsLabel2 !== props?.optionsLabel2) setOptionsLabel2(props?.optionsLabel2);
    if (optionsLabelSuffix !== props?.optionsLabelSuffix)
      setOptionsLabelSuffix(props?.optionsLabelSuffix);
    if (labelBold !== props?.labelBold && props?.labelBold !== undefined)
      setLabelBold(props?.labelBold);

    if (disableClearable !== props?.disableClearable && props?.disableClearable !== undefined)
      setDisableClearable(props?.disableClearable);

    if (showHierarchyLevel !== props?.showHierarchyLevel && props?.showHierarchyLevel !== undefined)
      setShowHierarchyLevel(props?.showHierarchyLevel);
  }, [
    props,
    disableClearable,
    items,
    label,
    labelBold,
    name,
    optionsLabel,
    optionsLabel2,
    optionsLabelSuffix,
    selected,
    textFieldStyle,
    placeholder,
    showHierarchyLevel,
  ]);

  // #endregion

  // #region Form Methods

  const getOptionLabel = useCallback(
    (option: any): string => {
      const first = optionsLabel;
      const second = optionsLabel2;

      let result = option[first];

      if (!second) result = option[first];
      else result = `${option[first]} ${option[second]}`;

      if (optionsLabelSuffix) result += optionsLabelSuffix;

      if (!result) return '';
      return result;
    },
    [optionsLabel, optionsLabel2, optionsLabelSuffix]
  );

  const filterOptions = createFilterOptions({
    stringify: getOptionLabel,
  });
  // #endregion

  const paginationListboxProps = useMemo(() => {
    return pagination
      ? {
          role: 'list-box',
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              const { pages, loadPage } = pagination;
              if (page < pages) {
                const nextPage = page + 1;
                loadPage(nextPage);
                setPage(nextPage);
              }
            }
          },
        }
      : undefined;
  }, [pagination, page]);

  return (
    <div style={{ width: '100%' }}>
      <LoadingOverlay active={props?.loading} text="Loading...">
        <Autocomplete
          disableCloseOnSelect={props?.multiple ?? false}
          blurOnSelect={!props?.multiple}
          filterOptions={props?.filterOptions || filterOptions}
          PopperComponent={PopperMy as ComponentType<PopperProps>}
          loading={props?.loading}
          ListboxProps={paginationListboxProps}
          isOptionEqualToValue={props?.getOptionSelected ?? getOptionSelected}
          popupIcon={props?.popupIcon}
          style={props?.style}
          disableClearable={disableClearable}
          fullWidth={props?.fullWidth !== false}
          multiple={props?.multiple ?? false}
          value={selected ?? (props?.multiple ? [] : null)}
          open={props?.open}
          onClose={props?.onClose}
          onOpen={props?.onOpen}
          onChange={(event: any, value: any): void => {
            setSelected(() => value);
            props.selectedChanged(value);
          }}
          autoHighlight
          options={items}
          getOptionLabel={props.getSelectedLabel || getOptionLabel}
          key={`autocomplete-${name}-key`}
          disabled={props?.disabled}
          onInputChange={props?.onInputChange}
          renderOption={
            props?.renderOption
              ? props?.renderOption
              : (ctrlProps: any, option): JSX.Element => {
                  return (
                    <div
                      {...ctrlProps}
                      style={{
                        zIndex: 9999,
                        ...(showHierarchyLevel
                          ? { paddingLeft: (option.level * 16).toString() + 'px' }
                          : {}),
                      }}
                    >
                      {props?.icon && props?.icon(option)}
                      <Typography variant="body2" component="span">
                        {getOptionLabel(option)}
                      </Typography>
                    </div>
                  );
                }
          }
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              className={props?.className}
              name={`textField-${name}`}
              key={`textfield${name}-key`}
              margin="dense"
              fullWidth
              label={label}
              placeholder={placeholder}
              variant={textFieldStyle}
              style={props?.textFieldStyling}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              InputLabelProps={{
                style: { fontWeight: labelBold ? 'bold' : 'normal' },
              }}
              error={props.error}
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <TooltipChip
                tagValue={tagValue}
                getTagProps={getTagProps}
                option={option}
                index={index}
                tooltip={props.tagTooltip}
                key={index}
                getOptionLabel={getOptionLabel}
              ></TooltipChip>
            ));
          }}
          inputValue={props?.inputValue}
          getOptionDisabled={(option) => {
            return option.disabled === true;
          }}
        />
      </LoadingOverlay>
    </div>
  );
};

export default EasyAutoComplete;

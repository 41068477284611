import { gql } from '@apollo/client';
import { IAssetLocation } from './fragments';

export const GET_ASSET_LOCATION_NAMES = gql`
  query assetLocationNames {
    assets_assetLocations {
      id
      name
    }
  }
`;

export const GET_ASSET_LOCATION_COUNT = gql`
  query assetLocationCount($filter: AssetLocationFilter = {}) {
    assets_assetLocationCount(filter: $filter)
  }
`;

export const GET_ASSET_LOCATIONS = gql`
  query assetLocations(
    $sort: [AssetLocationSort!] = []
    $filter: AssetLocationFilter = {}
    $take: Int = 100
    $skip: Int = 0
  ) {
    assets_assetLocations(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IAssetLocation
    }
  }
  ${IAssetLocation}
`;

export const ASSET_LOCATION_CREATE_MUTATION = gql`
  mutation assetLocationCreate(
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetLocationCreate(
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

export const ASSET_LOCATION_UPDATE_MUTATION = gql`
  mutation assetLocationUpdate(
    $id: ID!
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetLocationUpdate(
      id: $id
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

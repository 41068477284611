import { IFieldsDescription } from 'template/Asset/components/AssetEventsLog/hook/customFields';

export const customFields: Array<Array<IFieldsDescription>> = [
  [
    {
      id: 'assignmentType',
      title: 'Assignment Type',
    },

    {
      id: 'contractorName',
      title: 'Contractor Name',
    },

    {
      id: 'contractorEmail',
      title: 'Contractor Email',
    },

    // Boolean
    {
      id: 'isActive',
      title: 'Is Active',
      field: { type: 'boolean' },
    },

    {
      id: 'startDate',
      title: 'Start Date',
      field: { type: 'date' },
    },
    {
      id: 'endDate',
      title: 'End Date',
      field: { type: 'date' },
    },

    // Related
    {
      id: 'user',
      title: 'User',
    },
  ],
];

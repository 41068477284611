import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { useUI } from 'contexts/UiContext';
import {
  ASSET_TYPE_CREATE_MUTATION,
  ASSET_TYPE_UPDATE_MUTATION,
  GET_ASSET_TYPES,
} from 'graphql/ams/assetTypes';

import { assetTypes } from 'graphql/ams/types/assetTypes';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { tryUpdateProcedure } from 'utils/apollo';
import { v4 as uuidv4 } from 'uuid';

export interface IAssetTypesFilter {}
export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: IAssetTypesFilter;
}

export interface IAssetTypesTreeProps {
  expandAll?: boolean;
}

export const useAssetTypesTree = (
  { expandAll }: IAssetTypesTreeProps | undefined = { expandAll: false }
) => {
  const client = useApolloClient();
  const { addSnackbar } = useUI();

  const [createTypeMutation] = useMutation(ASSET_TYPE_CREATE_MUTATION);
  const [updateTypeMutation] = useMutation(ASSET_TYPE_UPDATE_MUTATION);

  const [types, setTypes] = useState<any[]>([]);

  const [expandedTypes, setExpandedTypes] = useState<any>({});

  const [reordered, setReordered] = useState(false);

  const [deletedItems, setDeletedItems] = useState<string[]>([]);
  const { data, loading, error } = useQuery<assetTypes>(GET_ASSET_TYPES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const expandList = useCallback(
    ({
      list,
      parents,
      nodeId,
      level,
    }: {
      list: any[];
      nodeId: string;
      parents: { [id: string]: any[] };
      level: number;
    }) => {
      if (!parents[nodeId]) {
        return;
      }
      for (var node of parents[nodeId]) {
        list.push({ ...node, level });
        expandList({ list, nodeId: node.id, parents, level: level + 1 });
      }
    },
    []
  );

  useEffect(() => {
    if (data && !loading) {
      // root items
      const parents: { [id: string]: any[] } = {};
      const nullParent = [];
      const allItems = data?.assets_assetTypes;
      for (var item of allItems) {
        if (!item.parent) {
          nullParent.push(item);
        } else {
          if (!parents[item.parent.id]) {
            parents[item.parent.id] = [item];
          } else {
            parents[item.parent.id].push(item);
          }
        }
      }

      nullParent.sort((a, b) => a.order - b.order);
      for (var parentId of Object.keys(parents)) {
        parents[parentId].sort((a, b) => a.order - b.order);
      }

      const finalList: any[] = [];

      for (var node of nullParent) {
        finalList.push({ ...node, level: 0 });
        expandList({ list: finalList, nodeId: node.id, parents, level: 1 });
      }

      let index = 0;
      for (var finalListItem of finalList) {
        finalListItem.index = index;
        finalListItem.orderNo = index + 1;
        index++;
      }

      setTypes(finalList);
    }
  }, [data, loading, expandList]);

  const totalItems = useMemo(() => {
    return types?.length || 0;
  }, [types]);

  const visibleTypes = useMemo(() => {
    const visibleIds: { [id: string]: boolean } = {};
    const visible = [];
    for (const item of types) {
      if (
        expandAll ||
        !item.parent ||
        (visibleIds[item.parent.id] && expandedTypes[item.parent.id])
      ) {
        visible.push(item);
        visibleIds[item.id] = true;
      }
    }
    return visible;
  }, [types, expandedTypes, expandAll]);

  const totalVisibleItems = useMemo(() => {
    return visibleTypes?.length || 0;
  }, [visibleTypes]);

  const getTreeLength = useCallback(
    (index: number) => {
      const { level } = types[index];
      let toIndex = index;
      while (types.length > toIndex + 1 && types[toIndex + 1].level > level) {
        toIndex++;
      }
      return toIndex - index;
    },
    [types]
  );

  const deleteTreeItemById = useCallback(
    (id: string) => {
      const index = types.findIndex((type: any) => type.id === id);
      if (index < 0) {
        return;
      }
      const treeLength = getTreeLength(index);

      const tmpTypes = [...types];
      const deletedItems = tmpTypes.splice(index, treeLength + 1);

      setDeletedItems((old: string[]) => [...old, ...deletedItems.map((item) => item.id)]);
      setTypes(tmpTypes);
    },
    [types, getTreeLength]
  );

  const onSubmit = useCallback(async () => {
    for (const type of types) {
      if (type.order !== type.index + 1) {
        type.order = type.index + 1;
        type.changed = true;
      }
    }

    const createItems = types.filter((type: any) => !!type.isNew);
    const updateItems = types.filter((type: any) => !type.isNew && !!type.changed);

    let foundError = false;
    let allErrors: string[] = [];
    let lastResult = undefined;

    for (const item of createItems) {
      const { parent } = item;
      const saveData = {
        ...pick(item, ['name', 'description', 'order', 'isSelectable']),
        parentAssetTypeId: parent?.id || null,
      };
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createTypeMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.isNew = false;
        item.changed = false;
        item.id = result?.assets_assetTypeCreate?.id;
      }
    }

    for (const item of updateItems) {
      const { parent } = item;
      const saveData = {
        ...pick(item, ['id', 'name', 'description', 'order', 'isSelectable']),
        parentAssetTypeId: parent?.id || null,
      };
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          updateTypeMutation({
            variables: saveData,
          }),
        parseResult: (data: any) => {
          return data;
        },
      });
      foundError = foundError || isError;
      lastResult = result;
      if (isError && errors?.length) {
        allErrors = [...allErrors, ...errors];
      } else {
        item.changed = false;
      }
    }

    if (foundError) {
      addSnackbar!({
        text: allErrors?.join(' '),
        severity: 'error',
      });
    } else {
      if (lastResult) {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
      } else {
        addSnackbar!({
          text: 'Nothing to Save',
          severity: 'info',
        });
      }
      await client.resetStore();
    }
    if (deletedItems.length) {
      addSnackbar!({
        text: 'Type deletion is not supported',
        severity: 'error',
      });
    }

    if (!foundError) {
      setReordered(() => false);
    }
    setTypes((old) => [...old]);
  }, [types, addSnackbar, createTypeMutation, updateTypeMutation, deletedItems.length, client]);

  const hasChanges = useMemo(() => {
    return (
      reordered || deletedItems.length || !!types.find((type: any) => type.isNew || !!type.changed)
    );
  }, [reordered, deletedItems, types]);

  const addNew = useCallback(() => {
    const newTypeItem: any = {
      id: uuidv4(),
      isSelectable: true,
      order: 1,
      level: 0,
      name: '',
      description: '',
      isNew: true,
      orderNo: 1,
    };
    setTypes((old: any[]) => {
      const tmpTypes = [newTypeItem, ...old];
      let index = 0;
      for (var tmpTypesItem of tmpTypes) {
        tmpTypesItem.index = index;
        tmpTypesItem.orderNo = index + 1;
        index++;
      }
      return tmpTypes;
    });
    return newTypeItem;
  }, []);

  const hasPrevSibling = useCallback(
    (id: string) => {
      const index = types.findIndex((item) => item.id === id);
      if (index === 0) {
        return false;
      }
      if (types[index - 1].level >= types[index].level) {
        return true;
      }
      return false;
    },
    [types]
  );

  const hasParent = useCallback(
    (id: string) => {
      const index = types.findIndex((item) => item.id === id);
      return !!types[index].parent;
    },
    [types]
  );

  const moveTreeItemLeft = useCallback(
    (id: string) => {
      setTypes((oldTypes: any[]) => {
        const tmpTypes = [...oldTypes];

        const index = tmpTypes.findIndex((item) => item.id === id);
        const parentIndex = tmpTypes.findIndex((item) => item.id === tmpTypes[index].parent.id);

        const subTreeLength = getTreeLength(index);
        const parentSubTreeLength = getTreeLength(parentIndex);

        tmpTypes[index] = {
          ...tmpTypes[index],
          parent: tmpTypes[parentIndex].parent ? { ...tmpTypes[parentIndex].parent } : null,
          changed: true,
        };

        for (let i = index; i <= index + subTreeLength; i++) {
          tmpTypes[i].level = tmpTypes[i].level - 1;
        }

        const movingItems = tmpTypes.splice(index, subTreeLength + 1);
        tmpTypes.splice(parentIndex + parentSubTreeLength - subTreeLength, 0, ...movingItems);

        let order = 0;
        for (var tmpTypesItem of tmpTypes) {
          tmpTypesItem.index = order;
          tmpTypesItem.orderNo = order + 1;
          order++;
        }

        return tmpTypes;
      });
    },
    [getTreeLength]
  );

  const moveTreeItemRight = useCallback(
    (id: string) => {
      setTypes((oldTypes: any[]) => {
        const tmpTypes = [...oldTypes];

        const index = tmpTypes.findIndex((item) => item.id === id);
        const level = tmpTypes[index].level;

        let prevSiblingIndex = index - 1;
        while (tmpTypes[prevSiblingIndex].level > level) {
          prevSiblingIndex--;
        }

        tmpTypes[index] = {
          ...tmpTypes[index],
          parent: pick(tmpTypes[prevSiblingIndex], ['id', 'name']),
          changed: true,
        };

        const subTreeLength = getTreeLength(index);
        for (let i = index; i <= index + subTreeLength; i++) {
          tmpTypes[i].level = tmpTypes[i].level + 1;
        }

        const newParentId = tmpTypes[prevSiblingIndex].id;
        setExpandedTypes((expanded: any) => ({ ...expanded, [newParentId]: true }));

        return tmpTypes;
      });
    },
    [getTreeLength]
  );

  const moveTreeItem = useCallback(
    (fromId: string, toId: string) => {
      setReordered(true);
      setTypes((oldTypes: any[]) => {
        const fromIndex = oldTypes.findIndex((item: any) => item.id === fromId);
        const toIndex = oldTypes.findIndex((item: any) => item.id.toString() === toId.toString());

        const subTreeLength = getTreeLength(fromIndex);
        if (toIndex > fromIndex && toIndex <= subTreeLength + fromIndex) {
          return oldTypes;
        }

        const targetSubTreeLength = getTreeLength(toIndex);

        const tmpTypes = [...oldTypes];

        tmpTypes[fromIndex].changed = true;
        tmpTypes[fromIndex].parent = tmpTypes[toIndex].parent
          ? { ...tmpTypes[toIndex].parent }
          : null;

        const diffLevel = tmpTypes[toIndex].level - tmpTypes[fromIndex].level;
        let targetIndex = toIndex;
        if (toIndex > fromIndex) {
          targetIndex = toIndex + targetSubTreeLength - subTreeLength;
        }

        const movingItems = tmpTypes.splice(fromIndex, subTreeLength + 1);
        tmpTypes.splice(targetIndex, 0, ...movingItems);

        if (diffLevel) {
          for (let i = 0; i < subTreeLength + 1; i++) {
            tmpTypes[i + targetIndex].level += diffLevel;
          }
        }

        let index = 0;
        for (var tmpTypesItem of tmpTypes) {
          tmpTypesItem.index = index;
          tmpTypesItem.orderNo = index + 1;
          index++;
        }

        return tmpTypes;
      });
    },
    [getTreeLength]
  );

  const updateItem = useCallback(
    (item: any) => {
      const updateValues = { ...item };
      if (item.orderNo !== item.index + 1) {
        moveTreeItem(item.id, types[item.orderNo - 1].id);
      }

      setTypes((types: any[]) => {
        const newTypes = [...types];
        const found = newTypes.find((type: any) => type.id === updateValues.id);
        Object.assign(found, {
          ...pick(updateValues, ['name', 'description', 'isSelectable']),
          changed: true,
        });
        return newTypes;
      });
    },
    [moveTreeItem, types]
  );

  const onExpandChange = useCallback((id: string) => {
    setExpandedTypes((expanded: any) => {
      const result = { ...expanded };
      if (result[id]) {
        delete result[id];
      } else {
        result[id] = true;
      }
      return result;
    });
  }, []);

  const parents = useMemo(() => {
    const parents: { [id: string]: boolean } = {};
    for (let i = 1; i < types.length; i++) {
      if (types[i - 1].level < types[i].level && !parents[types[i - 1].id]) {
        parents[types[i - 1].id] = true;
      }
    }

    return parents;
  }, [types]);

  return {
    onExpandChange,
    types: visibleTypes,
    loading,
    error,
    totalItems: totalVisibleItems,
    totalRecords: totalItems,
    deleteTreeItemById,
    updateItem,
    onSubmit,
    hasChanges,
    addNew,
    moveTreeItem,
    expandedTypes,
    parents,
    hasPrevSibling,
    moveTreeItemRight,
    moveTreeItemLeft,
    hasParent,
  };
};

import { gql } from '@apollo/client';

export const GET_ASSET_NAMES_COUNT = gql`
  query assetNamesCount($filter: AssetFilter) {
    assets_assetCount(filter: $filter)
  }
`;

export const GET_ASSET_NAMES = gql`
  query assetNamesList(
    $sort: [AssetSort!] = []
    $filter: AssetFilter = {}
    $take: Int = 100
    $skip: Int = 0
  ) {
    assets_assets(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
    }
  }
`;

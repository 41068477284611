import { msalLogout } from 'authorization/auth-utils-msal2';

import { MultiButtonDialog } from 'components/MultiButtonDialog';
import { useCallback } from 'react';

export const NoAccess = () => {
  const logout = useCallback((): void => {
    msalLogout();
  }, []);

  return (
    <>
      <MultiButtonDialog
        open={true}
        title={'Access Denied'}
        message={'Sorry, you are not authorized to use this application.'}
        onClose={() => {}}
        confirmButtons={[
          {
            title: 'Logout',
            onClick: logout,
          },
        ]}
      ></MultiButtonDialog>
    </>
  );
};

import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

export interface IViewFrameProps {
  children?: any;
  header?: any;
  tabs?: any;
}

export const ViewFrame: FC<IViewFrameProps> = ({ children, header, tabs }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <>
        {header ? (
          <>
            <Box m={4} />
            {header}
            <Box m={3} />
          </>
        ) : undefined}
        {tabs ? <>{tabs}</> : undefined}
        <Paper
          elevation={3}
          style={{ padding: '2rem', borderTopLeftRadius: '0px' }}
          id="main-paper"
        >
          {children}
        </Paper>
      </>
    </Container>
  );
};

const BASE = '/';
const DASHBOARD = '/';
const LOGIN = '/login';
const NOACCESS = '/access-denied';
const ASSETS = '/';
const NEW_ASSET_SUB_PAGE = '/asset/new-asset';
const ASSET_PAGE_SUB_PAGE = '/asset/:id/:tab';
const ASSET_DETAILS_SUB_PAGE = '/asset/:id/details';
const ASSET_FM_SUB_PAGE = '/asset/:id/file-management';
const ASSET_EVENTS_SUB_PAGE = '/asset/:id/events-log';
const REPORTS = '/reports';

const clientPathConst = {
  BASE,
  LOGIN,
  DASHBOARD,
  NOACCESS,

  ASSETS,
  NEW_ASSET_SUB_PAGE,
  ASSET_PAGE_SUB_PAGE,
  ASSET_DETAILS_SUB_PAGE,
  ASSET_FM_SUB_PAGE,
  ASSET_EVENTS_SUB_PAGE,

  REPORTS,
};

export default clientPathConst;

import { FC } from 'react';
import { Provider as AssetsProvider } from 'contexts/assets/AssetsContext';
import { Provider as AssetsListProvider } from 'contexts/assets/AssetsListContext';
import { TableFrame } from 'template/TableFrame/TableFrame';
import { AssetsListView } from 'template/AssetsList/AssetsListView';

export interface IProps {
  routes: Array<any>;
}

export const AssetsPage: FC<IProps> = () => {
  return (
    <AssetsProvider>
      <TableFrame>
        <AssetsListProvider>
          <AssetsListView></AssetsListView>
        </AssetsListProvider>
      </TableFrame>
    </AssetsProvider>
  );
};

import { ApolloError } from '@apollo/client';
import { IDateRange } from 'components/DateRange/DateRange';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { GET_ASSETS, GET_ASSET_COUNT } from 'graphql/ams/assets';
import { AssetFilter } from 'graphql/ams/types/graphql-types';
import { useCallback } from 'react';
import { useTableDataSource } from './tableDataSourceHook';

const getCount = (data: any) => data.assets_assetCount;
const getItems = (data: any) => data.assets_assets;

const getToday = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

const getFromToday = (period: IDateRange) => {
  const date = getToday();
  date.setDate(date.getDate() + (period.days ?? 0));
  date.setFullYear(date.getFullYear() + (period.years ?? 0));
  date.setMonth(date.getMonth() + (period.months ?? 0));
  return date;
};

const getNegativePeriod = (period: IDateRange) => ({
  days: -(period.days ?? 0),
  years: -(period.years ?? 0),
  months: -(period.months ?? 0),
});

export interface IAssetsTableDataSourceProps {
  initFilter?: Partial<AssetFilter>;
  overridePageLoadParams?: any;
  tableStorageKey?: string;
  initRowsPerPage?: number;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

const defaultFilterValues = {
  isCurrentlyAssigned: { id: 'All', name: 'All' },
  isLoaner: { id: 'All', name: 'All' },
  dollarValue: { id: 'All', name: 'All' },
};

export interface IAssetsTableHookValue {
  filterOptions?: any;
  filterValues?: any;
  totalItems: number;
  items: any[];
  loading: boolean;
  filterApplied: any;
  pageLoadParams: any;
  error: ApolloError | undefined;
  loadPage: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  onFilterChange: (filterValues: any) => boolean;
  clearAllFilters: () => void;
  errorItems?: any;
  errorCount?: any;
}

export const dateVariableTypes = [
  'date',
  'from',
  'between',
  'to',
  'past',
  'future',
  'older',
  'younger',
  'sooner',
  'later',
];

const utcDateAsLocalDate = (utcDate: string) => {
  if (!utcDate) {
    return undefined;
  }
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const [year, month, day] = utcDate.split('-');
  date.setFullYear(parseInt(year));
  date.setMonth(parseInt(month) - 1); // 0 based:  0 - January,...
  date.setDate(parseInt(day)); // 1 based: 1 - first day of a month
  return date;
};

const convertDateFilterValueToApiProps = (dateTypeFilterValue: any) => {
  let from: undefined | Date = undefined;
  let to: undefined | Date = undefined;
  let at: undefined | Date = undefined;
  if (typeof dateTypeFilterValue?.type === 'number') {
    switch (dateVariableTypes[dateTypeFilterValue.type]) {
      case 'date':
        at = utcDateAsLocalDate(dateTypeFilterValue.date);
        break;

      case 'from':
        from = utcDateAsLocalDate(dateTypeFilterValue.date);
        break;

      case 'to':
        to = utcDateAsLocalDate(dateTypeFilterValue.date);
        to && to.setDate(to.getDate() + 1);
        break;

      case 'between':
        from = utcDateAsLocalDate(dateTypeFilterValue.date);
        to = utcDateAsLocalDate(dateTypeFilterValue.dateTo);
        to && to.setDate(to.getDate() + 1);
        break;

      case 'past':
        to = getToday();
        to.setHours(0, 0, 0, 0);
        break;

      case 'future':
        from = getToday();
        from.setHours(0, 0, 0, 0);
        from.setDate(from.getDate() + 1);
        break;

      case 'older':
        to = getFromToday(getNegativePeriod(dateTypeFilterValue as IDateRange));
        to.setHours(0, 0, 0, 0);
        to.setDate(to.getDate() + 1);
        break;

      case 'younger':
        from = getFromToday(getNegativePeriod(dateTypeFilterValue as IDateRange));
        from.setHours(0, 0, 0, 0);
        break;

      case 'sooner':
        // sooner is future so we need to take from current day
        from = getToday();
        from.setHours(0, 0, 0, 0);
        to = getFromToday(dateTypeFilterValue as IDateRange);
        to.setHours(0, 0, 0, 0);
        break;

      case 'later':
        from = getFromToday(dateTypeFilterValue as IDateRange);
        from.setHours(0, 0, 0, 0);
        break;
      default:
        break;
    }
  } else {
    if (dateTypeFilterValue) {
      at = new Date(dateTypeFilterValue);
      at.setHours(0, 0, 0, 0);
    }
  }
  return { at, from, to };
};

export const useAssetsTableHook = ({
  initFilter,
  overridePageLoadParams,
  tableStorageKey,
  initRowsPerPage,
}: IAssetsTableDataSourceProps): IAssetsTableHookValue => {
  const filterToAPI = useCallback((filterValues: any): AssetFilter => {
    let {
      at: createdAt,
      from: createdAtFrom,
      to: createdAtTo,
    } = convertDateFilterValueToApiProps(filterValues.createdAt);

    let {
      at: updatedAt,
      from: updatedAtFrom,
      to: updatedAtTo,
    } = convertDateFilterValueToApiProps(filterValues.updatedAt);
    let {
      at: purchaseDate,
      from: purchaseDateFrom,
      to: purchaseDateTo,
    } = convertDateFilterValueToApiProps(filterValues.purchaseDate);
    let {
      at: warrantyExpirationDate,
      from: warrantyExpirationDateFrom,
      to: warrantyExpirationDateTo,
    } = convertDateFilterValueToApiProps(filterValues.warrantyExpirationDate);

    let {
      at: lastAssignmentEndDate,
      from: lastAssignmentEndDateFrom,
      to: lastAssignmentEndDateTo,
    } = convertDateFilterValueToApiProps(filterValues.lastAssignmentEndDate);

    if (lastAssignmentEndDate) {
      lastAssignmentEndDateFrom = new Date(lastAssignmentEndDate);
      lastAssignmentEndDateTo = new Date(lastAssignmentEndDateFrom);
      lastAssignmentEndDateTo.setDate(lastAssignmentEndDateTo.getDate() + 1);
    }

    return {
      // assetTypeIds: filterValues.assetTypeIds,
      assetTypeIds: filterValues.type || undefined,
      // assetStatusIds: filterValues.assetStatusIds,
      assetStatusIds: filterValues.status || undefined,
      assetLocationIds:
        filterValues.location && Array.isArray(filterValues.location)
          ? filterValues.location
          : undefined,

      isLoaner:
        filterValues.isLoaner?.id === 'TEMPORARY' ||
        (filterValues.isLoaner?.id === 'PERMANENT' ? false : undefined),
      isCurrentlyAssigned:
        filterValues.isCurrentlyAssigned?.id === 'YES'
          ? true
          : filterValues.isCurrentlyAssigned?.id === 'NO' || filterValues.currentAssignment === '--'
          ? false
          : undefined,
      // name: filterValues.name,
      nameContains: filterValues.name || undefined,

      // statusNameContains: filterValues.status || undefined,
      // typeNameContains: filterValues.type || undefined,
      // locationNameContains:
      //   filterValues.location && filterValues.location.id !== 'All'
      //     ? filterValues.location.name
      //     : undefined,

      brandContains: filterValues.brand || undefined,
      modelContains: filterValues.model || undefined,
      serialNumberContains: filterValues.serialNumber || undefined,
      serviceTagContains: filterValues.serviceTag || undefined,
      cpuContains: filterValues.cpu || undefined,
      ramContains: filterValues.ram || undefined,
      storageContains: filterValues.storage || undefined,
      screenSizeContains: filterValues.screenSize || undefined,

      purchaseDate,
      purchaseDateFrom,
      purchaseDateTo,

      warrantyExpirationDate,
      warrantyExpirationDateFrom,
      warrantyExpirationDateTo,

      serviceProviderContains: filterValues.serviceProvider || undefined,

      commentContains: filterValues.comment || undefined,
      currentAssignmentUserOrRoomOrContractorNameContains:
        filterValues.currentAssignment === '--' && filterValues.isCurrentlyAssigned?.id !== 'YES'
          ? undefined
          : filterValues.currentAssignment || undefined,
      currentAssignmentUserOrContractorEmailContains:
        filterValues.currentAssignmentEmail === '--'
          ? ''
          : filterValues.currentAssignmentEmail || undefined,
      factoryIdentifierContains: filterValues.factoryIdentifier || undefined,
      expressCodeContains: filterValues.expressCode || undefined,
      dollarValueLow: (() => {
        switch (filterValues.dollarValue?.id) {
          case '2':
            return 1000;
          case '3':
            return 10000;
          default:
            return undefined;
        }
      })(),
      dollarValueHigh: (() => {
        switch (filterValues.dollarValue?.id) {
          case '1':
            return 999.99;
          case '2':
            return 9999.99;
          default:
            return undefined;
        }
      })(),
      officeContains: filterValues.office || undefined,

      createdAt,
      createdAtFrom,
      createdAtTo,

      updatedAt,
      updatedAtFrom,
      updatedAtTo,

      lastAssignmentEndDateFrom: lastAssignmentEndDateFrom
        ? lastAssignmentEndDateFrom.toISOString()
        : undefined,
      lastAssignmentEndDateTo: lastAssignmentEndDateTo
        ? lastAssignmentEndDateTo.toISOString()
        : undefined,
    };
  }, []);

  const clearAllFilters = () => {
    onFilterChange(defaultFilterValues);
  };

  const {
    items,
    loadPage,
    totalItems,
    loading,
    onFilterChange,
    pageLoadParams,
    error,
    filterApplied,
    errorItems,
    errorCount,
  } = useTableDataSource<any, any, any, any>({
    initFilter,
    overridePageLoadParams,
    GET_COUNT_QUERY: GET_ASSET_COUNT,
    GET_QUERY: GET_ASSETS,
    getCount,
    getItems,
    tableStorageKey,
    initRowsPerPage: initRowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filterToAPI,
  });

  return {
    items,
    loadPage,
    totalItems,
    loading,
    onFilterChange,
    pageLoadParams,
    error,
    filterApplied,
    clearAllFilters,
    errorItems,
    errorCount,
  };
};

import { FC } from 'react';
import { Provider as AssetsListProvider } from 'contexts/assets/AssetsListContext';
import { TableFrame } from 'template/TableFrame/TableFrame';
import { ReportAssetsList } from 'template/ReportAssetsList/ReportAssetsList';

export interface IProps {
  routes: Array<any>;
}

export const ReportAssetsPage: FC<IProps> = () => {
  return (
    <TableFrame>
      <AssetsListProvider tableStorageKey="ReportAssetsTable">
        <ReportAssetsList></ReportAssetsList>
      </AssetsListProvider>
    </TableFrame>
  );
};

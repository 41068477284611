import s from './style.module.scss';
import { useCallback } from 'reactn';
import { useHistory } from 'react-router-dom';
import { useComponentContext as useFormChangedDialogContext } from 'components/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC } from 'react';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';

export interface IAssetHeaderProps {}

export const AssetHeader: FC<IAssetHeaderProps> = () => {
  const { getBasePageInfo } = useMainTabsHistory();
  const history = useHistory();
  const { confirmRequest } = useFormChangedDialogContext();

  const goToAssets = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && history.push(getBasePageInfo().url);
    });
  }, [history, confirmRequest, getBasePageInfo]);

  return (
    <>
      <div className={s.linkStyle} onClick={debounce(goToAssets, DEBOUNCE_TIMEOUT)}>
        Go back to {getBasePageInfo().name.toLowerCase()}
      </div>
    </>
  );
};

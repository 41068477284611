import { NAME_LENGTH_LIMIT } from './config';

export const validators = {
  simpleText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
    },
  },
  required: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  nameText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
      maximum: NAME_LENGTH_LIMIT,
    },
  },
  getPositiveIntegerValidator: (lessThanOrEqualTo: number) => ({
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: lessThanOrEqualTo,
      message: 'Allowed values are from 0 to ' + lessThanOrEqualTo?.toString(),
    },
  }),
};

import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { ASSETS_BY_COLUMNS, ASSETS_BY_COLUMNS_COUNT } from 'graphql/ams/assets';
import { assetsByColumns } from 'graphql/ams/types/assetsByColumns';
import { assetsByColumnsCount } from 'graphql/ams/types/assetsByColumnsCount';
import { AssetFilter } from 'graphql/ams/types/graphql-types';

const getCount = (dataAssetStatussCount: assetsByColumnsCount) =>
  dataAssetStatussCount.assets_assets_extensionCount;
const getItems = (dataAssetStatus: assetsByColumns) =>
  dataAssetStatus.assets_assets_extension
    ?.filter((asset) => asset.status?.id && asset.status?.name)
    .map((asset) => ({
      id: asset.status!.id,
      name: asset.status!.name,
    }));
const getItemId = (item: any) => item.status?.id;
const filterToAPI = (localSearch: string) => ({ statusNameContains: localSearch });
const distinctOn = [{ column: 'STATUS_ID' }];
const gqlProps = { withStatus: true };
const order = [{ column: 'STATUS_ID', order: 'ASC' }];

export interface IAssetStatussAutocompleteDataProps {
  search: string;
}

interface IItem {
  name: string | null;
}

export const useAssetStatussAutocompleteDataHook = ({
  search,
}: IAssetStatussAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<assetsByColumns, assetsByColumnsCount, IItem, AssetFilter>(
    {
      GET_QUERY: ASSETS_BY_COLUMNS,
      GET_COUNT_QUERY: ASSETS_BY_COLUMNS_COUNT,
      getCount,
      getItems,
      getItemId,
      search,
      filterToAPI,
      distinctOn,
      gqlProps,
      order,
    }
  );

  return {
    ...resp,
  };
};

// import { validators } from 'constants/validators';
import { validators } from 'constants/validators';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { validate } from 'validate.js';
// import { validate } from 'validate.js';

export interface IContextState {
  type: any;
}

export interface IContextActions {
  setType: any;
  setProps: any;
  onSubmitValidate: () => boolean;
}

const initialState: IContextState = {
  type: {},
};

const EditAssetTypeContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  children: any;
}

export const EditAssetTypeProvider: FC<IProviderProps> = ({ children }) => {
  const [props, setProps] = useState<any>({});

  const [typeNotValidated, setType] = useState<any>({});
  const [type, setValidatedType] = useState<any>({});

  const assetTypeValidators = useMemo(() => {
    return {
      name: validators.simpleText,
      orderNo: validators.getPositiveIntegerValidator(props.totalItems),
    };
  }, [props.totalItems]);

  const validateForm = useCallback(
    (newState: any) => {
      const errors = validate(newState, assetTypeValidators);
      return { ...newState, errors, isValid: errors ? false : true };
    },
    [assetTypeValidators]
  );

  useEffect(() => {
    setValidatedType(() => {
      return validateForm({ ...typeNotValidated });
    });
  }, [typeNotValidated, validateForm]);

  const onSubmitValidate = useCallback(() => {
    const { name, isValid } = type;
    if (!isValid || !name) {
      setValidatedType(validateForm({ ...typeNotValidated, showValidator: true }));
      return false;
    }
    return true;
  }, [type, validateForm, typeNotValidated]);

  return (
    <EditAssetTypeContext.Provider
      value={{
        type,
        setType,
        setProps,
        onSubmitValidate,
      }}
    >
      {children}
    </EditAssetTypeContext.Provider>
  );
};

export const useEditAssetTypeContext = () => useContext(EditAssetTypeContext);

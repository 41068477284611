/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from 'react';
import { Box, Paper, Button, Container } from '@mui/material';

import { LoginProps } from './interfaces';

import { DEBOUNCE_TIMEOUT } from '../../constants/config';
import { debounce } from 'lodash';
import { msalInstance } from '../../authorization/auth-utils-msal2';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

export const AppLogin: FC<LoginProps> = () => {
  const { inProgress } = useMsal();

  const loginHandler = useCallback(() => {
    if (inProgress === InteractionStatus.None) {
      msalInstance.loginRedirect().catch((e: any) => console.error(e));
    }
  }, [inProgress]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{ padding: '1rem', marginTop: '3rem' }} elevation={6}>
        <form>
          <Box m="3rem" />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={debounce(() => loginHandler(), DEBOUNCE_TIMEOUT)}
          >
            Login to the Application
          </Button>
          <Box m="3rem" />
        </form>
      </Paper>
    </Container>
  );
};

import { FC, useCallback, useMemo } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useComponentContext } from 'contexts/assets/AssetsListContext';

import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { assetsTableHead } from 'constants/assetsTable';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { Box, Button, Grid } from '@mui/material';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { paths } from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { ExportToExcel } from './ExportToExcel/ExportToExcel';
import { useAssetNamesAutocompleteDataHook } from 'hooks/assetNamesAutoCompleteDataHook';
import { useAssetOfficesAutocompleteDataHook } from 'hooks/assetOfficesAutoCompleteDataHook';
import { useAssetBrandsAutocompleteDataHook } from 'hooks/assetBrandAutoCompleteDataHook';
import { useAssetModelsAutocompleteDataHook } from 'hooks/assetModelsAutoCompleteDataHook';
import { useAssetFactoryIdentifiersAutocompleteDataHook } from 'hooks/assetFactoryIdentifierAutoCompleteDataHook';
import { useAssetExpressCodesAutocompleteDataHook } from 'hooks/assetExpressCodesAutoCompleteDataHook';
import { useAssetAssigneesAutocompleteDataHook } from 'hooks/assetAssigneeAutoCompleteDataHook';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';
import { useAssigneeEmailsAutocompleteDataHook } from 'hooks/assetAssigneeEmailsAutoCompleteDataHook';

export interface ITableViewProps {
  onReady?: () => void;
  printView?: boolean;
}

export const AssetsListView: FC<ITableViewProps> = ({ onReady, printView }) => {
  const {
    totalItems,
    items: assets,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    onAssetSelect,
    filterOptions,
    filterValues,
  } = useComponentContext();
  const history = useHistory();
  const { getBasePageInfo } = useMainTabsHistory();

  const gotoNewAsset = useCallback(() => {
    history.push(getBasePageInfo().url + paths.client.NEW_ASSET_SUB_PAGE);
  }, [history, getBasePageInfo]);

  const usersProps = useAssetAssigneesAutocompleteDataHook({
    search: filterValues['currentAssignment'],
  });

  const userEmailsProps = useAssigneeEmailsAutocompleteDataHook({
    search: filterValues['currentAssignmentEmail'],
  });

  const assetNamesProps = useAssetNamesAutocompleteDataHook({
    search: filterValues['name'],
  });

  const assetOfficesProps = useAssetOfficesAutocompleteDataHook({
    search: filterValues['office'],
  });

  const assetBrandsProps = useAssetBrandsAutocompleteDataHook({
    search: filterValues['brand'],
  });

  const assetModelsProps = useAssetModelsAutocompleteDataHook({
    search: filterValues['model'],
  });

  const assetFactoryIdentifiersProps = useAssetFactoryIdentifiersAutocompleteDataHook({
    search: filterValues['factoryIdentifier'],
  });

  const assetExpressCodesProps = useAssetExpressCodesAutocompleteDataHook({
    search: filterValues['expressCode'],
  });

  const extendedAssetsTableHead = useMemo(() => {
    const newUserProps = {
      ...usersProps,
      items: [
        { id: '--NotAssigned--', name: '--' },
        // { id: 'x001', name: '------------------------------', disabled: true },
        ...usersProps.items,
      ],
    };
    const userEmailProps = {
      ...userEmailsProps,
      items: [
        { id: '--NotAssigned--', name: '' },
        // { id: 'x001', name: '------------------------------', disabled: true },
        ...userEmailsProps.items.filter(({ name }) => !!name),
      ],
    };
    assetsTableHead.find((item) => item.id === 'currentAssignment')!.filterProps = newUserProps;
    assetsTableHead.find((item) => item.id === 'currentAssignmentEmail')!.filterProps =
      userEmailProps;
    assetsTableHead.find((item) => item.id === 'name')!.filterProps = assetNamesProps;
    assetsTableHead.find((item) => item.id === 'office')!.filterProps = assetOfficesProps;
    assetsTableHead.find((item) => item.id === 'brand')!.filterProps = assetBrandsProps;
    assetsTableHead.find((item) => item.id === 'model')!.filterProps = assetModelsProps;
    assetsTableHead.find((item) => item.id === 'factoryIdentifier')!.filterProps =
      assetFactoryIdentifiersProps;
    assetsTableHead.find((item) => item.id === 'expressCode')!.filterProps = assetExpressCodesProps;
    return assetsTableHead;
  }, [
    usersProps,
    userEmailsProps,
    assetNamesProps,
    assetOfficesProps,
    assetBrandsProps,
    assetModelsProps,
    assetFactoryIdentifiersProps,
    assetExpressCodesProps,
  ]);

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ padding: '16px', marginTop: '16px' }}
              order={{ xs: 1 }}
            >
              <ConfirmationButton
                action={gotoNewAsset}
                text="Create New Asset"
              ></ConfirmationButton>
            </Grid>

            <Grid item xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
              order={{ xs: 2, sm: 3 }}
            ></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              bottomOffset={20}
              totalItems={totalItems}
              dataCells={assets}
              headCells={extendedAssetsTableHead}
              loadPage={loadPage}
              handleSelect={onAssetSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={<ExportToExcel></ExportToExcel>}
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

import { gql } from '@apollo/client';
import { account } from '../fragments';

const queries = {
  GET_TOKEN: gql`
    query GetToken {
      token @client
    }
  `,
  GET_ACCOUNT: gql`
    query GetAccount {
      account @client(always: true) {
        ${account}
      }
    }
  `,
};

const mutations = {
  SET_ACCOUNT: gql`
    mutation SetAccount($account: Account) {
      setAccount(account: $account) @client {
        username
      }
    }
  `,
  SET_TOKEN: gql`
    mutation SetToken {
      setToken @client
    }
  `,
  SET_IS_LOGGED_IN: gql`
    mutation SetIsLoggedIn {
      setIsLoggedIn @client
    }
  `,
  SET_LOG_IN_ERROR: gql`
    mutation SetError {
      setError @client
    }
  `,
  REMOVE_ACCOUNT: gql`
    mutation RemoveAccount {
      removeAccount @client
    }
  `,
  REMOVE_USER: gql`
    mutation RemoveUser {
      removeUser @client
    }
  `,
  LOGOUT: gql`
    mutation Logout {
      logout @client
    }
  `,
};

const authGql = { queries, mutations };

export default authGql;

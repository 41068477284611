import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query usersList($sort: [UserSort!] = [], $filter: UserFilter, $take: Int, $skip: Int = 0) {
    users(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      name
      email
      isAssetsUser
    }
  }
`;

export const GET_USERS_COUNT = gql`
  query usersCount($filter: UserFilter) {
    userCount(filter: $filter)
  }
`;

import React, { FC } from 'react';

import { SortOrder } from 'components/ui/TableDnd/components/HeaderCell/HeaderCell';
import { currencyFormatUS } from './currencyFormat';
import { AssetActions } from 'components/AssetActions/AssetActions';
import { Link } from '@mui/material';

export const TransformCurrencyUS: FC<{
  data: string;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data }) => {
  const text = data ? (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ marginLeft: 'auto' }}>$ {currencyFormatUS(parseFloat(data))}</div>
    </div>
  ) : (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ marginLeft: 'auto' }}>--</div>
    </div>
  );
  return <>{text}</>;
};

export const TransformAssetActions: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  const { isCanceled, id } = row;
  return <AssetActions isCanceled={isCanceled} id={id}></AssetActions>;
};

export const TransformCurrentAssignmentEmail: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ row }): JSX.Element => {
  let email;
  if (row?.currentAssignment?.user?.email) {
    email = row.currentAssignment.user?.email;
  }
  if (row?.currentAssignment?.contractorEmail) {
    email = row?.currentAssignment?.contractorEmail;
  }
  if (!email) {
    return <>--</>;
  }

  let name;
  if (row.currentAssignment?.user) {
    name = row.currentAssignment.user.name;
  }
  if (row.currentAssignment?.room) {
    name = row.currentAssignment.room.name;
  }
  if (row.currentAssignment?.contractorName) {
    name = row.currentAssignment.contractorName;
  }

  return (
    <div
      onClick={(e: any) => {
        e.cancelBubble = true;
        e.stopPropagation();
      }}
    >
      <Link href={`mailto:${name} <${email}>`}>{email}</Link>
    </div>
  );
};

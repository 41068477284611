import { InputAdornment } from '@mui/material';
import { TextInputField } from 'components/TextInputField/TextInputField';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { formatPrice } from 'utils/currencyFormat';

export interface ICurrencyInputField {
  value: number | null | undefined;
  maxAmount?: number;
  onChange: any;
  [id: string]: any;
}

export const CurrencyInputField: FC<ICurrencyInputField> = ({
  value,
  onChange,
  maxAmount,
  ...props
}) => {
  const [localValue, setLocalValue] = useState<string>(value ? formatPrice(value) : '');

  useEffect(() => {
    setLocalValue(value ? formatPrice(value) : '');
  }, [value]);

  const onLocalChange = (e: any) => {
    const newValue = e.target.value.replace(/[^0-9.,]/gi, '');
    const newValueAsAmount = parseFloat(newValue.replaceAll(',', ''));
    if (maxAmount && newValueAsAmount > maxAmount) {
      setLocalValue(formatPrice(maxAmount));
    } else {
      setLocalValue(newValue);
    }
  };

  const onBlur = () => {
    onChange({ target: { value: localValue.replaceAll(',', '') } });
  };

  const maxAmountReachedText = useMemo(() => {
    if (!!maxAmount) {
      const currentAmount = parseFloat(localValue.replaceAll(',', ''));
      if (currentAmount === maxAmount) {
        return 'max value';
      }
      if (currentAmount > maxAmount) {
        return 'over limit';
      }
    }
    return undefined;
  }, [localValue, maxAmount]);

  return (
    <TextInputField
      {...props}
      onChange={onLocalChange}
      value={localValue}
      onBlur={onBlur}
      InputProps={{
        startAdornment: (
          <Fragment>
            <InputAdornment position="start">$</InputAdornment>
          </Fragment>
        ),
        endAdornment: maxAmountReachedText ? (
          <Fragment>
            <InputAdornment
              position="end"
              sx={{
                '&': { fontSize: '0.8rem !important' },
              }}
              disableTypography
            >
              {maxAmountReachedText}
            </InputAdornment>
          </Fragment>
        ) : undefined,
      }}
    ></TextInputField>
  );
};

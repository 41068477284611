import { gql } from '@apollo/client';
import { IAssetRoom } from './fragments';

export const GET_ASSET_ROOM_NAMES = gql`
  query assetRoomNames {
    assets_assetRooms {
      id
      name
    }
  }
`;

export const GET_ASSET_ROOM_COUNT = gql`
  query assetRoomCount($filter: AssetRoomFilter = {}) {
    assets_assetRoomCount(filter: $filter)
  }
`;

export const GET_ASSET_ROOMS = gql`
  query assetRooms(
    $sort: [AssetRoomSort!] = []
    $filter: AssetRoomFilter = {}
    $take: Int = 100
    $skip: Int = 0
  ) {
    assets_assetRooms(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IAssetRoom
    }
  }
  ${IAssetRoom}
`;

export const ASSET_ROOM_CREATE_MUTATION = gql`
  mutation assetRoomCreate(
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetRoomCreate(
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

export const ASSET_ROOM_UPDATE_MUTATION = gql`
  mutation assetRoomUpdate(
    $id: ID!
    $name: String!
    $description: String!
    $order: Int = 0
    $isSelectable: Boolean = true
  ) {
    assets_assetRoomUpdate(
      id: $id
      name: $name
      description: $description
      order: $order
      isSelectable: $isSelectable
    ) {
      id
    }
  }
`;

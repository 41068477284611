import { DatePicker } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { IRoomItem, SelectRoom } from 'components/SelectRoom/SelectRoom';
import { assignedTypeOptions, IGeneralListItem } from 'constants/selectOptions';
import { omit } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useAssetAssignmentContext } from './AssetAssignmentContext';

export const AssetRoomAssignment: FC = () => {
  const { assignment, setAssignment, isNew, setAssetIsLoaner } = useAssetAssignmentContext();
  const { startDate, endDate, showValidator, errors, assignmentType, selectedRoom } = assignment;

  useEffect(() => {
    setAssetIsLoaner(false);
  }, [setAssetIsLoaner]);

  const [selectedAssignmentType, setSelectedAssignmentType] = useState<IGeneralListItem>();

  useEffect(() => {
    if (assignmentType) {
      setSelectedAssignmentType(assignedTypeOptions.find((option) => option.id === assignmentType));
    } else {
      setSelectedAssignmentType(undefined);
    }
  }, [assignmentType]);

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      {isNew ? (
        <Grid item xs={12}>
          <SelectRoom
            onSelectChange={(room: IRoomItem) => {
              setAssignment((assignment: any) => ({
                ...assignment,
                selectedRoom: room ? omit(room, '__typename') : null,
              }));
            }}
            selectedItem={selectedRoom}
            error={showValidator && errors?.selectedRoom}
          ></SelectRoom>
        </Grid>
      ) : undefined}
      {!isNew ? (
        <>
          <Grid item xs={4} md={4} xl={2}>
            <Typography variant="body2" className="label-title">
              Assigned To:
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} xl={4}>
            {selectedRoom?.name}
          </Grid>
        </>
      ) : undefined}
      <Grid item xs={12} md={4} xl={3}>
        <Typography variant="body2" className="label-title">
          Assignment Type:
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={3}>
        {selectedAssignmentType?.name}
      </Grid>
      <Grid item xs={12} md={4} xl={2}>
        <Typography variant="body2" className="label-title">
          Start Date:
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={4}>
        <div style={{ maxWidth: '150px' }}>
          <DatePicker
            value={startDate || null}
            onChange={(date: Date | null) => {
              setAssignment!((assignment: any) => {
                return { ...assignment, startDate: date };
              });
            }}
            clearable
            renderInput={(props) => (
              <TextField {...props} error={props.error || (showValidator && errors?.startDate)} />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <Typography variant="body2" className="label-title">
          End Date:
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} xl={3}>
        <div style={{ maxWidth: '150px' }}>
          <DatePicker
            value={endDate || null}
            onChange={(date: Date | null) => {
              setAssignment!((assignment: any) => {
                return { ...assignment, endDate: date };
              });
            }}
            clearable
            renderInput={(props) => (
              <TextField {...props} error={props.error || (showValidator && errors?.endDate)} />
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
};

import { useCallback } from 'react';

export const useAfterLogin = () => {
  const getAfterLogin = useCallback(() => {
    return sessionStorage.getItem('afterLogin');
  }, []);

  const setAfterLogin = useCallback((value: string) => {
    sessionStorage.setItem('afterLogin', value);
  }, []);

  const clearAfterLogin = useCallback(() => {
    sessionStorage.removeItem('afterLogin');
  }, []);

  return { getAfterLogin, setAfterLogin, clearAfterLogin };
};

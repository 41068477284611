import React, { FC, useState, useEffect } from 'react';
import { Typography } from '@mui/material';

import './index.scss';

const UserInitials: FC<any> = ({ username, name, firstName, lastName, ...props }: any) => {
  const [initials, setInitials] = useState('');

  const buildInitials = (val: string) => {
    if (!val) return;

    const temp = val?.split(' ');

    if (temp.length > 1) {
      // DeMac accounts have LASTNAME-FIRSTNAME formatted
      const f = temp[1].length > 0 ? temp[1].substr(0, 1) : '';
      const l = temp[0].length > 0 ? temp[0].substr(0, 1) : '';

      setInitials(f + l);
    }

    if (temp.length === 1) setInitials(temp[1].substr(0, 1));
  };

  useEffect(() => {
    const initalsByFirstnameAndLastname = (): void => {
      if (!firstName && !lastName) return;
      const fn = firstName.trim();
      const ln = lastName.trim();

      const f = fn.length > 0 ? fn.substr(0, 1) : '';
      const l = ln.length > 0 ? ln.substr(0, 1) : '';

      setInitials(f + l);
    };

    if (name) buildInitials(name);
    if (username) buildInitials(username);
    if (firstName && lastName) initalsByFirstnameAndLastname();
  }, [name, username, firstName, lastName]);

  return (
    <>
      {initials && (
        <div className="user-initials" style={props?.divStyle}>
          <Typography variant="body2">{initials}</Typography>
        </div>
      )}
    </>
  );
};

export default UserInitials;

import React, { FC } from 'react';

import * as serviceWorker from './serviceWorker';

import ReactDOM from 'react-dom/client';

import './index.scss';

import { msalInstance } from './authorization/auth-utils-msal2';
import { MsalProvider } from '@azure/msal-react';
import { AppMsal } from './appMsal';

const App: FC<any> = () => (
  <MsalProvider instance={msalInstance}>
    <AppMsal />
  </MsalProvider>
);

const root = document.getElementById('root');
if (root) {
  const anchor = ReactDOM.createRoot(root);
  if (anchor) {
    anchor.render(<App />);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

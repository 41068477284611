import { useLazyQuery } from '@apollo/client';
import { GET_ASSET_LOCATION_NAMES } from 'graphql/ams/assetLocations';
import {
  assetLocationNames,
  assetLocationNames_assets_assetLocations,
} from 'graphql/ams/types/assetLocationNames';

import { useCallback, useEffect, useMemo } from 'react';

export interface IUseSelectedLocationDataState {
  items: assetLocationNames_assets_assetLocations[];
  loading: boolean;
  error?: any;
}

interface IUseSelectedLocationDataProps {
  IDs?: string[];
}

export const useSelectedAssetLocationsDataItems = ({
  IDs,
}: IUseSelectedLocationDataProps): IUseSelectedLocationDataState => {
  const [loadLocations, { data, loading, refetch, called, error }] =
    useLazyQuery<assetLocationNames>(GET_ASSET_LOCATION_NAMES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadLocations({ variables });
      }
    },
    [loadLocations, refetch, called]
  );

  useEffect(() => {
    if (IDs?.length) {
      loadPage(IDs);
    }
  }, [IDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.assets_assetLocations) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items, loading, error };
};

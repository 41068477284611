import { AssetEventType } from 'constants/enums';
import { useSelectedAssetLocationsDataItems } from 'hooks/selectedLocationsDataItems';
import { useAssetSelecteStatusesDataItems } from 'hooks/selectedStatusesDataItems';
import { useSelectedAssetTypesDataItems } from 'hooks/selectedTypesDataItems';
import { keyBy, mapValues, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatPrice } from 'utils/currencyFormat';
import { formatItemDate } from 'utils/time';
import { customFields } from './customFields';

export interface IEvent {
  [id: string]: any;
}

export interface IUsePayloadConvertProps {
  events: IEvent[];
}

export const usePayloadConvert = ({ events }: IUsePayloadConvertProps) => {
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>();
  const [selectedTypes, setSelectedTypes] = useState<string[]>();
  const [selectedLocations, setSelectedLocations] = useState<string[]>();

  const { items: statusItems, loading: loadingStatusItems } = useAssetSelecteStatusesDataItems({
    IDs: selectedStatuses,
  });
  const { items: typeItems, loading: loadingTypeItems } = useSelectedAssetTypesDataItems({
    IDs: selectedTypes,
  });
  const { items: locationItems, loading: loadingLocationItems } =
    useSelectedAssetLocationsDataItems({ IDs: selectedLocations });

  const mapSimpleOptions = useMemo(
    () => ({
      assetTypeId: {
        id: 'assetType',
        options: mapValues(keyBy(typeItems, 'id'), 'name'),
      },
      assetStatusId: {
        id: 'assetStatus',
        options: mapValues(keyBy(statusItems, 'id'), 'name'),
      },
      assetLocationId: {
        id: 'assetLocation',
        options: mapValues(keyBy(locationItems, 'id'), 'name'),
      },
    }),
    [locationItems, statusItems, typeItems]
  );

  const assetArrayToFieldMap = useMemo<{
    [key: string]: { id: string; type: string; options?: any; valueMap?: (obj: any) => string };
  }>(() => ({}), []);

  const fieldsInfo = useMemo(() => {
    const collectedInfo: any[] = [];
    [customFields].forEach((section) => {
      section.forEach((fieldGroup) => {
        fieldGroup.forEach((field) => {
          if (!!field.id) {
            collectedInfo.push({ ...pick(field, ['id', 'title', 'field']) });
          }
        });
      });
    });
    return keyBy(collectedInfo, 'id');
  }, []);

  useEffect(() => {
    if (events) {
      const statusesToLoad: { [key: string]: boolean } = {};
      const assetTypesToLoad: { [key: string]: boolean } = {};
      const locationsToLoad: { [key: string]: boolean } = {};

      events.forEach((event) => {
        if (
          [AssetEventType.ASSET_CREATED, AssetEventType.ASSET_UPDATED].includes(event.eventType) &&
          event.payload
        ) {
          const payload = JSON.parse(event.payload);
          if (payload.assetTypeId) {
            assetTypesToLoad[payload.assetTypeId] = true;
          }
          if (payload.assetStatusId) {
            statusesToLoad[payload.assetStatusId] = true;
          }
          if (payload.assetLocationId) {
            locationsToLoad[payload.assetLocationId] = true;
          }
        }
      });

      setSelectedStatuses(Object.keys(statusesToLoad));
      setSelectedTypes(Object.keys(assetTypesToLoad));
      setSelectedLocations(Object.keys(locationsToLoad));
    }
  }, [events]);

  const payloadConvert = useCallback(
    (payload: any) => {
      const collected: any[] = [];
      Object.keys(payload).forEach((key) => {
        if (key === 'id') {
          return;
        }

        if (Array.isArray(payload[key as keyof typeof payload])) {
          if (assetArrayToFieldMap[key as keyof typeof assetArrayToFieldMap]) {
            const {
              id: mappedId,
              type,
              options,
              valueMap,
            } = assetArrayToFieldMap[key as keyof typeof assetArrayToFieldMap];
            if (payload[key].length) {
              collected.push({
                id: key,
                title: fieldsInfo[mappedId]?.title + ' ' + type,
                value:
                  '"' +
                  (valueMap
                    ? payload[key].map((item: any) => valueMap(item)).join('", "')
                    : options
                    ? payload[key]
                        .map((id: string) => {
                          return options[id]?.name || options[id];
                        })
                        .join('", "')
                    : payload[key]) +
                  '"',
              });
            } else {
              // empty list is not important
            }
          } else {
            collected.push({
              id: key,
              title: key,
              value: payload[key].join(', ') || '--',
            });
          }
          return;
        }

        if (fieldsInfo[key]) {
          let displayValue;
          if (payload.hasOwnProperty(key)) {
            switch (fieldsInfo[key].field?.type) {
              case '$': {
                displayValue = '$ ' + formatPrice(payload[key]);
                break;
              }
              case 'date': {
                displayValue = formatItemDate(payload[key]);
                break;
              }
              case 'boolean': {
                displayValue = payload[key] ? 'true' : 'false';
                break;
              }

              default:
                displayValue = payload[key];
            }
          } else {
            displayValue = '--';
          }

          collected.push({
            id: key,
            title: fieldsInfo[key].title,
            value: displayValue,
          });
          return;
        }

        if (mapSimpleOptions[key as keyof typeof mapSimpleOptions]) {
          const { id: mappedId, options } = mapSimpleOptions[key as keyof typeof mapSimpleOptions];

          collected.push({
            id: mappedId,
            title: fieldsInfo[mappedId].title,
            value:
              // (!!options && (options[payload[key]]?.name || options[payload[key]])) ||
              (!!options && options[payload[key]]) || payload[key] || '--',
          });

          return;
        }
      });
      return collected;
    },
    [fieldsInfo, mapSimpleOptions, assetArrayToFieldMap]
  );

  return {
    payloadConvert,
    loading: loadingStatusItems || loadingLocationItems || loadingTypeItems,
  };
};

import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/TableDnd/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';

import { useCallback, useEffect, useMemo, useState } from 'react';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
  withLimitedAccess?: boolean;
}

export interface IUseTableDataSourceProps<T, TFilter> {
  initFilter?: any;
  overridePageLoadParams?: any;
  GET_QUERY: any;
  GET_COUNT_QUERY: any;
  getCount: (data: any) => number;
  getItems: (data: any) => T[];
  tableStorageKey?: string;
  initRowsPerPage?: number;
  filterToAPI: (filterValues: any) => TFilter;
}

export interface IItemsTableHookValue {
  totalItems: number;
  items: any;
  loadPage: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  filterApplied: any;
  pageLoadParams: any;
  errorItems?: any;
  errorCount?: any;
}

export const useTableDataSource = <
  itemsList extends unknown,
  itemsCount extends unknown,
  IItem extends unknown,
  TFilter extends unknown
>({
  initFilter,
  overridePageLoadParams,
  GET_QUERY,
  GET_COUNT_QUERY,
  getCount,
  getItems,
  tableStorageKey,
  initRowsPerPage,
  filterToAPI,
}: IUseTableDataSourceProps<IItem, TFilter>): IItemsTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey,
  });

  const [totalItems, setTotalItems] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    filter: initFilter,
    rowsPerPage: initRowsPerPage || DEFAULT_ROWS_PER_PAGE,
    ...getItem(),
  });

  const [loadItems, { called, data, loading, error: errorItems, refetch }] =
    useLazyQuery<itemsList>(GET_QUERY, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const [
    loadItemsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCount,
      refetch: refetchCount,
    },
  ] = useLazyQuery<itemsCount>(GET_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      setPageLoadParams((initial) => ({
        ...initial,
        ...overridePageLoadParams,
        filter: { ...initial.filter, ...overridePageLoadParams?.filter },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter, withLimitedAccess } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter: { ...filter },
      withLimitedAccess,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadItems({ variables });
    }
  }, [loadItems, refetch, called, pageLoadParams]);

  useEffect(() => {
    if (!data && !loading && errorItems) {
      setItems([]);
    }
  }, [data, loading, errorItems]);

  useEffect(() => {
    const { filter, withLimitedAccess } = pageLoadParams;

    const variables = {
      filter: { ...filter },
      withLimitedAccess,
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadItemsCount({ variables });
    }
  }, [loadItemsCount, refetchCount, calledCount, pageLoadParams]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(getCount(dataCount));
    }
  }, [dataCount, loadingCount, getCount]);

  useEffect(() => {
    if (!loading && data) {
      setItems(() => [...(getItems(data) || [])]);
    }
  }, [data, loading, getItems]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter = filterToAPI(filterValues);

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, filterToAPI]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return JSON.stringify(filter) !== '{}';
  }, [pageLoadParams]);

  return {
    totalItems,
    items,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: errorItems || errorCount,
    errorItems,
    errorCount,
  };
};

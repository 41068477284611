import { Chip, Tooltip } from '@mui/material';
import { FC, useMemo, useState } from 'react';

export interface ITooltipChipProps {
  tagValue?: any;
  getTagProps: any;
  option: any;
  index: any;
  tooltip: any;
  getOptionLabel?: any;
}

export const TooltipChip: FC<ITooltipChipProps> = ({
  tagValue,
  getTagProps,
  option,
  index,
  tooltip,
  getOptionLabel,
}) => {
  const [open, setOpen] = useState(false);

  const titleComponent = useMemo(() => {
    return tooltip && tooltip(open, option);
  }, [open, option, tooltip]);

  if (!option) return <></>;
  if (!titleComponent)
    return (
      <Chip
        label={(getOptionLabel && getOptionLabel(option)) || option.name}
        {...getTagProps({ index })}
      />
    );

  return (
    <Tooltip
      enterDelay={500}
      enterNextDelay={500}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      title={titleComponent}
      disableInteractive={!tooltip}
    >
      <Chip
        label={(getOptionLabel && getOptionLabel(option)) || option.name}
        {...getTagProps({ index })}
      />
    </Tooltip>
  );
};

import { Typography } from '@mui/material';
import { SvgLogo as Logo } from 'images/SvgLogo';
import { FC } from 'react';
import s from './style.module.scss';

export interface IPrintingHeaderProps {
  subtitle?: string;
}

export const PrintingHeader: FC<IPrintingHeaderProps> = ({ subtitle }) => {
  return (
    <div className={s.documentPrintingHeader}>
      <div className={s.logoBox}>
        <Logo className={s.logo} />
      </div>
      <div className={s.centerBox}>
        <div className={s.title}>
          <Typography variant="body2" className="label-title-nocase">
            D&M IT Asset Management System
          </Typography>
        </div>
        {subtitle ? <div className={s.subtitle}>{subtitle}</div> : undefined}
      </div>
      <div className={s.logoBox}></div>
    </div>
  );
};

import { useCallback } from 'react';

export interface ITableStorageProps {
  key?: string;
}

export const useTableStorage = ({ key }: ITableStorageProps) => {
  const getItem = useCallback(() => {
    if (!key) {
      return {};
    }
    const storageValue = sessionStorage.getItem('Table_' + key);
    if (storageValue) {
      return JSON.parse(storageValue);
    } else {
      return {};
    }
  }, [key]);

  const setItem = useCallback(
    (value: any) => {
      if (key) {
        const overwriteValue = { ...getItem(), ...value };
        sessionStorage.setItem('Table_' + key, JSON.stringify(overwriteValue));
      }
    },
    [getItem, key]
  );

  return { setItem, getItem };
};

import { paths } from 'constants/paths';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type PageType = 'ITAssets' | 'Reports';

export interface IPageInfo {
  url: string;
  name: string;
}

export interface IPagesInfo {
  [pageType: string]: IPageInfo;
}

const pagesInfo: IPagesInfo = {
  ITAssets: { url: '', name: 'Assets List' },
  Reports: { url: paths.client.REPORTS, name: "Report's Assets List" },
};

export interface IMainTabsHistory {
  getBasePageInfo: () => IPageInfo;
}

export const useMainTabsHistory = (): IMainTabsHistory => {
  const { pathname } = useLocation();

  const firstTab = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const tabReportsActive = useMemo(() => ['reports'].includes(firstTab), [firstTab]);

  const getBasePageInfo = useCallback(() => {
    if (tabReportsActive) {
      console.log('getBasePageInfo', pagesInfo.Reports);
      return pagesInfo.Reports;
    }

    console.log('getBasePageInfo', pagesInfo.ITAssets);
    return pagesInfo.ITAssets;
  }, [tabReportsActive]);

  return { getBasePageInfo };
};
